import Cookies from "universal-cookie";
import { LOGIN, LOGOUT } from "../actions";
const cookies = new Cookies();

const defaultObj = {
  isAuthenticated:
    cookies.get("fantasyAuthToken") && cookies.get("fantasyAuthToken") !== undefined
      ? cookies.get("fantasyAuthToken")
      : null,
  fantasyAuthToken:
    cookies.get("fantasyAuthToken") !== undefined ? cookies.get("fantasyAuthToken") : null,
  user: cookies.get("user") !== undefined ? cookies.get("user") : null,
};

export const UserReducers = (state = defaultObj, action) => {
  switch (action.type) {
    case LOGIN:
      var expireDate = new Date();
      expireDate.setDate(expireDate.getDate() + 30);
      cookies.set("fantasyAuthToken", action.payload.secretKey, {
        path: "/",
        expires: expireDate,
      });
      cookies.set("user", action.payload, { path: "/", expires: expireDate });
      return {
        fantasyAuthToken: action.payload.secretKey,
        isAuthenticated: true,
        user: action.payload,
      };
    case LOGOUT:
      cookies.remove("fantasyAuthToken", { path: "/" });
      cookies.remove("user", { path: "/" });
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    default:
      return state;
  }
};
