import { callPostApi } from "api/ApiCaller";
import Select from "react-select";
import {
    Activate_Contest_PUT,
    All_Contest_List_POST,
    DeActivate_Contest_PUT,
    Delete_Contest_DELETE,
    Deleted_Contest_List_POST,
    Revive_Contest_PUT,
    Get_Country_ke_value_list_POST,
    Get_Cricket_Match_key_value_list_POST,
    Get_Client_key_value_list_POST,
    Cancel_Contest_DELETE,
} from "api/ApiConst";
import CheckboxButton from "components/common/CheckboxButton";
import CustomButton from "components/common/CustomButton";
import PageHeading from "components/common/PageHeading";
import PaginationDataTable from "components/common/PaginationDataTable";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import dateUtilities from "utils/DateUtilities";
import {
    getImageSrcViaRefId,
    handleActive,
    handleCancel,
    handleDelete,
    handleInactive,
    handleRevive,
} from "utils/UserUtils";
import ContestAddEdit from "./ContestAddEdit";
import toast from "react-hot-toast";
import { MODAL_LOADER } from "utils/constants";
import { API_SUCCESS } from "api/ResponseCodes";
import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const customStyles = {
    control: (provided) => ({
        ...provided,
        width: 135, // Set the desired width
        padding: 2
    }),
};

const contestTypeListOptions = [
    { label: "All", value: "All" },
    { label: "UpComing", value: "UpComing" },
    { label: "OnGoing ", value: "OnGoing" },
    // { label: " Completed", value: "Completed" },
];

const defaultRecordObj = {
    contestType: "All",
    clientId: 0,
    cricketMatchId: 0,
    startDateTimeStamp: 0,
    endDateTimeStamp: 0
}

const ContestMaster = () => {
    const navigate = useNavigate();
    const [record, setRecord] = useState(defaultRecordObj);
    const [refreshFlag, setRefreshFlag] = useState(0);
    const [viewDeletedList, setViewDeletedList] = useState(false);
    const [recordID, setRecordID] = useState(null);
    const [showAddUpdateModal, setShowAddUpdateModal] = useState(false);
    const [selectedContestTypeOption, setSelectedContestTypeOption] = useState([{ label: "All", value: "All" }]);
    const [clientList, setClientList] = useState([]);
    const [selectedClientList, setSelectedClientList] = useState([{ label: "All Client", value: "All", key: 0 }]);
    const [matchList, setMatchList] = useState([]);
    const [selectedMatchList, setSelectedMatchList] = useState([{ label: "All Match", value: "All", key: 0 }]);

    useEffect(() => {
        if (!showAddUpdateModal) {
            setRecordID(null);
        }
    }, [showAddUpdateModal]);

    useEffect(() => {
        getMatchList();
        getClientList();

    }, []);

    const getMatchList = () => {
        callPostApi("Loader", Get_Cricket_Match_key_value_list_POST, {}, {}, (res) => {
            setMatchList(res.responsePacket);
        });
    };
    const getClientList = () => {
        callPostApi("Loader", Get_Client_key_value_list_POST, {}, {}, (res) => {
            setClientList(res.responsePacket);
        });
    };

    const handleRefresh = useCallback(() => {
        setRefreshFlag(refreshFlag + 1);
    }, [refreshFlag]);

    const columns = useMemo(
        () => [
            {
                id: "status",
                name: "",
                selector: (row) => (
                    <div
                        className={row.active ? "led-green" : "led-red"}
                        title={row.active ? "Active" : "Inactive"}
                    />
                ),
                width: "60px",
                sortable: false,
            },
            {
                id: "clientName",
                name: "Client",
                selector: (row) => row.clientName,
                sortable: false,
                wrap: true,
                // width: "100px",
            },
            {
                id: "matchTitle",
                name: "Match Title",
                selector: (row) => row.cricketMatchTitle,
                sortable: false,
                wrap: true,
                // width: "140px"
            },
            {
                id: "contestStatusEnum",
                name: "Contest Status",
                selector: (row) => row.contestStatusEnum,
                sortable: false,
                wrap: true,
                // width: "140px"

            },
            {
                id: "publishDateTimeStamp",
                name: "Publish Date",
                selector: (row) =>
                    dateUtilities.formattedDate(
                        row.publishDateTimeStamp,
                        "dd-MM-yyyy, hh:mm aa"
                    ),
                sortable: false,
                wrap: true,
                // width: "170px"
            },
            {
                id: "startDateTimeStamp",
                name: "Start Date",
                selector: (row) =>
                    dateUtilities.formattedDate(
                        row.startDateTimeStamp,
                        "dd-MM-yyyy, hh:mm aa"
                    ),
                sortable: false,
                wrap: true,
                // width: "200px"
            },
            {
                id: "participant",
                name: "Participant",
                // TODO : Replace "10" with joinCount variable here
                selector: (row) =>
                    row.minimumNumberOfParticipantsRequired +
                    "/" +
                    row.maximumNumberOfParticipants,
                sortable: false,
                wrap: true,
                // right: true,
                // width: "110px"
            },
            {
                id: "prizePool",
                name: "Prize Pool",
                selector: (row) => {
                    // TODO : Replace "10" with according to the condition variable here
                    return row.prizePoolTypeEnum === "PlayerPool" ? (
                        <>
                            <div>Player Pool  </div>
                            <div>{row.prizePool * 10}</div>
                        </>
                    ) : (
                        <div>Guaranteed Pool</div>
                    );
                },
                sortable: false,
                wrap: true,
                // width: "130px"
            },
            {
                id: "createdAtTimeStamp",
                name: "Created At",
                selector: (row) =>
                    dateUtilities.formattedDate(
                        row.createdAtTimeStamp,
                        "dd-MM-yyyy, hh:mm aa"
                    ),
                // width: "200px",
                sortable: false,
                wrap: 2,
            },
            {
                id: "action",
                name: "",
                selector: (row) => (
                    <div className="custom-column-action">{getActionButtons(row)}</div>
                ),
                width: "150px",
                sortable: false,
                right: true
            },
        ],
        [handleRefresh]
    );

    const getActionButtons = (row) => {
        const date = new Date();
        const currentTime = date.getTime();
        return (
            <div className="d-flex align-items-center justify-content-between">

                <>
                    {(currentTime < row.publishDateTimeStamp) && <button className="listBtn me-2" onClick={() => handleEdit(row)}>
                        <i className="bx bx-pencil"></i>
                    </button>}
                    <button className="listBtn me-2"
                        onClick={() => navigate(`/admin-contest-details/${row.uuid}/live`)}
                    >
                        <i className="mdi mdi-eye-outline"></i>
                    </button>
                </>
                <div className="dropdown">
                    <button
                        className="btn btn-primary btn-flat btn-addon btn-xs dropdown-toggle"
                        title="More"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{ padding: "5px 10px 8px 10px" }}
                    >
                        <i className="bx bx-chevron-down"></i>
                    </button>
                    <ul className="dropdown-menu dropdown-menu-right">
                        <>
                            {/* {row.deleted ? (
                                <li onClick={() => handleReviveData(row)}>
                                    <i className="bx bx-log-out-circle"></i> Revive
                                </li>
                            ) : ( */}
                            <>
                                <li onClick={() => handleDeleteData(row)}>
                                    <i className="bx bx-trash"></i> Cancel
                                </li>
                                {/* <li onClick={() => handleActiveInactive(row)}>
                                        {row.active ? (
                                            <>
                                                <i className="bx bx-x-circle"></i> Inactive
                                            </>
                                        ) : (
                                            <>
                                                <i className="bx bx-check-circle"></i> Active
                                            </>
                                        )}
                                    </li> */}
                            </>
                            {/* )} */}
                        </>
                    </ul>
                </div>
            </div >
        );
    };

    const handleEdit = (row) => {
        setRecordID(row.uuid);
        setShowAddUpdateModal(true);
    };

    const handleActiveInactive = (row) => {
        if (row.active) {
            handleInactive(
                DeActivate_Contest_PUT.replaceAll("{recordID}", row.uuid),
                handleRefresh
            );
        } else {
            handleActive(
                Activate_Contest_PUT.replaceAll("{recordID}", row.uuid),
                handleRefresh
            );
        }
    };

    //Revive Record
    const handleReviveData = (row) => {
        handleRevive(
            Revive_Contest_PUT.replaceAll("{recordID}", row.uuid),
            handleRefresh
        );
    };
    //Delete Record
    const handleDeleteData = (row) => {
        handleCancel(
            Cancel_Contest_DELETE.replaceAll("{recordID}", row.uuid),
            handleRefresh
        );
    };
    // const handleToggleDeletedView = () => setViewDeletedList(!viewDeletedList);

    const handleTextChange = (e) => {
        const { id, value } = e.target;
        setRecord((prevRecord) => ({ ...prevRecord, [id]: value, }));
    }

    const handleSelectContestList = (e) => {
        setRecord({ ...record, contestType: e.value });
        setSelectedContestTypeOption({
            label: e.label,
            value: e.value,
            ...e,
        });
    };
    const handleSelectMatchList = (e) => {
        setRecord({ ...record, cricketMatchId: e.key });
        setSelectedMatchList({
            label: e.label,
            value: e.value,
            ...e,
        });
    };
    const handleSelectClientList = (e) => {
        setRecord({ ...record, clientId: e.key });
        setSelectedClientList({
            label: e.label,
            value: e.value,
            // key: e.key,
            ...e,
        });

    };

    let recordObj = { ...record, }
    const handleGetList = () => {
        let rObj = { ...record }
        let rUrl = All_Contest_List_POST;

        // rObj.startDateTimeStamp = new Date(rObj.startDateTimeStamp).getTime();
        // rObj.endDateTimeStamp = new Date(rObj.endDateTimeStamp).getTime();

        // const diff = rObj.endDateTimeStamp - rObj.startDateTimeStamp;
        // const daysDifference = diff / (1000 * 60 * 60 * 24);

        // if (daysDifference <= 31) {
        // rObj.contestType = selectedContestTypeOption.value;
        // rObj.clientId = selectedClientList.key;
        // rObj.cricketMatchId = selectedMatchList.key;
        // if (rObj.contestType && rObj.clientId && rObj.cricketMatchId) {
        callPostApi(MODAL_LOADER, rUrl, rObj, {}, (response) => {
            if (response.errorCode === API_SUCCESS) {
                toast.success(response.message, { id: "toast" });
                handleRefresh();
                // setRecord(response.responsePacket);
            } else {
                toast.error(response.message, { id: "toast" });
            }
        });
        // }
        // else {
        //     toast.error("All Fileds must be filled", { id: "toast" });
        // }
        // }
        // else {
        //     toast.error("Days cannot be more than 31 days");
        // }
    }
    return (
        <>
            {PageHeading("Contest List", "Dashboard", "/")}
            <div className="card">
                <div className="card-body tableView d-flex">
                    <div className="d-flex flex-grow-1">
                        <Select
                            className="ms-1"
                            maxMenuHeight={150}
                            styles={customStyles}
                            options={contestTypeListOptions}
                            value={selectedContestTypeOption}
                            onChange={(e) => handleSelectContestList(e)}
                        />
                        <Select
                            className="ms-1"
                            maxMenuHeight={150}
                            styles={customStyles}
                            options={[{ label: "All Client", value: "All", key: 0 }, ...clientList]}
                            value={selectedClientList}
                            onChange={handleSelectClientList}
                        />
                        <Select
                            className="ms-1"
                            maxMenuHeight={150}
                            styles={customStyles}
                            options={[{ label: "All Match", value: "All", key: 0 }, ...matchList]}
                            value={selectedMatchList}
                            onChange={handleSelectMatchList}
                        />

                        <CustomButton
                            title="Get List"
                            buttonClass="bg-success ms-2 px-4 align-items-center justify-content-center "
                            handleClick={handleGetList}
                        />
                        {/* {showDateInputs && ( */}
                        {/* <> */}
                        {/* <input  type="date" className="form-control ms-1 w-75 " id="startDateTimeStamp"
                            onChange={handleTextChange}
                            value={record.startDateTimeStamp} />
                        <input type="date"  className="form-control ms-1 w-75" id="endDateTimeStamp"
                            onChange={handleTextChange}
                            value={record.endDateTimeStamp} /> */}
                        {/* </> */}
                        {/* )} */}
                    </div>
                    <div className="ms-auto d-flex">
                        <CustomButton
                            title="Create New"
                            iconClass="bx bx-plus"
                            buttonClass="me-1 align-items-center justify-content-center"
                            handleClick={() => setShowAddUpdateModal(true)}
                        />

                        {/* <CheckboxButton
                            title="Deleted List"
                            handleClick={handleToggleDeletedView}
                            additionalClass="me-1 contest-btn"
                        /> */}
                    </div>
                </div>
                <div className="card-body tableView">
                    <PaginationDataTable
                        paginationUrl={
                            // viewDeletedList
                            //     ? Deleted_Contest_List_POST
                            // :
                            All_Contest_List_POST
                        }
                        serverSidePagination={true}
                        // search={true}
                        pagination={false}
                        columns={columns}
                        filter={{ ...record }}
                    />
                </div>
            </div>

            {showAddUpdateModal && (
                <ContestAddEdit
                    show={showAddUpdateModal}
                    hideModal={() => setShowAddUpdateModal(false)}
                    recordID={recordID}
                    handleRefresh={handleRefresh}
                    matchList={matchList}
                    clientList={clientList}
                />
            )}
        </>
    );
};

export default ContestMaster;
