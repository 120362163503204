import React from "react";

import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { combineReducers, createStore } from "redux";

import { UserReducers } from "./reducer/reducers/UserReducers";
import { UtilityReducer } from "./reducer/reducers/UtilityReducer";

import App from "./App";

const rootReducer = combineReducers({
    user: UserReducers,
    utility: UtilityReducer,
});

// TODO Deprecated Issue
export const store = createStore(rootReducer);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>
);
