import React from "react";

import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { HIDE_MODAL_LOADING } from "reducer/actions";

import Loader from "./Loader";

const ModalWrapper = ({ show, hide, header, handleSubmit = null, children, size = null, modalCustomButtons = null, ...options }) => {
    const { showModalLoader } = useSelector((state) => state.utility);
    const dispatch = useDispatch();

    const handleHide = () => {
        hide();
        dispatch({ type: HIDE_MODAL_LOADING });
    };

    return (
        <Modal show={show} onHide={handleHide} size={size ? size : "lg"} {...options}>
            <Modal.Header closeButton>
                <div className=" w-100 d-flex align-items-center justify-content-between">
                <Modal.Title>{header}</Modal.Title>
                {modalCustomButtons && modalCustomButtons()}
                </div>
            </Modal.Header>
            {showModalLoader && <Loader loaderClass="modalLoading" />}
            <>
                <Modal.Body>{children}</Modal.Body>
                <Modal.Footer>
                    {handleSubmit && (
                        <Button variant="primary" onClick={handleSubmit}>
                            Submit
                        </Button>
                    )}
                </Modal.Footer>
            </>
        </Modal>
    );
};

export default ModalWrapper;
