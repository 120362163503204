export const CoinBag = () => {
  return (
    <svg
      width={20}
      height={21}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width={20} height={21} fill="#F5F5F5" />
      <g id="Home Screen" clipPath="url(#clip0_0_1)">
        <rect
          width={360}
          height={986}
          transform="translate(-139 -201)"
          fill="white"
        />
        <rect
          id="Rectangle 386"
          x={-139}
          y={-102}
          width={360}
          height={724}
          fill="#F1F5F9"
        />
        <g id="Frame 396">
          <rect
            width={328}
            height={783}
            transform="translate(-123 -92)"
            fill="white"
          />
          <g id="Group 632241">
            <g id="Match Card" filter="url(#filter0_d_0_1)">
              <rect
                x={-123}
                y={-92}
                width={328}
                height={122}
                rx={8}
                fill="white"
                shapeRendering="crispEdges"
              />
              <rect
                x="-122.75"
                y="-91.75"
                width="327.5"
                height="121.5"
                rx="7.75"
                stroke="#0D6EC8"
                strokeWidth="0.5"
                shapeRendering="crispEdges"
              />
              <g id="Frame 621">
                <rect
                  x={-6}
                  y="-0.666016"
                  width={94}
                  height={22}
                  rx={4}
                  fill="url(#paint0_linear_0_1)"
                />
                <g id="clarity:coin-bag-solid" clipPath="url(#clip1_0_1)">
                  <path
                    id="Vector"
                    d="M13.8277 14.7782H18.1944C17.8277 9.93932 15.7444 6.93377 13.0722 5.15599L14.2888 2.50043C14.3256 2.41617 14.3409 2.32409 14.3333 2.23246C14.3258 2.14083 14.2956 2.05252 14.2455 1.97543C14.1954 1.89834 14.1269 1.83489 14.0462 1.79078C13.9656 1.74666 13.8752 1.72325 13.7833 1.72266H6.16661C6.07467 1.72325 5.98432 1.74666 5.90365 1.79078C5.82299 1.83489 5.75453 1.89834 5.70442 1.97543C5.65432 2.05252 5.62413 2.14083 5.61656 2.23246C5.60899 2.32409 5.62428 2.41617 5.66106 2.50043L6.32772 3.94488H11.9499L11.4499 5.05599H10.4222C10.7361 5.90002 10.9668 6.77267 11.1111 7.66154C11.2045 8.21292 11.2639 8.76953 11.2888 9.32821L10.4111 9.94488C10.4134 9.22629 10.354 8.50882 10.2333 7.80043C10.0798 6.85764 9.81495 5.93639 9.44439 5.05599H8.88883C8.37613 5.7038 7.91544 6.39113 7.51106 7.11154C7.18501 7.72952 6.90822 8.37225 6.68328 9.03377L5.92217 8.52821C6.14433 7.88885 6.41743 7.26834 6.73883 6.67266C7.06359 6.11407 7.41972 5.57431 7.8055 5.05599H6.83328L6.87772 5.15043C3.93328 7.11154 1.69439 10.556 1.69439 16.306C1.68688 16.4269 1.7034 16.548 1.74299 16.6625C1.78257 16.7769 1.84445 16.8824 1.92503 16.9728C2.00562 17.0632 2.10331 17.1367 2.21248 17.1891C2.32165 17.2416 2.44012 17.2718 2.56106 17.2782H7.0055C6.81466 17.0796 6.68289 16.8318 6.62496 16.5625C6.56703 16.2932 6.58522 16.0131 6.6775 15.7536C6.76977 15.494 6.93249 15.2653 7.14742 15.0931C7.36235 14.9208 7.621 14.8118 7.89439 14.7782C7.76134 14.5526 7.69042 14.2957 7.68883 14.0338C7.68883 13.6359 7.84687 13.2544 8.12817 12.9731C8.40948 12.6918 8.79101 12.5338 9.18883 12.5338H12.5222C12.92 12.5338 13.3015 12.6918 13.5828 12.9731C13.8641 13.2544 14.0222 13.6359 14.0222 14.0338C14.025 14.2948 13.9579 14.5519 13.8277 14.7782Z"
                    fill="white"
                  />
                  <path
                    id="Vector_2"
                    d="M12.0001 16.1669C12.0001 16.0195 11.9416 15.8782 11.8374 15.774C11.7332 15.6699 11.5919 15.6113 11.4446 15.6113H8.11122C7.96388 15.6113 7.82257 15.6699 7.71838 15.774C7.6142 15.8782 7.55566 16.0195 7.55566 16.1669C7.55566 16.3142 7.6142 16.4555 7.71838 16.5597C7.82257 16.6639 7.96388 16.7224 8.11122 16.7224H11.4446C11.5919 16.7224 11.7332 16.6639 11.8374 16.5597C11.9416 16.4555 12.0001 16.3142 12.0001 16.1669Z"
                    fill="white"
                  />
                  <path
                    id="Vector_3"
                    d="M12.5222 13.3887H9.18886C9.04151 13.3887 8.90021 13.4472 8.79602 13.5514C8.69183 13.6556 8.6333 13.7969 8.6333 13.9442C8.6333 14.0916 8.69183 14.2329 8.79602 14.3371C8.90021 14.4413 9.04151 14.4998 9.18886 14.4998H12.5222C12.6695 14.4998 12.8108 14.4413 12.915 14.3371C13.0192 14.2329 13.0777 14.0916 13.0777 13.9442C13.0777 13.7969 13.0192 13.6556 12.915 13.5514C12.8108 13.4472 12.6695 13.3887 12.5222 13.3887Z"
                    fill="white"
                  />
                  <path
                    id="Vector_4"
                    d="M12.2221 17.834H8.88881C8.74147 17.834 8.60016 17.8925 8.49597 17.9967C8.39178 18.1009 8.33325 18.2422 8.33325 18.3895C8.33325 18.5369 8.39178 18.6782 8.49597 18.7824C8.60016 18.8866 8.74147 18.9451 8.88881 18.9451H12.2221C12.3695 18.9451 12.5108 18.8866 12.615 18.7824C12.7192 18.6782 12.7777 18.5369 12.7777 18.3895C12.7777 18.2422 12.7192 18.1009 12.615 17.9967C12.5108 17.8925 12.3695 17.834 12.2221 17.834Z"
                    fill="white"
                  />
                  <path
                    id="Vector_5"
                    d="M18.1666 17.834H14.2777C14.1304 17.834 13.9891 17.8925 13.8849 17.9967C13.7807 18.1009 13.7222 18.2422 13.7222 18.3895C13.7222 18.5369 13.7807 18.6782 13.8849 18.7824C13.9891 18.8866 14.1304 18.9451 14.2777 18.9451H18.1666C18.314 18.9451 18.4553 18.8866 18.5595 18.7824C18.6636 18.6782 18.7222 18.5369 18.7222 18.3895C18.7222 18.2422 18.6636 18.1009 18.5595 17.9967C18.4553 17.8925 18.314 17.834 18.1666 17.834Z"
                    fill="white"
                  />
                  <path
                    id="Vector_6"
                    d="M18.7223 15.6113H14.8334C14.686 15.6113 14.5447 15.6699 14.4406 15.774C14.3364 15.8782 14.2778 16.0195 14.2778 16.1669C14.2778 16.3142 14.3364 16.4555 14.4406 16.5597C14.5447 16.6639 14.686 16.7224 14.8334 16.7224H18.7223C18.8696 16.7224 19.0109 16.6639 19.1151 16.5597C19.2193 16.4555 19.2778 16.3142 19.2778 16.1669C19.2778 16.0195 19.2193 15.8782 19.1151 15.774C19.0109 15.6699 18.8696 15.6113 18.7223 15.6113Z"
                    fill="white"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
        <g id="Group 632232"></g>
      </g>
      <defs>
        <filter
          id="filter0_d_0_1"
          x={-136}
          y={-92}
          width={341}
          height={134}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx={-7} dy={6} />
          <feGaussianBlur stdDeviation={3} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_0_1"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_0_1"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_0_1"
          x1={-6}
          y1="10.334"
          x2={88}
          y2="10.334"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#004B90" />
          <stop offset={1} stopColor="#3D9CF4" />
        </linearGradient>
        <clipPath id="clip0_0_1">
          <rect
            width={360}
            height={986}
            fill="white"
            transform="translate(-139 -201)"
          />
        </clipPath>
        <clipPath id="clip1_0_1">
          <rect
            width={20}
            height={20}
            fill="white"
            transform="translate(0 0.333984)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const ArrowBack = () => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 11.0007H7.82998L12.71 6.1207C13.1 5.7307 13.1 5.0907 12.71 4.7007C12.32 4.3107 11.69 4.3107 11.3 4.7007L4.70998 11.2907C4.31998 11.6807 4.31998 12.3107 4.70998 12.7007L11.3 19.2907C11.69 19.6807 12.32 19.6807 12.71 19.2907C13.1 18.9007 13.1 18.2707 12.71 17.8807L7.82998 13.0007H19C19.55 13.0007 20 12.5507 20 12.0007C20 11.4507 19.55 11.0007 19 11.0007Z"
        fill="white"
      />
    </svg>
  );
};

export const FilterList = () => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 18H13C13.55 18 14 17.55 14 17C14 16.45 13.55 16 13 16H11C10.45 16 10 16.45 10 17C10 17.55 10.45 18 11 18ZM3 7C3 7.55 3.45 8 4 8H20C20.55 8 21 7.55 21 7C21 6.45 20.55 6 20 6H4C3.45 6 3 6.45 3 7ZM7 13H17C17.55 13 18 12.55 18 12C18 11.45 17.55 11 17 11H7C6.45 11 6 11.45 6 12C6 12.55 6.45 13 7 13Z"
        fill="white"
      />
    </svg>
  );
};
export const LightningIcon = () => {
  return (
    <svg
      width={15}
      height={16}
      viewBox="0 0 10 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width={10} height={11} fill="#F5F5F5" />
      <g id="Create  Team" clipPath="url(#clip0_969_11184)">
        <rect
          width={360}
          height={1505}
          transform="translate(-242 -238)"
          fill="#0D6EC8"
        />
        <rect
          id="Rectangle 386"
          x={-242}
          y={-162}
          width={360}
          height={2120}
          fill="#F1F5F9"
        />
        <g id="BG farme" clipPath="url(#clip1_969_11184)">
          <g id="Group 632196">
            <g id="Frame 581">
              <g id="Player card">
                <rect
                  x={-225}
                  y={-13}
                  width={328}
                  height={79}
                  rx={8}
                  fill="white"
                />
              </g>
            </g>
            <g id="simple-icons:lightning" clipPath="url(#clip2_969_11184)">
              <path
                id="Vector"
                d="M5.00033 0.5L0.729492 3V8L5.00033 10.5L9.27116 8V3L5.00033 0.5ZM4.26074 8L4.71074 6.05958L3.69908 5.06083L5.74616 3L5.29533 4.94375L6.30116 5.93708L4.26074 8Z"
                fill="#0D6EC8"
              />
            </g>
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_969_11184">
          <rect
            width={360}
            height={1505}
            fill="white"
            transform="translate(-242 -238)"
          />
        </clipPath>
        <clipPath id="clip1_969_11184">
          <rect
            width={328}
            height={1847}
            fill="white"
            transform="translate(-225 -17)"
          />
        </clipPath>
        <clipPath id="clip2_969_11184">
          <rect
            width={10}
            height={10}
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const BarChart = () => {
  return (
    <svg
      width={16}
      height={17}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="icon">
        <path
          id="Vector"
          d="M4.26634 6.63334H4.39967C4.91301 6.63334 5.33301 7.05334 5.33301 7.56667V12.2333C5.33301 12.7467 4.91301 13.1667 4.39967 13.1667H4.26634C3.75301 13.1667 3.33301 12.7467 3.33301 12.2333V7.56667C3.33301 7.05334 3.75301 6.63334 4.26634 6.63334ZM7.99967 3.83334C8.51301 3.83334 8.93301 4.25334 8.93301 4.76667V12.2333C8.93301 12.7467 8.51301 13.1667 7.99967 13.1667C7.48634 13.1667 7.06634 12.7467 7.06634 12.2333V4.76667C7.06634 4.25334 7.48634 3.83334 7.99967 3.83334ZM11.733 9.16667C12.2463 9.16667 12.6663 9.58667 12.6663 10.1V12.2333C12.6663 12.7467 12.2463 13.1667 11.733 13.1667C11.2197 13.1667 10.7997 12.7467 10.7997 12.2333V10.1C10.7997 9.58667 11.2197 9.16667 11.733 9.16667Z"
          fill="#16A34A"
        />
      </g>
    </svg>
  );
};

export const SmallRightArrow = () => {
  return (
    <svg
      width={5}
      height={6}
      viewBox="0 0 5 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.75801 5.74164L3.91634 3.58331C4.24134 3.25831 4.24134 2.73331 3.91634 2.40831L1.75801 0.249976C1.23301 -0.266691 0.333008 0.099976 0.333008 0.841643V5.14998C0.333008 5.89998 1.23301 6.26664 1.75801 5.74164Z"
        fill="#1B1B1B"
        fillOpacity="0.7"
      />
    </svg>
  );
};

export const UsersIcon = () => {
  return (
    <svg
      width={24}
      height={23}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.2993 11C17.9593 11 19.2893 9.66 19.2893 8C19.2893 6.34 17.9593 5 16.2993 5C14.6393 5 13.2993 6.34 13.2993 8C13.2993 9.66 14.6393 11 16.2993 11ZM8.29932 11C9.95932 11 11.2893 9.66 11.2893 8C11.2893 6.34 9.95932 5 8.29932 5C6.63932 5 5.29932 6.34 5.29932 8C5.29932 9.66 6.63932 11 8.29932 11ZM8.29932 13C5.96932 13 1.29932 14.17 1.29932 16.5V18C1.29932 18.55 1.74932 19 2.29932 19H14.2993C14.8493 19 15.2993 18.55 15.2993 18V16.5C15.2993 14.17 10.6293 13 8.29932 13ZM16.2993 13C16.0093 13 15.6793 13.02 15.3293 13.05C15.3493 13.06 15.3593 13.08 15.3693 13.09C16.5093 13.92 17.2993 15.03 17.2993 16.5V18C17.2993 18.35 17.2293 18.69 17.1193 19H22.2993C22.8493 19 23.2993 18.55 23.2993 18V16.5C23.2993 14.17 18.6293 13 16.2993 13Z"
        fill="white"
      />
    </svg>
  );
};
export const RightCheck = () => {
  return (
    <svg
      width={10}
      height={10}
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.65474 5.46875C8.34224 7.03125 7.16412 8.5025 5.51099 8.83125C4.70474 8.99181 3.86837 8.89391 3.12099 8.55149C2.37361 8.20907 1.75332 7.63958 1.34843 6.92412C0.943535 6.20865 0.774692 5.38368 0.865936 4.56667C0.957181 3.74966 1.30386 2.98226 1.85662 2.37375C2.99037 1.125 4.90474 0.781248 6.46724 1.40625"
        stroke="#16A34A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.3418 4.84375L4.9043 6.40625L8.6543 2.34375"
        stroke="#16A34A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const LineChart = () => {
  return (
    <svg
      width={25}
      height={24}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.44997 17.78L9.74997 12.48L13 15.73C13.41 16.14 14.07 16.12 14.45 15.69L21.62 7.62003C21.97 7.23003 21.95 6.63003 21.58 6.25003C21.18 5.85003 20.51 5.86003 20.13 6.29003L13.74 13.47L10.45 10.18C10.06 9.79003 9.42997 9.79003 9.03997 10.18L2.94997 16.28C2.55997 16.67 2.55997 17.3 2.94997 17.69L3.03997 17.78C3.42997 18.17 4.06997 18.17 4.44997 17.78Z"
        fill="white"
      />
    </svg>
  );
};

export const SilverBoost = () => {
  return (
    <svg
      width={10}
      height={10}
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 5L6 0.5L4.5 4H9L2 9L5 5H1Z"
        fill="#DBDBD7"
        stroke="white"
        strokeWidth="0.1"
      />
    </svg>
  );
};

export const YellowBoost = () => {
  return (
    <svg
      width={6}
      height={10}
      viewBox="0 0 6 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.333496 5L3.66683 0.5L2.66683 4H5.66683L1.00016 9L3.00016 5H0.333496Z"
        fill="#EBFF00"
        stroke="white"
        strokeWidth="0.1"
      />
    </svg>
  );
};

export const HelpIcon = () => {
  return (
    <svg
      width={30}
      height={30}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 73.09 73.09"
    >
      <defs>
        <style
          dangerouslySetInnerHTML={{
            __html:
              ".cls-1{fill:none;}.cls-2{clip-path:url(#clip-path);}.cls-3{fill:#1d609d;}.cls-4{fill:#1d619e;}.cls-5{fill:#1d619f;}.cls-6{fill:#1d62a0;}.cls-7{fill:#1d62a1;}.cls-8{fill:#1d63a2;}.cls-9{fill:#1d63a3;}.cls-10{fill:#1d64a4;}.cls-11{fill:#1d64a5;}.cls-12{fill:#1d65a6;}.cls-13{fill:#1d65a7;}.cls-14{fill:#1e66a8;}.cls-15{fill:#1e67a9;}.cls-16{fill:#1e67aa;}.cls-17{fill:#1e68ab;}.cls-18{fill:#1e68ac;}.cls-19{fill:#1e69ad;}.cls-20{fill:#1e69ae;}.cls-21{fill:#1e6aaf;}.cls-22{fill:#1e6ab0;}.cls-23{fill:#1e6bb1;}.cls-24{fill:#1e6cb3;}.cls-25{fill:#1e6cb4;}.cls-26{fill:#1e6db5;}.cls-27{fill:#1e6db6;}.cls-28{fill:#1e6eb7;}.cls-29{fill:#1e6eb8;}.cls-30{fill:#1e6fb9;}.cls-31{fill:#1e6fba;}.cls-32{fill:#1e70bb;}.cls-33{fill:#1e70bc;}.cls-34{fill:#1e71bd;}.cls-35{fill:#1f72be;}.cls-36{fill:#1f72bf;}.cls-37{fill:#1f73c0;}.cls-38{fill:#1f73c1;}.cls-39{fill:#1f74c2;}.cls-40{fill:#1f74c3;}.cls-41{fill:#1f75c4;}.cls-42{fill:#1f75c5;}.cls-43{fill:#1f76c6;}.cls-44{fill:#1f76c7;}.cls-45{fill:#1f77c8;}.cls-46{fill:#231f20;}.cls-47{fill:#fff;}",
          }}
        />
        <clipPath id="clip-path">
          <circle className="cls-1" cx="36.54" cy="36.54" r="35.11" />
        </clipPath>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="OBJECTS">
          <g className="cls-2">
            <rect
              className="cls-3"
              x="1.43"
              y="1.43"
              width="70.22"
              height="70.22"
            />
            <circle className="cls-3" cx="36.54" cy="36.54" r="35.11" />
            <circle className="cls-4" cx="36.54" cy="36.54" r="34.79" />
            <circle className="cls-5" cx="36.54" cy="36.54" r="34.47" />
            <circle className="cls-6" cx="36.54" cy="36.54" r="34.15" />
            <circle className="cls-7" cx="36.54" cy="36.54" r="33.83" />
            <circle className="cls-8" cx="36.54" cy="36.54" r="33.51" />
            <circle className="cls-9" cx="36.54" cy="36.54" r="33.19" />
            <circle className="cls-10" cx="36.54" cy="36.54" r="32.88" />
            <circle className="cls-11" cx="36.54" cy="36.54" r="32.56" />
            <circle className="cls-12" cx="36.54" cy="36.54" r="32.24" />
            <circle className="cls-13" cx="36.54" cy="36.54" r="31.92" />
            <circle className="cls-14" cx="36.54" cy="36.54" r="31.6" />
            <circle className="cls-15" cx="36.54" cy="36.54" r="31.28" />
            <circle className="cls-16" cx="36.54" cy="36.54" r="30.96" />
            <circle className="cls-17" cx="36.54" cy="36.54" r="30.64" />
            <circle className="cls-18" cx="36.54" cy="36.54" r="30.32" />
            <circle className="cls-19" cx="36.54" cy="36.54" r={30} />
            <circle className="cls-20" cx="36.54" cy="36.54" r="29.68" />
            <circle className="cls-21" cx="36.54" cy="36.54" r="29.36" />
            <circle className="cls-22" cx="36.54" cy="36.54" r="29.04" />
            <circle className="cls-23" cx="36.54" cy="36.54" r="28.72" />
            <circle className="cls-24" cx="36.54" cy="36.54" r="28.41" />
            <circle className="cls-25" cx="36.54" cy="36.54" r="28.09" />
            <circle className="cls-26" cx="36.54" cy="36.54" r="27.77" />
            <circle className="cls-27" cx="36.54" cy="36.54" r="27.45" />
            <circle className="cls-28" cx="36.54" cy="36.54" r="27.13" />
            <circle className="cls-29" cx="36.54" cy="36.54" r="26.81" />
            <circle className="cls-30" cx="36.54" cy="36.54" r="26.49" />
            <circle className="cls-31" cx="36.54" cy="36.54" r="26.17" />
            <circle className="cls-32" cx="36.54" cy="36.54" r="25.85" />
            <circle className="cls-33" cx="36.54" cy="36.54" r="25.53" />
            <circle className="cls-34" cx="36.54" cy="36.54" r="25.21" />
            <circle className="cls-35" cx="36.54" cy="36.54" r="24.89" />
            <circle className="cls-36" cx="36.54" cy="36.54" r="24.57" />
            <circle className="cls-37" cx="36.54" cy="36.54" r="24.25" />
            <circle className="cls-38" cx="36.54" cy="36.54" r="23.93" />
            <circle className="cls-39" cx="36.54" cy="36.54" r="23.62" />
            <circle className="cls-40" cx="36.54" cy="36.54" r="23.3" />
            <circle className="cls-41" cx="36.54" cy="36.54" r="22.98" />
            <circle className="cls-42" cx="36.54" cy="36.54" r="22.66" />
            <circle className="cls-43" cx="36.54" cy="36.54" r="22.34" />
            <circle className="cls-44" cx="36.54" cy="36.54" r="22.02" />
            <circle className="cls-45" cx="36.54" cy="36.54" r="21.7" />
          </g>
          <path
            className="cls-46"
            d="M36.54,73.09A36.55,36.55,0,0,1,10.7,10.7,36.55,36.55,0,0,1,73.09,36.54,36.57,36.57,0,0,1,36.54,73.09Zm0-70.22A33.68,33.68,0,1,0,70.22,36.54,33.7,33.7,0,0,0,36.54,2.87Z"
          />
          <path
            className="cls-47"
            d="M22.61,37.77H17.35v5.71H14V29.61h3.38V35h5.26V29.61H26V43.48H22.61Z"
          />
          <path
            className="cls-47"
            d="M36.92,32.31H31.8v2.81h4.53v2.61H31.8v3h5.12v2.71h-8.5V29.61h8.5Z"
          />
          <path
            className="cls-47"
            d="M42.49,29.61V40.87h4.43v2.61H39.11V29.61Z"
          />
          <path
            className="cls-47"
            d="M54,38.5h-2.1v5H48.54V29.61H54c3.4,0,5.1,1.91,5.1,4.46C59.12,36.37,57.56,38.5,54,38.5Zm-.26-2.69c1.35,0,1.92-.67,1.92-1.74s-.57-1.74-1.92-1.74H51.92v3.48Z"
          />
        </g>
      </g>
    </svg>
  );
};

export const Coins = () => {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      style={{ enableBackground: "new 0 0 512 512" }}
      xmlSpace="preserve"
    >
      <g>
        <g>
          <g>
            <g>
              <path
                style={{ fill: "#FFD200" }}
                d="M256,416c-44.859,0-80,21.086-80,48c0,26.914,35.141,48,80,48s80-21.086,80-48
                        C336,437.086,300.859,416,256,416z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FF9600" }}
                d="M256,400c-44.859,0-80,21.086-80,48c0,26.914,35.141,48,80,48s80-21.086,80-48
                        C336,421.086,300.859,400,256,400z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FFD200" }}
                d="M256,384c-44.859,0-80,21.086-80,48s35.141,48,80,48s80-21.086,80-48S300.859,384,256,384z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FF9600" }}
                d="M256,368c-44.859,0-80,21.086-80,48c0,26.914,35.141,48,80,48s80-21.086,80-48
                        C336,389.086,300.859,368,256,368z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FFD200" }}
                d="M256,352c-44.859,0-80,21.086-80,48c0,26.914,35.141,48,80,48s80-21.086,80-48
                        C336,373.086,300.859,352,256,352z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FF9600" }}
                d="M256,336c-44.859,0-80,21.086-80,48c0,26.914,35.141,48,80,48s80-21.086,80-48
                        C336,357.086,300.859,336,256,336z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FFD200" }}
                d="M256,320c-44.859,0-80,21.086-80,48c0,26.914,35.141,48,80,48s80-21.086,80-48
                        C336,341.086,300.859,320,256,320z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FF9600" }}
                d="M256,304c-44.859,0-80,21.086-80,48c0,26.914,35.141,48,80,48s80-21.086,80-48
                        C336,325.086,300.859,304,256,304z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FFD200" }}
                d="M256,288c-44.859,0-80,21.086-80,48c0,26.914,35.141,48,80,48s80-21.086,80-48
                        C336,309.086,300.859,288,256,288z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FF9600" }}
                d="M256,272c-44.859,0-80,21.086-80,48c0,26.914,35.141,48,80,48s80-21.086,80-48
                        C336,293.086,300.859,272,256,272z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FFD200" }}
                d="M256,256c-44.859,0-80,21.086-80,48c0,26.914,35.141,48,80,48s80-21.086,80-48
                        C336,277.086,300.859,256,256,256z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FF9600" }}
                d="M256,240c-44.859,0-80,21.086-80,48c0,26.914,35.141,48,80,48s80-21.086,80-48
                        C336,261.086,300.859,240,256,240z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FFD200" }}
                d="M256,224c-44.859,0-80,21.086-80,48c0,26.914,35.141,48,80,48s80-21.086,80-48
                        C336,245.086,300.859,224,256,224z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FF9600" }}
                d="M256,208c-44.859,0-80,21.086-80,48s35.141,48,80,48s80-21.086,80-48S300.859,208,256,208z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FF9600" }}
                d="M176,464c0,26.914,35.141,48,80,48v-96C211.141,416,176,437.086,176,464z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#DF8328" }}
                d="M176,448c0,26.914,35.141,48,80,48v-96C211.141,400,176,421.086,176,448z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FF9600" }}
                d="M176,432c0,26.914,35.141,48,80,48v-96C211.141,384,176,405.086,176,432z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#DF8328" }}
                d="M176,416c0,26.914,35.141,48,80,48v-96C211.141,368,176,389.086,176,416z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FF9600" }}
                d="M176,400c0,26.914,35.141,48,80,48v-96C211.141,352,176,373.086,176,400z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#DF8328" }}
                d="M176,384c0,26.914,35.141,48,80,48v-96C211.141,336,176,357.086,176,384z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FF9600" }}
                d="M176,368c0,26.914,35.141,48,80,48v-96C211.141,320,176,341.086,176,368z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FF9600" }}
                d="M176,368c0,26.914,35.141,48,80,48v-96C211.141,320,176,341.086,176,368z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#DF8328" }}
                d="M176,352c0,26.914,35.141,48,80,48v-96C211.141,304,176,325.086,176,352z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FF9600" }}
                d="M176,336c0,26.914,35.141,48,80,48v-96C211.141,288,176,309.086,176,336z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#DF8328" }}
                d="M176,320c0,26.914,35.141,48,80,48v-96C211.141,272,176,293.086,176,320z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FF9600" }}
                d="M176,304c0,26.914,35.141,48,80,48v-96C211.141,256,176,277.086,176,304z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FF9600" }}
                d="M176,304c0,26.914,35.141,48,80,48v-96C211.141,256,176,277.086,176,304z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#DF8328" }}
                d="M176,288c0,26.914,35.141,48,80,48v-96C211.141,240,176,261.086,176,288z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FF9600" }}
                d="M176,272c0,26.914,35.141,48,80,48v-96C211.141,224,176,245.086,176,272z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#DF8328" }}
                d="M176,256c0,26.914,35.141,48,80,48v-96C211.141,208,176,229.086,176,256z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FF9600" }}
                d="M176,240c0,26.914,35.141,48,80,48v-96C211.141,192,176,213.086,176,240z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FFD200" }}
                d="M256,192c-44.859,0-80,21.086-80,48s35.141,48,80,48s80-21.086,80-48S300.859,192,256,192z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FF9600" }}
                d="M256,176c-44.859,0-80,21.086-80,48s35.141,48,80,48s80-21.086,80-48S300.859,176,256,176z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FFD200" }}
                d="M256,160c-44.859,0-80,21.086-80,48s35.141,48,80,48s80-21.086,80-48S300.859,160,256,160z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FF9600" }}
                d="M256,144c-44.859,0-80,21.086-80,48s35.141,48,80,48s80-21.086,80-48S300.859,144,256,144z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FFD200" }}
                d="M256,128c-44.859,0-80,21.086-80,48s35.141,48,80,48s80-21.086,80-48S300.859,128,256,128z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FF9600" }}
                d="M176,240c0,26.914,35.141,48,80,48v-96C211.141,192,176,213.086,176,240z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#DF8328" }}
                d="M176,224c0,26.914,35.141,48,80,48v-96C211.141,176,176,197.086,176,224z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FF9600" }}
                d="M176,208c0,26.914,35.141,48,80,48v-96C211.141,160,176,181.086,176,208z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#DF8328" }}
                d="M176,192c0,26.914,35.141,48,80,48v-96C211.141,144,176,165.086,176,192z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FF9600" }}
                d="M176,176c0,26.914,35.141,48,80,48v-96C211.141,128,176,149.086,176,176z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FF9600" }}
                d="M256,112c-44.859,0-80,21.086-80,48s35.141,48,80,48s80-21.086,80-48S300.859,112,256,112z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#DF8328" }}
                d="M176,160c0,26.914,35.141,48,80,48v-96C211.141,112,176,133.086,176,160z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FFD200" }}
                d="M256,96c-44.859,0-80,21.086-80,48s35.141,48,80,48s80-21.086,80-48S300.859,96,256,96z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FF9600" }}
                d="M256,80c-44.859,0-80,21.086-80,48s35.141,48,80,48s80-21.086,80-48S300.859,80,256,80z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FF9600" }}
                d="M176,144c0,26.914,35.141,48,80,48V96C211.141,96,176,117.086,176,144z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#DF8328" }}
                d="M176,128c0,26.914,35.141,48,80,48V80C211.141,80,176,101.086,176,128z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FFD200" }}
                d="M256,64c-44.859,0-80,21.086-80,48s35.141,48,80,48s80-21.086,80-48S300.859,64,256,64z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FF9600" }}
                d="M256,48c-44.859,0-80,21.086-80,48s35.141,48,80,48s80-21.086,80-48S300.859,48,256,48z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FFD200" }}
                d="M256,32c-44.859,0-80,21.086-80,48s35.141,48,80,48s80-21.086,80-48S300.859,32,256,32z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FF9600" }}
                d="M176,112c0,26.914,35.141,48,80,48V64C211.141,64,176,85.086,176,112z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#DF8328" }}
                d="M176,96c0,26.914,35.141,48,80,48V48C211.141,48,176,69.086,176,96z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FF9600" }}
                d="M176,80c0,26.914,35.141,48,80,48V32C211.141,32,176,53.086,176,80z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FF9600" }}
                d="M256,16c-44.859,0-80,21.086-80,48s35.141,48,80,48s80-21.086,80-48S300.859,16,256,16z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#DF8328" }}
                d="M176,64c0,26.914,35.141,48,80,48V16C211.141,16,176,37.086,176,64z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FFD200" }}
                d="M256,0c-44.859,0-80,21.086-80,48s35.141,48,80,48s80-21.086,80-48S300.859,0,256,0z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FFEF43" }}
                d="M256,80c-32.445,0-56-13.461-56-32s23.555-32,56-32s56,13.461,56,32S288.445,80,256,80z M256,32
                        c-24.781,0-40,9.32-40,16s15.219,16,40,16s40-9.32,40-16S280.781,32,256,32z"
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <path
                style={{ fill: "#FFD200" }}
                d="M400,416c-44.859,0-80,21.086-80,48c0,26.914,35.141,48,80,48s80-21.086,80-48
                        C480,437.086,444.859,416,400,416z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FF9600" }}
                d="M400,400c-44.859,0-80,21.086-80,48c0,26.914,35.141,48,80,48s80-21.086,80-48
                        C480,421.086,444.859,400,400,400z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FF9600" }}
                d="M320,464c0,26.914,35.141,48,80,48v-96C355.14,416,320,437.086,320,464z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#DF8328" }}
                d="M320,448c0,26.914,35.141,48,80,48v-96C355.14,400,320,421.086,320,448z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FF9600" }}
                d="M320,432c0,26.914,35.141,48,80,48v-96C355.14,384,320,405.086,320,432z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FFD200" }}
                d="M400,384c-44.859,0-80,21.086-80,48s35.141,48,80,48s80-21.086,80-48S444.859,384,400,384z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FF9600" }}
                d="M400,368c-44.859,0-80,21.086-80,48c0,26.914,35.141,48,80,48s80-21.086,80-48
                        C480,389.086,444.859,368,400,368z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FFD200" }}
                d="M400,352c-44.859,0-80,21.086-80,48c0,26.914,35.141,48,80,48s80-21.086,80-48
                        C480,373.086,444.859,352,400,352z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FF9600" }}
                d="M400,336c-44.859,0-80,21.086-80,48c0,26.914,35.141,48,80,48s80-21.086,80-48
                        C480,357.086,444.859,336,400,336z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FFD200" }}
                d="M400,320c-44.859,0-80,21.086-80,48c0,26.914,35.141,48,80,48s80-21.086,80-48
                        C480,341.086,444.859,320,400,320z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FF9600" }}
                d="M320,432c0,26.914,35.141,48,80,48v-96C355.14,384,320,405.086,320,432z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#DF8328" }}
                d="M320,416c0,26.914,35.141,48,80,48v-96C355.14,368,320,389.086,320,416z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FF9600" }}
                d="M320,400c0,26.914,35.141,48,80,48v-96C355.14,352,320,373.086,320,400z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#DF8328" }}
                d="M320,384c0,26.914,35.141,48,80,48v-96C355.14,336,320,357.086,320,384z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FF9600" }}
                d="M320,368c0,26.914,35.141,48,80,48v-96C355.14,320,320,341.086,320,368z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FF9600" }}
                d="M400,304c-44.859,0-80,21.086-80,48c0,26.914,35.141,48,80,48s80-21.086,80-48
                        C480,325.086,444.859,304,400,304z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#DF8328" }}
                d="M320,352c0,26.914,35.141,48,80,48v-96C355.14,304,320,325.086,320,352z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FFD200" }}
                d="M400,288c-44.859,0-80,21.086-80,48c0,26.914,35.141,48,80,48s80-21.086,80-48
                        C480,309.086,444.859,288,400,288z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FF9600" }}
                d="M400,272c-44.859,0-80,21.086-80,48c0,26.914,35.141,48,80,48s80-21.086,80-48
                        C480,293.086,444.859,272,400,272z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FF9600" }}
                d="M320,336c0,26.914,35.141,48,80,48v-96C355.14,288,320,309.086,320,336z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#DF8328" }}
                d="M320,320c0,26.914,35.141,48,80,48v-96C355.14,272,320,293.086,320,320z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FFD200" }}
                d="M400,256c-44.859,0-80,21.086-80,48c0,26.914,35.141,48,80,48s80-21.086,80-48
                        C480,277.086,444.859,256,400,256z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FF9600" }}
                d="M400,240c-44.859,0-80,21.086-80,48c0,26.914,35.141,48,80,48s80-21.086,80-48
                        C480,261.086,444.859,240,400,240z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FFD200" }}
                d="M400,224c-44.859,0-80,21.086-80,48c0,26.914,35.141,48,80,48s80-21.086,80-48
                        C480,245.086,444.859,224,400,224z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FF9600" }}
                d="M320,304c0,26.914,35.141,48,80,48v-96C355.14,256,320,277.086,320,304z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#DF8328" }}
                d="M320,288c0,26.914,35.141,48,80,48v-96C355.14,240,320,261.086,320,288z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FF9600" }}
                d="M320,272c0,26.914,35.141,48,80,48v-96C355.14,224,320,245.086,320,272z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FF9600" }}
                d="M400,208c-44.859,0-80,21.086-80,48s35.141,48,80,48s80-21.086,80-48S444.859,208,400,208z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#DF8328" }}
                d="M320,256c0,26.914,35.141,48,80,48v-96C355.14,208,320,229.086,320,256z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FFD200" }}
                d="M400,192c-44.859,0-80,21.086-80,48s35.141,48,80,48s80-21.086,80-48S444.859,192,400,192z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FFEF43" }}
                d="M400,272c-32.445,0-56-13.461-56-32s23.555-32,56-32s56,13.461,56,32S432.445,272,400,272z
                         M400,224c-24.781,0-40,9.32-40,16s15.219,16,40,16c24.781,0,40-9.32,40-16S424.781,224,400,224z"
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <path
                style={{ fill: "#FFD200" }}
                d="M112,416c-44.859,0-80,21.086-80,48c0,26.914,35.141,48,80,48s80-21.086,80-48
                        C192,437.086,156.859,416,112,416z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FF9600" }}
                d="M112,400c-44.859,0-80,21.086-80,48c0,26.914,35.141,48,80,48s80-21.086,80-48
                        C192,421.086,156.859,400,112,400z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FFD200" }}
                d="M112,384c-44.859,0-80,21.086-80,48s35.141,48,80,48s80-21.086,80-48S156.859,384,112,384z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FF9600" }}
                d="M112,368c-44.859,0-80,21.086-80,48c0,26.914,35.141,48,80,48s80-21.086,80-48
                        C192,389.086,156.859,368,112,368z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FFD200" }}
                d="M112,352c-44.859,0-80,21.086-80,48c0,26.914,35.141,48,80,48s80-21.086,80-48
                        C192,373.086,156.859,352,112,352z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FF9600" }}
                d="M112,336c-44.859,0-80,21.086-80,48c0,26.914,35.141,48,80,48s80-21.086,80-48
                        C192,357.086,156.859,336,112,336z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FF9600" }}
                d="M32,464c0,26.914,35.141,48,80,48v-96C67.141,416,32,437.086,32,464z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#DF8328" }}
                d="M32,448c0,26.914,35.141,48,80,48v-96C67.141,400,32,421.086,32,448z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FF9600" }}
                d="M32,432c0,26.914,35.141,48,80,48v-96C67.141,384,32,405.086,32,432z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FF9600" }}
                d="M32,432c0,26.914,35.141,48,80,48v-96C67.141,384,32,405.086,32,432z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#DF8328" }}
                d="M32,416c0,26.914,35.141,48,80,48v-96C67.141,368,32,389.086,32,416z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FF9600" }}
                d="M32,400c0,26.914,35.141,48,80,48v-96C67.141,352,32,373.086,32,400z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#DF8328" }}
                d="M32,384c0,26.914,35.141,48,80,48v-96C67.141,336,32,357.086,32,384z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FF9600" }}
                d="M32,368c0,26.914,35.141,48,80,48v-96C67.141,320,32,341.086,32,368z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FFD200" }}
                d="M112,320c-44.859,0-80,21.086-80,48c0,26.914,35.141,48,80,48s80-21.086,80-48
                        C192,341.086,156.859,320,112,320z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FF9600" }}
                d="M112,304c-44.859,0-80,21.086-80,48c0,26.914,35.141,48,80,48s80-21.086,80-48
                        C192,325.086,156.859,304,112,304z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FFD200" }}
                d="M112,288c-44.859,0-80,21.086-80,48c0,26.914,35.141,48,80,48s80-21.086,80-48
                        C192,309.086,156.859,288,112,288z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FF9600" }}
                d="M112,272c-44.859,0-80,21.086-80,48c0,26.914,35.141,48,80,48s80-21.086,80-48
                        C192,293.086,156.859,272,112,272z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FFD200" }}
                d="M112,256c-44.859,0-80,21.086-80,48c0,26.914,35.141,48,80,48s80-21.086,80-48
                        C192,277.086,156.859,256,112,256z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FF9600" }}
                d="M32,368c0,26.914,35.141,48,80,48v-96C67.141,320,32,341.086,32,368z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#DF8328" }}
                d="M32,352c0,26.914,35.141,48,80,48v-96C67.141,304,32,325.086,32,352z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FF9600" }}
                d="M32,336c0,26.914,35.141,48,80,48v-96C67.141,288,32,309.086,32,336z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#DF8328" }}
                d="M32,320c0,26.914,35.141,48,80,48v-96C67.141,272,32,293.086,32,320z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FF9600" }}
                d="M32,304c0,26.914,35.141,48,80,48v-96C67.141,256,32,277.086,32,304z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FF9600" }}
                d="M112,240c-44.859,0-80,21.086-80,48c0,26.914,35.141,48,80,48s80-21.086,80-48
                        C192,261.086,156.859,240,112,240z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#DF8328" }}
                d="M32,288c0,26.914,35.141,48,80,48v-96C67.141,240,32,261.086,32,288z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FFD200" }}
                d="M112,224c-44.859,0-80,21.086-80,48c0,26.914,35.141,48,80,48s80-21.086,80-48
                        C192,245.086,156.859,224,112,224z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FF9600" }}
                d="M112,208c-44.859,0-80,21.086-80,48s35.141,48,80,48s80-21.086,80-48S156.859,208,112,208z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FF9600" }}
                d="M32,272c0,26.914,35.141,48,80,48v-96C67.141,224,32,245.086,32,272z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#DF8328" }}
                d="M32,256c0,26.914,35.141,48,80,48v-96C67.141,208,32,229.086,32,256z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FFD200" }}
                d="M112,192c-44.859,0-80,21.086-80,48s35.141,48,80,48s80-21.086,80-48S156.859,192,112,192z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FF9600" }}
                d="M112,176c-44.859,0-80,21.086-80,48s35.141,48,80,48s80-21.086,80-48S156.859,176,112,176z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FFD200" }}
                d="M112,160c-44.859,0-80,21.086-80,48s35.141,48,80,48s80-21.086,80-48S156.859,160,112,160z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FF9600" }}
                d="M32,240c0,26.914,35.141,48,80,48v-96C67.141,192,32,213.086,32,240z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#DF8328" }}
                d="M32,224c0,26.914,35.141,48,80,48v-96C67.141,176,32,197.086,32,224z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FF9600" }}
                d="M32,208c0,26.914,35.141,48,80,48v-96C67.141,160,32,181.086,32,208z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FF9600" }}
                d="M112,144c-44.859,0-80,21.086-80,48s35.141,48,80,48s80-21.086,80-48S156.859,144,112,144z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#DF8328" }}
                d="M32,192c0,26.914,35.141,48,80,48v-96C67.141,144,32,165.086,32,192z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FFD200" }}
                d="M112,128c-44.859,0-80,21.086-80,48s35.141,48,80,48s80-21.086,80-48S156.859,128,112,128z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#FFEF43" }}
                d="M112,208c-32.445,0-56-13.461-56-32s23.555-32,56-32s56,13.461,56,32S144.445,208,112,208z
                         M112,160c-24.781,0-40,9.32-40,16c0,6.68,15.219,16,40,16s40-9.32,40-16C152,169.32,136.781,160,112,160z"
              />
            </g>
          </g>
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
};

export const OneCoin = () => {
  return (
    <svg
      id="Capa_1"
      enableBackground="new 0 0 512 512"
      height={15}
      viewBox="0 0 512 512"
      width={15}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g>
          <circle cx={256} cy={256} fill="#ffd05b" r="248.5" />
          <circle cx={256} cy={256} fill="#ffeaa5" r="200.403" />
          <circle cx={256} cy={256} fill="#ffe077" r="168.339" />
          <path
            d="m296.081 264.016-32.599-84.757c-2.635-6.851-12.328-6.851-14.964 0l-32.599 84.757-44.26-33.195c-6.225-4.669-14.782 1.377-12.461 8.804l29.157 93.302c2.092 6.693 8.29 11.251 15.303 11.251h104.684c7.012 0 13.211-4.557 15.303-11.251l29.157-93.302c2.321-7.427-6.236-13.473-12.461-8.804z"
            fill="#ffc244"
          />
          <path
            d="m273.628 486.872c-137.243 0-248.5-111.258-248.5-248.5 0-64.136 24.307-122.589 64.199-166.673-50.25 45.471-81.827 111.194-81.827 184.301 0 137.242 111.257 248.5 248.5 248.5 73.106 0 138.83-31.577 184.301-81.827-44.083 39.892-102.537 64.199-166.673 64.199z"
            fill="#ffc244"
          />
          <path
            d="m274.444 405.894c-92.971 0-168.339-75.368-168.339-168.339 0-41.79 15.316-79.943 40.526-109.373-36.047 30.874-58.97 76.634-58.97 127.818 0 92.971 75.368 168.339 168.339 168.339 51.182 0 96.941-22.922 127.816-58.968-29.431 25.208-67.583 40.523-109.372 40.523z"
            fill="#ffd05b"
          />
          <path
            d="m227.231 320.129c-10.576 0-19.798-6.779-22.952-16.87l-19.58-62.659-13.039-9.779c-6.225-4.669-14.783 1.377-12.462 8.804l29.157 93.302c2.091 6.693 8.29 11.251 15.303 11.251h104.683c7.013 0 13.212-4.558 15.303-11.251l3.999-12.798z"
            fill="#ffaa5a"
          />
        </g>
        <g>
          <path d="m437.02 74.98c-48.353-48.351-112.64-74.98-181.02-74.98s-132.667 26.629-181.02 74.98c-48.351 48.353-74.98 112.64-74.98 181.02s26.629 132.667 74.98 181.02c48.353 48.351 112.64 74.98 181.02 74.98s132.667-26.629 181.02-74.98c48.351-48.353 74.98-112.64 74.98-181.02s-26.629-132.667-74.98-181.02zm-10.607 351.433c-45.519 45.519-106.039 70.587-170.413 70.587s-124.894-25.068-170.413-70.587-70.587-106.039-70.587-170.413 25.068-124.894 70.587-170.413 106.039-70.587 170.413-70.587 124.894 25.068 170.413 70.587 70.587 106.039 70.587 170.413-25.068 124.894-70.587 170.413z" />
          <path d="m63.26 248.752c1.803-48.761 21.811-94.629 56.337-129.156s80.395-54.534 129.156-56.337c48.5-1.793 95.466 14.506 132.258 45.895 3.15 2.688 7.885 2.313 10.573-.838s2.313-7.885-.838-10.573c-39.66-33.836-90.282-51.412-142.547-49.473-52.557 1.943-101.996 23.508-139.208 60.72s-58.777 86.651-60.72 139.208c-1.933 52.263 15.637 102.888 49.473 142.547 1.483 1.739 3.589 2.632 5.709 2.632 1.722 0 3.452-.589 4.864-1.794 3.151-2.688 3.526-7.422.838-10.573-31.389-36.792-47.689-83.761-45.895-132.258z" />
          <path d="m414.257 121.254c-2.688-3.151-7.422-3.526-10.573-.838s-3.526 7.422-.838 10.573c31.389 36.792 47.688 83.762 45.895 132.258-1.803 48.761-21.811 94.629-56.337 129.156s-80.395 54.534-129.156 56.337c-48.492 1.794-95.466-14.505-132.258-45.895-3.15-2.688-7.885-2.313-10.573.838s-2.313 7.885.838 10.573c37.687 32.153 85.273 49.617 134.76 49.617 2.59 0 5.188-.048 7.788-.144 52.557-1.943 101.996-23.508 139.208-60.72s58.777-86.651 60.72-139.208c1.932-52.262-15.638-102.887-49.474-142.547z" />
          <path d="m364.537 209.794c-2.658-1.878-6.224-1.828-8.828.125l-56.191 42.143-4.454-11.581c-1.487-3.866-5.827-5.795-9.692-4.308-3.866 1.487-5.794 5.826-4.308 9.692l8.016 20.842c.844 2.194 2.667 3.866 4.927 4.516 2.259.648 4.693.202 6.573-1.208l45.539-34.154-29.634 94.829c-1.119 3.581-4.392 5.987-8.144 5.987h-104.683c-3.752 0-7.025-2.406-8.144-5.987l-29.634-94.829 45.539 34.154c1.881 1.41 4.314 1.856 6.573 1.208 2.259-.65 4.083-2.321 4.927-4.516l33.081-86.008 12.8 33.279c1.487 3.866 5.828 5.796 9.692 4.308 3.866-1.487 5.794-5.826 4.308-9.692l-19.8-51.48c-1.114-2.896-3.896-4.808-7-4.808s-5.886 1.911-7 4.808l-36.519 94.948-56.191-42.143c-2.604-1.953-6.17-2.004-8.828-.125-2.658 1.878-3.802 5.256-2.831 8.363l36.565 117.007c3.086 9.877 12.113 16.513 22.461 16.513h104.685c10.348 0 19.375-6.636 22.461-16.513l36.565-117.008c.971-3.106-.173-6.484-2.831-8.362z" />
          <path d="m256 80.161c-96.958 0-175.839 78.881-175.839 175.839s78.881 175.839 175.839 175.839 175.839-78.881 175.839-175.839-78.881-175.839-175.839-175.839zm0 336.678c-88.687 0-160.839-72.152-160.839-160.839s72.152-160.839 160.839-160.839 160.839 72.152 160.839 160.839-72.152 160.839-160.839 160.839z" />
        </g>
      </g>
    </svg>
  );
};
