import React, { useCallback, useEffect, useState } from "react";
import Select from "react-select";
import toast from "react-hot-toast";

import {
    Add_Demo_Contest_POST,
    Get_Cricket_Match_key_value_list_POST,
    Get_Demo_Contest_Details_GET,
} from "api/ApiConst";
import { isEmpty } from "utils/TextUtils";
import PageHeading from "components/common/PageHeading";
import { callGetApi, callPostApi } from "api/ApiCaller";

const restartIntervalKeyValuePairList = [
    { label: "3 min.", value: 3 },
    { label: "10 min.", value: 10 },
    { label: "20 min.", value: 20 },
    { label: "30  min.", value: 30 },
    { label: " 40 min.", value: 40 },
];
const waitingTimeCreateTeamKeyValuePairList = [
    { label: "1 min.", value: 1 },
    { label: "2 min.", value: 2 },
    { label: "5 min.", value: 5 },
    { label: "10 min.", value: 10 },
    { label: "15 min.", value: 15 },
    { label: "20 min.", value: 20 },
];
const rankDistribution = {
    rankFrom: 0,
    rankTo: 0,
    rewardPercentage: 0,
};

const waitingTimeInSec = [
    {
        label: "5 sec",
        value: 5,
    },
    {
        label: "10 sec",
        value: 10,
    },
    {
        label: "15 sec",
        value: 15,
    },
    {
        label: "20 sec",
        value: 20,
    },
    {
        label: "30 sec",
        value: 30,
    },
];
const applyBoosterTimeInSec = [
    {
        label: "20 sec",
        value: 20,
    },
    {
        label: "30 sec",
        value: 30,
    },
    {
        label: "1 min",
        value: 60,
    },
];

const defaultRecordObj = {
    clientId: 0,
    clientName: "",
    cricketMatchId: 0,
    cricketMatchTitle: "",
    publishDateTimeStamp: 0,
    startDateTimeStamp: 0,
    rewardDistributionList: [],
    waitingTimeForDrawNextPart: 0,
    waitingTimeForApplyBooster: 0,
    minimumNumberOfParticipantsRequired: 1,
    maximumNumberOfParticipants: 100,
    prizePoolTypeEnum: { label: "PlayerPool", value: "PlayerPool" },
    entryFee: 1,
    prizePool: 100,
    waitingTimeForCreateTeam: [],
    restartInterval: [],
};

const DemoContestMaster = () => {
    const [record, setRecord] = useState(defaultRecordObj);
    const [refreshFlag, setRefreshFlag] = useState(0);
    const [recordID, setRecordID] = useState("Demo-Contest-Game");
    const [matchList, setMatchList] = useState([]);
    const [selectedMatchList, setSelectedMatchList] = useState({});
    const [selectedPrizePoolTypeEnum, setSelectedPrizePoolTypeEnum] = useState({
        label: "PlayerPool",
        value: "PlayerPool",
    });
    const [selectedWaitingTimeForDrawNextPart, setSelectedWaitingTimeForDrawNextPart] = useState({});
    const [selectedWaitingTimeForApplyBooster, setSelectedWaitingTimeForApplyBooster,] = useState({});
    const [selectedWaitingTimeForCreateTeam, setSelectedWaitingTimeForCreateTeam] = useState({});
    const [selectedRestartInterval, setSelectedRestartInterval] = useState({});
    const [prizePoolTypeEnum, setPrizePoolTypeEnum] = useState([{ label: "PlayerPool", value: "PlayerPool" },]);

    const { rewardDistributionList } = record;

    useEffect(() => {
        getMatchList();
    }, [])

    useEffect(() => {
        if (recordID) {
            callGetApi(
                "", Get_Demo_Contest_Details_GET.replaceAll("{recordID}", recordID),
                {}, (response) => {
                    const res = response.responsePacket;
                    if (res.rewardDistributionList) {
                        setRecord(res);
                    } else {
                        const _res = { ...res, rewardDistributionList: [] };
                        setRecord(_res);
                    }
                    setSelectedMatchList(
                        matchList.find((data) => data.key == res.cricketMatchId)
                    );

                    setSelectedPrizePoolTypeEnum(
                        prizePoolTypeEnum.find(
                            (data) => data.value === res.prizePoolTypeEnum
                        )
                    );
                    setSelectedWaitingTimeForDrawNextPart(
                        waitingTimeInSec.find(
                            (data) => data.value === res.waitingTimeForDrawNextPart
                        )
                    );
                    setSelectedWaitingTimeForApplyBooster(
                        applyBoosterTimeInSec.find(
                            (data) => data.value === res.waitingTimeForApplyBooster
                        )
                    );
                    setSelectedRestartInterval(
                        restartIntervalKeyValuePairList.find(
                            (data) => data.value === res.restartInterval
                        )
                    );
                    setSelectedWaitingTimeForCreateTeam(
                        waitingTimeCreateTeamKeyValuePairList.find(
                            (data) => data.value === res.waitingTimeForCreateTeam
                        )
                    );

                })
        }
    }, [recordID, matchList]);

    const getMatchList = () => {
        callPostApi("Loader", Get_Cricket_Match_key_value_list_POST, {}, {}, (res) => {
            setMatchList(res.responsePacket);
        });
    };

    const handleRefresh = useCallback(() => {
        setRefreshFlag(refreshFlag + 1);
    }, [refreshFlag]);

    //Text Change Event
    const handleTextChange = (e) => {
        const { name, id, value } = e.target;
        setRecord((prev) => ({ ...prev, [name]: value }));
    };

    const handleDynamicTextChange = (e, index) => {
        const { value, id } = e.target;
        const _rowRewardDistribution = [...rewardDistributionList];
        _rowRewardDistribution[index] = {
            ..._rowRewardDistribution[index],
            [id]: value,
        };

        setRecord((prev) => ({
            ...prev,
            rewardDistributionList: _rowRewardDistribution,
        }));
    };

    const addRewardDistribution = (index) => {
        return (
            <div key={index} className=" rounded border d-flex justify-content-around align-items-end border-dark mb-3 p-3 ">
                <div className="col-3">
                    <label>Rank From*</label>
                    <input
                        type="number"
                        className="form-control input-default"
                        id="rankFrom"
                        name="rankFrom"
                        onChange={(e) => handleDynamicTextChange(e, index)}
                        value={rewardDistributionList[index].rankFrom}
                    />
                </div>
                <div className="col-3">
                    <label>Rank To*</label>
                    <input
                        type="number"
                        className="form-control input-default"
                        id="rankTo"
                        name="rankTo"
                        onChange={(e) => handleDynamicTextChange(e, index)}
                        value={rewardDistributionList[index].rankTo}
                    />
                </div>
                <div className="col-3">
                    <label>Reward Percentage ( %) *</label>
                    <input
                        type="number"
                        className="form-control input-default"
                        id="rewardPercentage"
                        name="rewardPercentage"
                        onChange={(e) => handleDynamicTextChange(e, index)}
                        value={rewardDistributionList[index].rewardPercentage}
                    />
                </div>
                <button
                    className="btn btn-danger"
                    onClick={() => handleClickRemoveRewardDistribution(index)}
                >
                    Remove
                </button>
            </div>
        );
    };

    const handleSelectMatchList = (e) => {
        setRecord({ ...record, cricketMatchId: e.value });
        setSelectedMatchList({
            label: e.label,
            value: e.value,
            ...e,
        });
    };

    const handleSelectWaitingTimeForDrawNextPart = (e) => {
        setSelectedWaitingTimeForDrawNextPart({
            label: e.label,
            value: e.value,
            ...e,
        });

    };

    const handleSelectWaitingTimeForApplyBooster = (e) => {
        setRecord({ ...record, prizePoolTypeEnum: e.value });
        setSelectedWaitingTimeForApplyBooster({
            label: e.label,
            value: e.value,
            ...e,
        });
    };
    const handleSelectWaitingTimeForCreateTeam = (e) => {
        setRecord({ ...record, waitingTimeForCreateTeam: e.value });
        setSelectedWaitingTimeForCreateTeam({
            label: e.label,
            value: e.value,
            ...e,
        });
    };
    const handleSelectRestartInterval = (e) => {
        setRecord({ ...record, restartInterval: e.value });
        setSelectedRestartInterval({
            label: e.label,
            value: e.value,
            ...e,
        });
    };

    const handleSelectPrizePoolTypeEnum = (e) => {
        setRecord({ ...record, prizePoolTypeEnum: e.value });
        setSelectedPrizePoolTypeEnum({
            label: e.label,
            value: e.value,
            ...e,
        });
    };

    const handleClickAddRewardDistribution = () => {

        setRecord((prev) => ({
            ...prev,
            rewardDistributionList: [
                ...prev.rewardDistributionList,
                rankDistribution,
            ],
        }));
    };

    const handleClickRemoveRewardDistribution = (index) => {
        const _rowRewardDistribution = [...rewardDistributionList];
        _rowRewardDistribution.splice(index, 1);
        setRecord((prev) => ({
            ...prev,
            rewardDistributionList: _rowRewardDistribution,
        }));
    };

    const checkInputValidation = () => {
        if (isEmpty(record.cricketMatchId)) {
            toast.error(`Cricket Match must not be empty`, { id: "toast" });
            return false;
        } else if (isEmpty(record.waitingTimeForDrawNextPart)) {
            toast.error(`Waiting Time For Draw Next Part must not be empty`, { id: "toast" });
            return false;
        } else if (isEmpty(record.waitingTimeForApplyBooster)) {
            toast.error(`Waiting Time For Apply Booster must not be empty`, { id: "toast" });
            return false;
        }
        else if (isEmpty(record.waitingTimeForCreateTeam)) {
            toast.error(`Create Team waiting time  must not be empty`, { id: "toast" });
            return false;
        }
        else if (isEmpty(record.restartInterval)) {
            toast.error(`Restart Interval  must not be empty`, { id: "toast" });
            return false;
        }
        else if (isEmpty(record.minimumNumberOfParticipantsRequired)) {
            toast.error(`Minimum Number Of Participants Required must not be empty`, { id: "toast" });
            return false;
        } else if (isEmpty(record.maximumNumberOfParticipants)) {
            toast.error(`Maximum Number Of Participants must not be empty`, { id: "toast" });
            return false;
        } else if (
            parseInt(record.minimumNumberOfParticipantsRequired) >
            parseInt(record.maximumNumberOfParticipants)
        ) {
            toast.error(`Maximum number of participants should be grater to minimum number of participants`, { id: "toast" });
            return false;
        } else if (isEmpty(record.prizePoolTypeEnum)) {
            toast.error(`Prize Pool must not be empty`, { id: "toast" });
            return false;
        } else if (isEmpty(record.entryFee)) {
            toast.error(`Entry Fee must not be empty`, { id: "toast" });
            return false;
        } else if (isEmpty(record.prizePool)) {
            toast.error(`Prize Pool must not be empty`, { id: "toast" });
            return false;
        } else if (isEmpty(record.prizePool)) {
            toast.error(`Prize Pool must not be empty`, { id: "toast" });
            return false;
        } else if (isEmpty(record.rewardDistributionList[0])) {
            toast.error(`Add Reward Distribution must not be empty`, { id: "toast" });
            return false;
        } else if (record.minimumNumberOfParticipantsRequired < 0) {
            toast.error('Minimum Number Of Participants must not be negative', { id: "toast" });
            return false;
        } else if (record.maximumNumberOfParticipants < 0) {
            toast.error('Maximum Number Of Participants must not be negative', { id: "toast" });
            return false;
        } else if (record?.waitingTimeForCreateTeam >= record?.restartInterval) {
            toast.error("Create team waiting time cannot be equal or more than Restart Interval time.", { id: "toast" });
            return false;
        }

        let arr = record.rewardDistributionList;

        for (let i = 0; i < arr.length; i++) {
            if (isEmpty(parseInt(arr[i].rankFrom))) {
                toast.error(`Rank From must not be empty in row ${i + 1}`, {
                    id: "toast"
                });
                return false;
            }
            if (isEmpty(parseInt(arr[i].rankTo))) {
                toast.error(`Rank To must not be empty in row ${i + 1}`, {
                    id: "toast"
                });
                return false;
            }
            if (isEmpty(parseInt(arr[i].rewardPercentage))) {
                toast.error(`Reward Percentage must not be empty in row ${i + 1}`, {
                    id: "toast"
                });
                return false;
            }

            if (parseInt(arr[i].rankFrom) > parseInt(arr[i].rankTo)) {
                toast.error(`RankFrom in row ${i + 1}  greater then  or equal to RankTo row ${i}`, {
                    id: "toast"
                });
                return false;
            }

            for (let j = 0; j < i; j++) {
                if (parseInt(arr[j].rankTo) >= parseInt(arr[i].rankFrom)) {
                    toast.error(`RankTo row ${j + 1} is not greater than  or equal to RankFrom row ${i + 1}`, {
                        id: "toast"
                    });
                    return false;
                }
            }
            //   totalPercentage += arr[i].rewardPercentage;
        }
        return true;
    };
    //Submit Avatar Form
    const handleSubmit = async () => {
        try {
            if (!checkInputValidation()) {
                return;
            }
            let recordObj = {
                ...record,
                cricketMatchId: selectedMatchList.key,
                prizePoolTypeEnum: selectedPrizePoolTypeEnum.value,
                waitingTimeForDrawNextPart: selectedWaitingTimeForDrawNextPart.value,
                waitingTimeForApplyBooster: selectedWaitingTimeForApplyBooster.value,
                restartInterval: selectedRestartInterval.value,
                waitingTimeForCreateTeam: selectedWaitingTimeForCreateTeam.value,
            }

            callPostApi("", Add_Demo_Contest_POST, recordObj, {}, (response) => {
                toast.success(response.message);
                // hideModal();
                handleRefresh();
            }
            );
        } catch (error) {
            toast.error(error);
            return;
        }
    };
    return (
        <>
            {PageHeading("Demo Contest List", "Dashboard", "/")}
            <div className="card col-lg-9 py-5 px-4 ">
                <div className="form-group row ">
                    <div className="col-lg-4 mb-3">
                        <label>Select Cricket Match*</label>
                        <Select
                            value={selectedMatchList}
                            options={matchList}
                            onChange={handleSelectMatchList}
                        />
                    </div>
                    <div className="col-lg-4 mb-3">
                        <label>Waiting Time For Draw Next Part*</label>
                        <Select
                            value={selectedWaitingTimeForDrawNextPart}
                            options={waitingTimeInSec}
                            onChange={handleSelectWaitingTimeForDrawNextPart}
                        />
                    </div>
                    <div className="col-lg-4 mb-3">
                        <label>Waiting Time For Apply Booster*</label>
                        <Select
                            value={selectedWaitingTimeForApplyBooster}
                            options={applyBoosterTimeInSec}
                            onChange={handleSelectWaitingTimeForApplyBooster}
                        />
                    </div>
                    <div className="col-lg-4 mb-3">
                        <label>Waiting Time For Create Team *</label>
                        <Select
                            value={selectedWaitingTimeForCreateTeam}
                            options={waitingTimeCreateTeamKeyValuePairList}
                            onChange={handleSelectWaitingTimeForCreateTeam}
                        />
                    </div>
                    <div className="col-lg-4 mb-3">
                        <label>Restart Interval *</label>
                        <Select
                            options={restartIntervalKeyValuePairList}
                            onChange={handleSelectRestartInterval}
                            value={selectedRestartInterval}
                        />
                    </div>
                    <div className="col-lg-4 mb-3">
                        <label>Select Prize Pool Type*</label>
                        <Select
                            value={selectedPrizePoolTypeEnum}
                            options={prizePoolTypeEnum}
                            onChange={handleSelectPrizePoolTypeEnum}
                        />
                    </div>
                    {/* <div className="col-lg-4 mb-3">
                        <label>Restart Interval *</label>
                        <input
                            type="number"
                            className="form-control input-default"
                            id="restartInterval"
                            name="restartInterval"
                            // disabled
                            onChange={handleTextChange}
                            value={record?.restartInterval}
                        />
                    </div>
                    <div className="col-lg-4 mb-3">
                        <label> Waiting Time For Create Team*</label>
                        <input
                            type="number"
                            className="form-control input-default"
                            id="waitingTimeForCreateTeam"
                            name="waitingTimeForCreateTeam"
                            // disabled
                            onChange={handleTextChange}
                            value={record?.waitingTimeForCreateTeam}
                        />
                    </div> */}

                    <div className="col-lg-4 mb-3">
                        <label>Entry Fee*</label>
                        <input
                            type="number"
                            className="form-control input-default"
                            id="entryFee"
                            name="entryFee"
                            disabled
                            onChange={handleTextChange}
                            value={record?.entryFee}
                        />
                    </div>
                    <div className="col-lg-4 mb-3">
                        <label>Prize Pool*</label>
                        <input
                            type="number"
                            className="form-control input-default"
                            id="prizePool"
                            name="prizePool"
                            disabled
                            onChange={handleTextChange}
                            value={record?.prizePool}
                        />
                    </div>
                    <div className="col-lg-4 mb-3">
                        <label>Min. No. Of Participants Required*</label>
                        <input
                            type="number"
                            className="form-control input-default"
                            id="minimumNumberOfParticipantsRequired"
                            name="minimumNumberOfParticipantsRequired"
                            disabled
                            onChange={handleTextChange}
                            value={record?.minimumNumberOfParticipantsRequired}
                        />
                    </div>
                    <div className="col-lg-4 mb-3">
                        <label>Max. No. Of Participants*</label>
                        <input
                            type="number"
                            className="form-control input-default"
                            id="maximumNumberOfParticipants"
                            name="maximumNumberOfParticipants"
                            disabled
                            onChange={handleTextChange}
                            value={record?.maximumNumberOfParticipants}
                        />
                    </div>
                    <div className="col-lg-12 mb-3">
                        <div className="row">
                            <div className="col-12 d-flex justify-content-between align-items-center mb-3">
                                <label>Reward Distribution *</label>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={handleClickAddRewardDistribution}
                                >
                                    Add
                                </button>
                            </div>
                            <div className="col-12">
                                {rewardDistributionList &&
                                    rewardDistributionList.map((data, i) =>
                                        addRewardDistribution(i)
                                    )}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 mb-3">
                        <div className="row">
                            <div className="col-12  text-end mb-3">
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={handleSubmit}
                                >  Submit </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DemoContestMaster;
