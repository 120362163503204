// // // // Live Server
export const HTTPS = process.env.REACT_APP_HTTPS;
export const HOST = process.env.REACT_APP_HOST;
export const PORT = process.env.REACT_APP_PORT;
export const SERVICE = process.env.REACT_APP_SERVICE;

export const BASE_URL = `${HTTPS}://${HOST}:${PORT}` + (SERVICE !== "" ? "/" + SERVICE : "");
export const BASE_URL_V1 = BASE_URL + "/v1/";
export const BASE_URL_OPS_V1 = BASE_URL + "/ops/v1/";
export const BASE_URL_OPS_V1_SUPER_ADMIN = BASE_URL + "/ops/super/v1/";
export const BASE_URL_OPS_V1_ADMIN = BASE_URL + "/ops/admin/v1/";
// export const BASE_URL_OPS_V1_CLIENT = BASE_URL + "/ops/client/v1/";
// export const BASE_URL_OPS_V1_RESTAURANT = BASE_URL + "/ops/user-restaurant/v1/";

// Auth-controller
export const Admin_Login_POST = BASE_URL + "/auth/admin-login";
export const Client_Login_POST = BASE_URL + "/auth/client-login";
export const Restaurant_Login_POST = BASE_URL + "/auth/restaurant-login";
export const Admin_Change_Password_POST = BASE_URL + "/profile/changePassword";

// Common APIs
// export const UploadFile_POST = BASE_URL + "/uploadFile/inBase64";
export const Upload_Image_Custom_Server_POST = BASE_URL + "/public/upload-file/inBase64";

const recordID = "{recordID}";
const updateURL = "update/" + recordID;
const reviveURL = "revive/" + recordID;
const activateURL = "activate/" + recordID;
const inActivateURL = "inactivate/" + recordID;
const saveURL = "save";
const listURL = "list";
const deletedListURL = "deleted-list";
const getURL = "get/" + recordID;
const deleteURL = "delete/" + recordID;
const cancelURL = "cancel/" + recordID;

// Profile-controller
const profile_URL = BASE_URL_OPS_V1 + "profile/";
export const Change_Login_User_Password_Put = profile_URL + "change-password";
export const Get_Restaurant_Profile_Details_Get =
  profile_URL + "get-user-restaurant-profile-detail";

// Admin-Jersey-Controller
const Admin_Jersey_Url = BASE_URL_OPS_V1 + "jersey/";
export const Update_Jersey_PUT = Admin_Jersey_Url + updateURL;
export const Revive_Jersey_PUT = Admin_Jersey_Url + reviveURL;
export const DeActivate_Jersey_PUT = Admin_Jersey_Url + inActivateURL;
export const Activate_Jersey_PUT = Admin_Jersey_Url + activateURL;
export const Add_Jersey_POST = Admin_Jersey_Url + saveURL;
export const All_Jersey_List_POST = Admin_Jersey_Url + listURL;
export const Deleted_Jersey_List_POST = Admin_Jersey_Url + deletedListURL;
export const Get_Jersey_Details_GET = Admin_Jersey_Url + getURL;
export const Delete_Jersey_DELETE = Admin_Jersey_Url + deleteURL;
export const Get_Jersey_key_value_list_POST =
  Admin_Jersey_Url + "key-value-list";

// Admin-Country-Controller
const Admin_Country_Url = BASE_URL_OPS_V1 + "country/";
export const Update_Country_PUT = Admin_Country_Url + updateURL;
export const Revive_Country_PUT = Admin_Country_Url + reviveURL;
export const DeActivate_Country_PUT = Admin_Country_Url + inActivateURL;
export const Activate_Country_PUT = Admin_Country_Url + activateURL;
export const Add_Country_POST = Admin_Country_Url + saveURL;
export const All_Country_List_POST = Admin_Country_Url + listURL;
export const Deleted_Country_List_POST = Admin_Country_Url + deletedListURL;
export const Get_Country_Details_GET = Admin_Country_Url + getURL;
export const Delete_Country_DELETE = Admin_Country_Url + deleteURL;
export const Get_Country_ke_value_list_POST = Admin_Country_Url + "key-value-list";

//Admin-Theme-Controller
const Admin_Theme_Url = BASE_URL_OPS_V1 + "theme/";
export const Revive_Theme_PUT = Admin_Theme_Url + reviveURL;
export const DeActivate_Theme_PUT = Admin_Theme_Url + inActivateURL;
export const Activate_Theme_PUT = Admin_Theme_Url + activateURL;
export const Add_Theme_POST = Admin_Theme_Url + saveURL;
export const All_Theme_List_POST = Admin_Theme_Url + listURL;
export const Deleted_Theme_List_POST = Admin_Theme_Url + deletedListURL;
export const Delete_Theme_DELETE = Admin_Theme_Url + deleteURL;
export const Get_Theme_ke_value_list_GET = Admin_Theme_Url + "key-value-list";

// Export Excel file 
export const PUBLIC_Download_Excel_GET = BASE_URL + "/public/player/downloadPlayerExcel/" + recordID ;
export const Download_Excel_GET = BASE_URL + "/ops/v1/user-client/download-all-players/" + recordID ;

// Admin-Cricket-Player-Controller
const Admin_Cricket_Player_Url = BASE_URL_OPS_V1 + "cricket-player/";
export const Update_Cricket_Player_PUT = Admin_Cricket_Player_Url + updateURL;
export const Revive_Cricket_Player_PUT = Admin_Cricket_Player_Url + reviveURL;
export const DeActivate_Cricket_Player_PUT = Admin_Cricket_Player_Url + inActivateURL;
export const Activate_Cricket_Player_PUT = Admin_Cricket_Player_Url + activateURL;
export const Add_Cricket_Player_POST = Admin_Cricket_Player_Url + saveURL;
export const All_Cricket_Player_List_POST = Admin_Cricket_Player_Url + listURL;
export const Deleted_Cricket_Player_List_POST = Admin_Cricket_Player_Url + deletedListURL;
export const Get_Cricket_Player_Details_GET = Admin_Cricket_Player_Url + getURL;
export const Delete_Cricket_Player_DELETE = Admin_Cricket_Player_Url + deleteURL;
export const Get_Player_key_value_list_POST = Admin_Cricket_Player_Url + "key-value-list";


// Admin Team Controller
const Admin_Team_Url = BASE_URL_OPS_V1 + "team/";
export const Update_Team_PUT = Admin_Team_Url + updateURL;
export const Revive_Team_PUT = Admin_Team_Url + reviveURL;
export const DeActivate_Team_PUT = Admin_Team_Url + inActivateURL;
export const Activate_Team_PUT = Admin_Team_Url + activateURL;
export const Add_Team_POST = Admin_Team_Url + saveURL;
export const All_Team_List_POST = Admin_Team_Url + listURL;
export const Deleted_Team_List_POST = Admin_Team_Url + deletedListURL;
export const Get_Team_Details_GET = Admin_Team_Url + getURL;
export const Delete_Team_DELETE = Admin_Team_Url + deleteURL;
export const Get_Team_key_value_list_POST = Admin_Team_Url + "key-value-list";

// Admin Client Controller
const Admin_Client_Url = BASE_URL_OPS_V1 + "user-client/";
export const Update_Client_PUT = Admin_Client_Url + updateURL;
export const Revive_Client_PUT = Admin_Client_Url + reviveURL;
export const DeActivate_Client_PUT = Admin_Client_Url + inActivateURL;
export const Activate_Client_PUT = Admin_Client_Url + activateURL;
export const Add_Client_POST = Admin_Client_Url + saveURL;
export const All_Client_List_POST = Admin_Client_Url + listURL;
export const Deleted_Client_List_POST = Admin_Client_Url + deletedListURL;
export const Get_Client_Details_GET = Admin_Client_Url + getURL;
export const Delete_Client_DELETE = Admin_Client_Url + deleteURL;
export const Get_Client_key_value_list_POST = Admin_Client_Url + "key-value-list";
export const Regenerate_Public_Private_Key_PUT = Admin_Client_Url + "regenerate-secret-key/" + recordID;
export const Get_Public_Key_GET = Admin_Client_Url + "get-secret-key/" + recordID;
export const Get_Player_List_GET = Admin_Client_Url + "playerList/" + recordID;


// Admin Cricket Match Controller
const Admin_Cricket_Match_Url = BASE_URL_OPS_V1 + "cricket-match/";
export const Update_Cricket_Match_PUT = Admin_Cricket_Match_Url + updateURL;
export const Revive_Cricket_Match_PUT = Admin_Cricket_Match_Url + reviveURL;
export const DeActivate_Cricket_Match_PUT = Admin_Cricket_Match_Url + inActivateURL;
export const Activate_Cricket_Match_PUT = Admin_Cricket_Match_Url + activateURL;
export const Add_Cricket_Match_POST = Admin_Cricket_Match_Url + saveURL;
export const All_Cricket_Match_List_POST = Admin_Cricket_Match_Url + listURL;
export const Deleted_Cricket_Match_List_POST = Admin_Cricket_Match_Url + deletedListURL;
export const Get_Cricket_Match_Details_GET = Admin_Cricket_Match_Url + getURL;
export const Delete_Cricket_Match_DELETE = Admin_Cricket_Match_Url + deleteURL;
export const Get_Cricket_Match_key_value_list_POST = Admin_Cricket_Match_Url + "key-value-list";
export const GET_CRICKET_MATCH_DETAILS = Admin_Cricket_Match_Url + getURL;
export const POST_ADD_RECOMMENDED_TEAM = Admin_Cricket_Match_Url + "add-recommended-team/{cricketMatchId}";
export const DELETE_RECOMMENDED_TEAM = Admin_Cricket_Match_Url + "delete-recommended-team/{cricketMatchId}/{recommendedTeamId}";


// Admin Contest Controller
const Admin_Contest_Url = BASE_URL_OPS_V1 + "contest/";
export const Update_Contest_PUT = Admin_Contest_Url + updateURL;
export const Revive_Contest_PUT = Admin_Contest_Url + reviveURL;
export const DeActivate_Contest_PUT = Admin_Contest_Url + inActivateURL;
export const Activate_Contest_PUT = Admin_Contest_Url + activateURL;
export const Add_Contest_POST = Admin_Contest_Url + saveURL;
// export const All_Live_Contest_List_POST = Admin_Contest_Url + "liveContestList";
export const All_Contest_List_POST = Admin_Contest_Url + listURL;
export const All_History_Contest_List_POST = Admin_Contest_Url + "historyContestList";
export const Deleted_Contest_List_POST = Admin_Contest_Url + deletedListURL;
// export const Get_Live_Contest_Details_GET = Admin_Contest_Url + "liveContestDetails/{contestUuid}";
export const Get_ContestDetails_Details_GET = Admin_Contest_Url + "contestDetails/{contestUuid}";
export const Get_Contest_Details_GET = Admin_Contest_Url + getURL;
export const Delete_Contest_DELETE = Admin_Contest_Url + deleteURL;
export const Cancel_Contest_DELETE = Admin_Contest_Url + cancelURL;

//admin-demo-contest-controller
const Admin_Demo_Contest_Url = BASE_URL_OPS_V1 + "demo-contest/";
export const Add_Demo_Contest_POST = Admin_Demo_Contest_Url + saveURL;
export const Get_Demo_Contest_Details_GET = Admin_Demo_Contest_Url + getURL;

//
const Admin_Logs_Contest_Url = BASE_URL_OPS_V1 + "logs/";
export const Add_log_transactionStatusList_Post = Admin_Logs_Contest_Url + "transactionStatusList/{contestId}";
export const Add_log_apiCallerList_Post = Admin_Logs_Contest_Url + "apiCallerList/{contestId}";