const dashboard = {
    title: "Dashboard",
    link: "/",
    icon: "bx bx-home-circle",
    multi: false,
    role: "",
};

export const admin = [
    { ...dashboard },
    {
        title: "Jersey",
        link: "admin-jersey",
        icon: "bx bxs-coupon",
        multi: false,
    },
    {
        title: "Country",
        link: "admin-country",
        icon: "mdi mdi-flag-variant",
        multi: false,
    },
    {
        title: "Theme",
        link: "admin-theme",
        icon: "fa fa-palette",
        multi: false,
    },
    {
        title: "Team",
        link: "admin-team",
        icon: "bx bxs-group",
        multi: false,
    },
    {
        title: "Cricket Player",
        link: "admin-cricket-player",
        icon: "mdi mdi-human-handsup",
        multi: false,
    },
    {
        title: "Cricket Match",
        link: "admin-match",
        icon: "mdi mdi-codepen",
        multi: false,
    },
    {
        title: "Client",
        link: "admin-client",
        icon: "bx bx-user",
        multi: false,
    },
    {
        title: "Contest",
        link: "admin-contest",
        icon: "mdi mdi-gamepad",
        multi: false,
    },
    // {
    //     title: "Live Contest",
    //     link: "admin-live-contest",
    //     icon: "mdi mdi-checkbox-blank-circle",
    //     multi: false,
    // },
    {
        title: "Contest History",
        link: "admin-contest-history",
        icon: "mdi mdi-checkbox-blank-circle",
        multi: false,
    },
    {
        title: "Demo Contest ",
        link: "admin-demo-contest",
        icon: "mdi mdi-checkbox-blank-circle",
        multi: false,
    },
];

// restaurantAdmin
export const restaurantAdmin = [
    { ...dashboard },
    {
        title: "Voucher",
        link: "restaurant-voucher",
        icon: "bx bxs-coupon",
        multi: false,
    },
    {
        title: "Table",
        link: "restaurant-table",
        icon: "bx bx-shape-circle",
        multi: false,
    },
    {
        title: "Printer",
        link: "restaurant-printer",
        icon: "bx bxs-printer",
        multi: false,
    },
    {
        title: "Restaurant Setup",
        multi: true,
        icon: "bx bx-restaurant",
        keyword: "restaurant-setup",
        linkList: [
            {
                title: "Tax",
                link: "restaurant-setup-tax",
                icon: "bx bx-file",
                multi: false,
            },
            // {
            //     title: "Cuisine",
            //     link: "restaurant-setup-cuisine-list",
            //     icon: "bx bx-food-tag",
            //     multi: false,
            // },
            // {
            //     title: "Sticker",
            //     link: "restaurant-setup-sticker",
            //     icon: "bx bx-sticker",
            //     multi: false,
            // },
            // {
            //     title: "Timing Group",
            //     link: "restaurant-setup-timing-group",
            //     icon: "bx bx-time",
            //     multi: false,
            // },
            // {
            //     title: "Restaurant ",
            //     link: "restaurant-setup-list",
            //     icon: "bx bx-restaurant",
            //     multi: false,
            // },
            // {
            //     title: "Event",
            //     link: "restaurant-setup-event",
            //     icon: "bx bx-calendar-event",
            //     multi: false,
            // },
        ],
    },
    {
        title: "Menu",
        icon: "bx bx-menu",
        keyword: "restaurant-menu",
        multi: true,
        linkList: [
            {
                title: "Category",
                link: "restaurant-menu-category",
                icon: "bx bx-code-block",
                keyword: "restaurant-menu-category",
                multi: true,
                linkList: [
                    {
                        title: "Steal Deal",
                        link: "restaurant-menu-steal-deal-category",
                        icon: "bx bxs-drink",
                        multi: false,
                    },
                    {
                        title: "Food",
                        link: "restaurant-menu-non-steal-deal-category",
                        icon: "bx bxs-dish",
                        multi: false,
                    },
                ],
            },
            {
                title: "Item Customization",
                link: "restaurant-menu-customization",
                icon: "bx bx-customize",
                multi: false,
            },
            {
                title: "Condiments",
                link: "restaurant-menu-condiments",
                icon: "bx bx-customize",
                multi: false,
            },
            {
                title: "Item",
                link: "restaurant-menu-item",
                icon: "bx bx-food-tag",
                multi: true,
                keyword: "restaurant-menu-item",
                linkList: [
                    {
                        title: "Steal Deal",
                        link: "restaurant-menu-steal-deal-item",
                        icon: "bx bxs-wine",
                        multi: false,
                    },
                    {
                        title: "Food",
                        link: "restaurant-menu-non-steal-deal-item",
                        icon: "bx bxs-dish",
                        multi: false,
                    },
                ],
            },
        ],
    },
    {
        title: "Complementaaaaaaary Head",
        link: "restaurant-complementary-head",
        icon: "bx bxs-gift",
        multi: false,
    },
    {
        title: "Staff",
        multi: true,
        icon: "bx bxs-group",
        keyword: "restaurant-staff",
        linkList: [
            {
                title: "Employee",
                link: "restaurant-employee",
                icon: "bx bx-user",
                multi: false,
            },
            {
                title: "Delivery Boy",
                link: "restaurant-delivery-boy",
                icon: "bx bx-user",
                multi: false,
            },
            {
                title: "Track Delivery Boy",
                link: "restaurant-track-delivery-boy",
                icon: "bx bx-user",
                multi: false,
            },
        ],
    },
    {
        title: "Offers",
        multi: true,
        icon: "bx bxs-offer",
        keyword: "restaurant-offers",
        linkList: [
            {
                title: "Happy Hours",
                link: "restaurant-happy-hours-offer",
                icon: "bx bxs-watch",
                multi: false,
            },
            {
                title: "Delivery Offer",
                link: "restaurant-delivery-offer",
                icon: "bx bxs-package",
                multi: false,
            },
        ],
    },
    {
        title: "Orders",
        multi: true,
        icon: "bx bxs-cart-alt",
        keyword: "restaurant-orders",
        linkList: [
            {
                title: "Dine In",
                link: "restaurant-dineIn-orders",
                icon: "mdi mdi-silverware",
                multi: false,
            },
            {
                title: "Delivery",
                link: "restaurant-delivery-orders",
                icon: "mdi mdi-moped",
                multi: false,
            },
            {
                title: "Take away",
                link: "restaurant-takeaway-orders",
                icon: "bx bxs-shopping-bag-alt",
                multi: false,
            },
        ],
    },
];
