import React, { useEffect } from "react";

// libraries
import {
    Navigate,
    Route,
    Routes,
    useLocation,
    useNavigate,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// utilities
import { LOGOUT, UPDATE_BRAND_KEY_VALUE_PAIRS } from "reducer/actions";

// components
import Dashboard from "components/pages/Dashboard";
import Login from "components/pages/Login";
import Home from "components/pages/Home";

import WorkInProgress from "components/common/WorkInProgress";
import { isEmpty } from "utils/TextUtils";
import AdminJerseyMaster from "components/master/admin/jersey/AdminJerseyMaster";
import AdminCricketPlayerMaster from "components/master/admin/cricketPlayer/AdminCricketPlayerMaster";
import AdminCountryMaster from "components/master/admin/country/AdminCountryMaster";
import AdminTeamMaster from "components/master/admin/team/AdminTeamMaster";
import AdminClientMaster from "components/master/admin/client/AdminClientMaster";
import MatchMaster from "components/master/admin/match/MatchMaster";
import ContestMaster from "components/master/admin/contest/ContestMaster";
import AdminContestHistoryMaster from "components/master/admin/contestHistory/AdminContestHistoryMaster";
import AdminContestDetails from "components/master/admin/common/AdminContestDetails";
import DemoContestMaster from "components/master/admin/demoContest/DemoContestMaster";
import AdminThemeMaster from "components/master/admin/theme/AdminThemeMaster";

const AppRoutes = () => {
    const { isAuthenticated, user } = useSelector((state) => state.user);
    let location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect(() => {
        if (!isAuthenticated) {
            dispatch({ type: LOGOUT });
            if (
                location.pathname !== "/al" &&
                location.pathname !== "/clientLogin" &&
                location.pathname !== "/restLogin"
            ) {
                if (localStorage.getItem("location")) {
                    navigate(localStorage.getItem("location"));
                } else {
                    navigate("/al");
                }
            }
            if (
                location.pathname === "/al" ||
                location.pathname === "/clientLogin" ||
                location.pathname === "/restLogin"
            ) {
                localStorage.setItem("location", location.pathname);
            }
            document.title = "Fantasy Replay";
        } else {
            if (
                location.pathname === "/al" ||
                location.pathname === "/clientLogin" ||
                location.pathname === "/restLogin"
            ) {
                navigate("/");
            }
            if (!isEmpty(user?.productTitle)) {
                if (location.pathname !== "/al") {
                    document.title = `${user?.productTitle}`;
                }
            }
        }
    }, [isAuthenticated]);

    return (
        <Routes>
            {!isAuthenticated ? (
                <>
                    <Route
                        path="/al"
                        element={isAuthenticated ? <Navigate to={-1} /> : <Login />}
                    />
                    <Route
                        path="/clientLogin"
                        element={isAuthenticated ? <Navigate to={-1} /> : <Login />}
                    />
                    <Route
                        path="/restLogin"
                        element={isAuthenticated ? <Navigate to={-1} /> : <Login />}
                    />
                </>
            ) : (
                (user?.userType === "ROLE_ADMIN" || user?.userType === 'ROLE_SUPER_ADMIN') && (
                    <Route path="/" element={<Dashboard />}>
                        <Route index element={<Home />} />
                        <Route path="admin-jersey" element={<AdminJerseyMaster />} />
                        <Route path="admin-country" element={<AdminCountryMaster />} />
                        <Route path="admin-theme" element={<AdminThemeMaster />} />
                        <Route
                            path="admin-cricket-player"
                            element={<AdminCricketPlayerMaster />}
                        />
                        <Route path="admin-team" element={<AdminTeamMaster />} />
                        <Route path="admin-client" element={<AdminClientMaster />} />
                        <Route path="admin-match" element={<MatchMaster />} />
                        <Route path="admin-contest" element={<ContestMaster />} />
                        <Route path="admin-contest-history" element={<AdminContestHistoryMaster />} />
                        <Route path="admin-contest-details/:contestId?/:flag?" element={<AdminContestDetails />} />
                        <Route path="admin-demo-contest" element={<DemoContestMaster />} />
                    </Route>
                )
            )}
        </Routes>
    );
};

export default AppRoutes;
