import React, { useState } from "react";
import toast from "react-hot-toast";

import { callPostApi } from "api/ApiCaller";
import {  Add_Theme_POST } from "api/ApiConst";

import { isEmpty } from "utils/TextUtils";
import { MODAL_LOADER } from "utils/constants";
import ModalWrapper from "components/common/ModalWrapper";

const AdminThemeAddEdit = ({ show, hideModal, handleRefresh}) => {

    const [record, setRecord] = useState({
        title : ""
    });

    //Text Change Event
    const handleTextChange = (e) => {
        setRecord((prev) => ({ ...prev, [e.target.id]: e.target.value }));
    };

    const checkInputValidation = () => {
        if (isEmpty(record.title)) {
            toast.error(`title must not be empty.`, {id: "toast",});
            return false;
        }
        return true;
    };

    //Submit Theme Form
    const handleSubmit = async () => {
        try {
            if (!checkInputValidation()) {
                return;
            }
            let recordObj = record;
                callPostApi(MODAL_LOADER,Add_Theme_POST,recordObj,{},(response) => {
                        toast.success(response.message);
                        hideModal();
                        handleRefresh();
                    }
                );
        } catch (error) {
            return;
        }
    };

    return (
        <ModalWrapper
            show={show}
            hide={hideModal}
            backdrop="static"
            header="Add Theme"
            size="sm"
            handleSubmit={handleSubmit}
        >
            <div className="form-group row mb-3">
                <div className="col-lg-12">
                    <label>Title *</label>
                    <input
                        type="text"
                        className="form-control input-default"
                        id="title"
                        onChange={(e) => handleTextChange(e)}
                        value={record?.title}
                    />
                </div>
            </div>
        </ModalWrapper>
    );
};
export default AdminThemeAddEdit;
