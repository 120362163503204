import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import * as navigationList from "data/leftMenuNavigation";
import { useSelector } from "react-redux";
import useWidowResize from "customHooks/useWidowResize";

const LeftMenu = () => {
    const location = useLocation();
    const [navList, setNavList] = useState([]);
    const { user } = useSelector((state) => state.user);
    const [windowWidth] = useWidowResize()

    useEffect(() => {
        if (user?.userType === "ROLE_ADMIN" || user.userType === "ROLE_SUPER_ADMIN") {
            setNavList(navigationList.admin);
        } else if (user?.userType === "ROLE_RESTAURANT_ADMIN") {
            setNavList(navigationList.restaurantAdmin);
        }
    }, []);

    const handleSideMenuLink = () => {
        if (windowWidth <= 992) {
            document.body.classList.toggle("sidebar-enable");
            document.body.classList.toggle("vertical-collpsed");
        }
    }

    return (
        <div className="vertical-menu">
            {/* <div className="h-100"> */}
            <div id="sidebar-menu">
                <ul className="list-unstyled" id="side-menu">
                    <li className="menu-title" key="t-menu">
                        Menu
                    </li>
                    {navList.map((navItem, i) =>
                        navItem.multi ? (
                            <li className="nav-item" key={i}>
                                <a
                                    className={`nav-link has-arrow waves-effect ${location.pathname === "/" + navItem.keyword ? "mm-active" : "collapsed"}`}
                                    data-bs-target={`#components-nav-${navItem.keyword}`}
                                    href="#"
                                    data-bs-toggle="collapse"
                                >
                                    <i className={navItem.icon} />
                                    <span>{navItem.title}</span>
                                </a>
                                <ul id={`components-nav-${navItem.keyword}`} className="sub-menu nav-content collapse" data-bs-parent="#side-menu">
                                    {navItem.linkList.map((subItem, j) =>
                                        subItem.multi ? (
                                            <li className="nav-item" key={j} onClick={handleSideMenuLink}>
                                                <a className={`nav-link has-arrow waves-effect collapsed`} href="#" data-bs-target={`#components-nav-${subItem.keyword}`} data-bs-toggle="collapse">
                                                    <i className={subItem.icon} />
                                                    <span>{subItem.title}</span>
                                                    <i className="bi bi-chevron-down ms-auto" />
                                                </a>
                                                <ul id={`components-nav-${subItem.keyword}`} className={`sub-menu nav-content collapse`} data-bs-parent={`components-nav-${navItem.keyword}`}>
                                                    {subItem.linkList.map((subSubItem, k) => (
                                                        <li key={k} className={`${location.pathname === "/" + subSubItem.link ? "mm-active active" : ""}`}>
                                                            <Link to={subSubItem.link} className={`waves-effect ${location.pathname === "/" + subSubItem.link ? "mm-active active" : ""}`}>
                                                                <i className={subSubItem.icon} />
                                                                <span>{subSubItem.title}</span>
                                                            </Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </li>
                                        ) : (
                                            <li key={j} className={`nav-item  ${location.pathname === "/" + subItem.link ? "mm-active" : ""}`} onClick={handleSideMenuLink}>
                                                <Link to={subItem.link} className={`waves-effect ${location.pathname === "/" + subItem.link ? "mm-active active" : ""}`}>
                                                    <i className={subItem.icon} />
                                                    <span>{subItem.title}</span>
                                                </Link>
                                            </li>
                                        )
                                    )}
                                </ul>
                            </li>
                        ) : (
                            <li key={i} className={` ${location.pathname === "/" + navItem.link ? "mm-active active" : ""}`} onClick={handleSideMenuLink}>
                                <Link to={navItem.link} className={`waves-effect d-flex align-items-center ${location.pathname === "/" + navItem.link ? "mm-active active" : ""}`}>
                                    <i className={navItem.icon} />
                                    <span>{navItem.title}</span>
                                </Link>
                            </li>
                        )
                    )}
                </ul>
            </div>
            {/* </div> */}
        </div>
    );
};

export default LeftMenu;
