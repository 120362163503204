import React from "react";

const CheckboxButton = ({ handleClick, title, additionalClass = null }) => {
    return (
        <div className={additionalClass}>
            <label className="btn btn-primary mb-0  d-flex  align-items-center h-100">
                <input type="checkbox" className="form-check-input" onChange={handleClick} />
                {title}
            </label>
        </div>
    );
};

export default CheckboxButton;
