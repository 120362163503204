import { callGetApi, callPostApi, callPutApi } from "api/ApiCaller";
import {
    Add_Client_POST,
    Get_Client_Details_GET,
    Get_Country_ke_value_list_POST,
    Get_Theme_ke_value_list_GET,
    Update_Client_PUT,
} from "api/ApiConst";
import ModalWrapper from "components/common/ModalWrapper";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { isEmail, isEmpty } from "utils/TextUtils";
import { MODAL_LOADER } from "utils/constants";
import ImageSelector from "components/common/ImageSelector";
import { API_SUCCESS } from "api/ResponseCodes";
import Select from "react-select";

const AdminClientAddEdit = ({ show, hideModal, handleRefresh, recordID }) => {
    const defaultRecordObj = {
        firstName: "",
        lastName: "",
        gender: "",
        dateOfBirth: "",
        isdCode: "+91",
        mobile: "",
        username: "",
        email: "",
        pwdText: "",
        photoImageRefId: "",
        productTitle: "",
        productDescription: "",
        productIconUrl: "",
        contactName: "",
        contactNumber: "",
        contactEmail: "",
        productBaseUrl: "",
        themeTitle: "",
    };

    const [record, setRecord] = useState(defaultRecordObj);
    const [themeTypeList, setThemeTypeList] = useState([]);


    useEffect(() => {
        getThemeTypeKeyList()
    }, [])

    const getThemeTypeKeyList = () => {
        callGetApi({}, Get_Theme_ke_value_list_GET, {}, (response) => {
            const res = response?.responsePacket;
            let typeList = res.map((type) => {
                return { label: type.label, value: type.value }
            });
            setThemeTypeList(typeList);
        }, (error) => {
            console.error("Error fetching theme type key list:", error);
        });
    };

    useEffect(() => {
        if (recordID) {
            callGetApi(
                MODAL_LOADER,
                Get_Client_Details_GET.replaceAll("{recordID}", recordID),
                {},
                (response) => {
                    const res = response?.responsePacket;
                    const _themeTitle = themeTypeList?.find((obj) => obj.value === res.themeTitle);

                    setRecord({
                        ...res,
                        themeTitle: _themeTitle
                    });
                }
            );
        }
    }, [recordID, themeTypeList]);

    //Text Change Event
    const handleTextChange = (e) => {
        setRecord((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    const checkInputValidation = () => {
        if (isEmpty(record.firstName)) {
            toast.error(`First name must not be empty`, { id: "toast" });
            return false;
        } else if (isEmpty(record.lastName)) {
            toast.error(`Last name must not be empty`, {
                id: "toast",
            });
            return false;
        } else if (!handleDateChange(record.dateOfBirth)) {
            toast("You are not eligible for client because age is below 18");
            return false;
        } else if (isEmpty(record.isdCode)) {
            toast.error(`ISD Code must not be empty`, {
                id: "toast",
            });
            return false;
        } else if (isEmpty(record.mobile)) {
            toast.error(`Mobile Number must not be empty`, {
                id: "toast",
            });
            return false;
        } else if (isEmpty(record.email)) {
            toast.error(`Email must not be empty`, {
                id: "toast",
            });
            return false;
        }
        if (record.email) {
            if (!isEmail(record.email)) {
                toast.error(`Please Provide Valid Email`, { id: "toast" });
                return false;
            }
        }

        if (isEmpty(record.productIconUrl)) {
            toast.error(`Product Image must not be empty`, {
                id: "toast",
            });
            return false;
        } else if (isEmpty(record.productTitle)) {
            toast.error(`Product title must not be empty`, {
                id: "toast",
            });
            return false;
        }
        return true;
    };

    //Submit Avatar Form
    const handleSubmit = async () => {
        try {
            if (!checkInputValidation()) {
                return;
            }

            let recordObj = {
                ...record,
                themeTitle: record?.themeTitle?.value,
            };

            if (!recordID || recordID === "") {
                callPostApi(
                    MODAL_LOADER,
                    Add_Client_POST,
                    recordObj,
                    {},
                    (response) => {
                        if (response.errorCode === API_SUCCESS) {
                            toast.success(response.message);
                            hideModal();
                            handleRefresh();
                        } else {
                            toast.error(response.message);
                        }
                    }
                );
            } else {
                callPutApi(
                    MODAL_LOADER,
                    Update_Client_PUT.replaceAll("{recordID}", recordID),
                    recordObj,
                    {},
                    (response) => {
                        if (response.errorCode === API_SUCCESS) {
                            toast.success(response.message);
                            hideModal();
                            handleRefresh();
                        } else {
                            toast.error(response.message);
                        }
                    }
                );
            }
        } catch (error) {
            return;
        }
    };

    const handleImageChange = (imageRefId) => {
        setRecord({ ...record, photoImageRefId: imageRefId });
    };
    const handleProductImageChange = (imageRefId) => {
        setRecord({ ...record, productIconUrl: imageRefId });
    };

    const handleSelectChange = (fieldName, selectedValue) => {
        setRecord({ ...record, [fieldName]: selectedValue });
    }

    const handleDateChange = (date) => {
        const currentDate = new Date();
        const selectedDate = new Date(date);
        const selectedYear = selectedDate.getFullYear();
        const currentYear = currentDate.getFullYear();
        const year = currentYear - selectedYear;
        if (year < 18) {
            return false;
        }
        return true;
    };

    return (
        <ModalWrapper
            show={show}
            hide={hideModal}
            backdrop="static"
            header={`${recordID ? "Update" : "Add"} Client`}
            size="md"
            handleSubmit={handleSubmit}
        >
            <fieldset className="mb-3">
                <legend>Personal Details</legend>

                <div className="form-group row mb-3">
                    <div className="col-lg-6">
                        <label>First Name*</label>
                        <input
                            type="text"
                            className="form-control input-default"
                            id="firstName"
                            name="firstName"
                            onChange={handleTextChange}
                            value={record.firstName}
                        />
                    </div>
                    <div className="col-lg-6 mb-3">
                        <label>Last Name*</label>
                        <input
                            type="text"
                            className="form-control input-default"
                            id="lastName"
                            name="lastName"
                            onChange={handleTextChange}
                            value={record.lastName}
                        />
                    </div>

                    <div className="col-lg-6 mb-3">
                        <label>Mobile Number*</label>
                        <div className="row gx-1">
                            <div className="col-3">
                                <input
                                    type="text"
                                    className="form-control input-default"
                                    id="isdCode"
                                    name="isdCode"
                                    maxLength={3}
                                    onChange={handleTextChange}
                                    value={record.isdCode}
                                    placeholder="+91"
                                />
                            </div>
                            <div className="col-9">
                                <input
                                    type="text"
                                    className="form-control input-default"
                                    id="mobile"
                                    name="mobile"
                                    maxLength={10}
                                    onChange={handleTextChange}
                                    value={record.mobile}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 mb-3">
                        <label>Email*</label>
                        <input
                            type="email"
                            className="form-control input-default"
                            id="email"
                            name="email"
                            onChange={handleTextChange}
                            value={record.email}
                        />
                    </div>
                </div>
            </fieldset>
            <fieldset className="mb-3">
                <legend>Product Details</legend>
                <div className="form-group row mb-3">
                    <div className="col-lg-4 mb-3">
                        <label>Image*</label>
                        <ImageSelector
                            style={{
                                height: "177px",
                                width: "120px",
                                overflow: "hidden",
                            }}
                            user={true}
                            handleImageChange={handleProductImageChange}
                            selectedImage={record.productIconUrl}
                        />
                    </div>
                    <div className="col-lg-8 mb-2">
                        <div className="col-lg-12 mb-3">
                            <label>Title*</label>
                            <input
                                type="text"
                                className="form-control input-default"
                                id="productTitle"
                                name="productTitle"
                                onChange={handleTextChange}
                                value={record.productTitle}
                            />
                        </div>
                        <div className="col-lg-12 mb-3">
                            <label>Description</label>
                            <textarea
                                className="form-control"
                                id="productDescription"
                                name="productDescription"
                                style={{ height: "100px" }}
                                rows={4}
                                onChange={handleTextChange}
                                value={record.productDescription}
                            ></textarea>
                        </div>

                        <div className="col-lg-12 mb-3">
                            <label>Select Theme</label>
                            <Select
                                isSearchable={false}
                                options={themeTypeList}
                                name="themeTitle"
                                value={record.themeTitle}
                                onChange={(selectedValue) => handleSelectChange("themeTitle", selectedValue)} />
                        </div>
                    </div>
                    <div className="col-lg-12 mb-3">
                        <label>Product Base URL</label>
                        <textarea
                            className="form-control"
                            id="productBaseUrl"
                            name="productBaseUrl"
                            rows={2}
                            onChange={handleTextChange}
                            value={record.productBaseUrl}
                        ></textarea>
                    </div>
                </div>
            </fieldset>
        </ModalWrapper>
    );
};

export default AdminClientAddEdit;
