import { useEffect, useState } from "react";
import Select from "react-select";
import toast from "react-hot-toast";

import { callGetApi, callPostApi, callPutApi } from "api/ApiCaller";
import { Add_Contest_POST, Get_Contest_Details_GET, Update_Contest_PUT, } from "api/ApiConst";

import ModalWrapper from "components/common/ModalWrapper";
import { isEmpty } from "utils/TextUtils";
import { MODAL_LOADER } from "utils/constants";

const ContestAddEdit = ({ show, hideModal, handleRefresh, recordID, matchList, clientList, }) => {
    const rankDistribution = {
        rankFrom: 0,
        rankTo: 0,
        rewardPercentage: 0,
    };

    const waitingTimeInSec = [
        {
            label: "5 sec",
            value: 5,
        }, {
            label: "10 sec",
            value: 10,
        }, {
            label: "15 sec",
            value: 15,
        }, {
            label: "20 sec",
            value: 20,
        }, {
            label: "30 sec",
            value: 30,
        }, {
            label: "1 Min",
            value: 60,
        }, {
            label: "5 Min",
            value: 300,
        }, {
            label: "10 Min",
            value: 600,
        }
    ];
    const applyBoosterTimeInSec = [
        {
            label: "20 sec",
            value: 20,
        }, {
            label: "30 sec",
            value: 30,
        }, {
            label: "1 Min",
            value: 60,
        }, {
            label: "5 Min",
            value: 300,
        }, {
            label: "10 Min",
            value: 600,
        }
    ];

    const defaultRecordObj = {
        clientId: 0,
        clientName: "",
        cricketMatchId: 0,
        cricketMatchTitle: "",
        publishDateTimeStamp: 0,
        startDateTimeStamp: 0,
        rewardDistributionList: [],
        waitingTimeForDrawNextPart: 0,
        waitingTimeForApplyBooster: 0,
        minimumNumberOfParticipantsRequired: 0,
        maximumNumberOfParticipants: 0,
        prizePoolTypeEnum: { label: "GuaranteedPool", value: "GuaranteedPool" },
        entryFee: 0,
        prizePool: 0,
        replicaCount : 0,
    };

    const [record, setRecord] = useState(defaultRecordObj);
    const [selectedClientList, setSelectedClientList] = useState({});
    const [selectedMatchList, setSelectedMatchList] = useState({});
    const [selectedPrizePoolTypeEnum, setSelectedPrizePoolTypeEnum] = useState({
        label: "GuaranteedPool",
        value: "GuaranteedPool",
    });

    const [selectedWaitingTimeForDrawNextPart, setSelectedWaitingTimeForDrawNextPart] =
        useState({});

    const [
        selectedWaitingTimeForApplyBooster,
        setSelectedWaitingTimeForApplyBooster,
    ] = useState({});

    const [prizePoolTypeEnum, setPrizePoolTypeEnum] = useState([
        { label: "GuaranteedPool", value: "GuaranteedPool" },
        { label: "PlayerPool", value: "PlayerPool" },
    ]);

    const { rewardDistributionList } = record;

    useEffect(() => {
        if (recordID) {
            callGetApi(
                MODAL_LOADER,
                Get_Contest_Details_GET.replaceAll("{recordID}", recordID),
                {},
                (response) => {
                    const res = response.responsePacket;
                    res.startDateTimeStamp = getDateTimeString(res.startDateTimeStamp);
                    res.publishDateTimeStamp = getDateTimeString(res.publishDateTimeStamp);
                    setRecord(res);
                    setSelectedClientList(
                        clientList.find((data) => data.key == res.clientId)
                    );
                    setSelectedMatchList(
                        matchList.find((data) => data.key == res.cricketMatchId)
                    );
                    setSelectedPrizePoolTypeEnum(
                        prizePoolTypeEnum.find(
                            (data) => data.value === res.prizePoolTypeEnum
                        )
                    );
                    setSelectedWaitingTimeForDrawNextPart(
                        waitingTimeInSec.find(
                            (data) => data.value === res.waitingTimeForDrawNextPart
                        )
                    );
                    setSelectedWaitingTimeForApplyBooster(
                        applyBoosterTimeInSec.find(
                            (data) => data.value === res.waitingTimeForApplyBooster
                        )
                    );
                }
            );
        }
    }, [recordID]);

    const getDateTimeString = (timeStamp) => {
        // 2023-12-03T23:43
        var dateAndTime = new Date(timeStamp);
        let Number = Intl.NumberFormat('en-US', {
            minimumIntegerDigits: 2,
            minimumFractionDigits: 0
        });
        let dateTimeString =
            dateAndTime.getFullYear() + "-" +
            Number.format(dateAndTime.getMonth() + 1) + "-" +
            Number.format(dateAndTime.getDate()) + "T" +
            Number.format(dateAndTime.getHours()) + ":" +
            Number.format(dateAndTime.getMinutes());
        return dateTimeString;
    }

    //Text Change Event
    const handleTextChange = (e) => {
        const { name, id, value } = e.target;

        setRecord((prev) => ({ ...prev, [name]: value }));
    };

    const checkInputValidation = () => {
        if (isEmpty(record.clientId)) {
            toast.error(`Client must not be empty`, { id: "toast" });
            return false;
        } else if (isEmpty(record.cricketMatchId)) {
            toast.error(`Cricket Match must not be empty`, { id: "toast" });
            return false;
        } else if (isEmpty(record.publishDateTimeStamp)) {
            toast.error(`Publish Date must not be empty`, { id: "toast" });
            return false;
        } else if (isEmpty(record.startDateTimeStamp)) {
            toast.error(`Start Date must not be empty`, { id: "toast" });
            return false;
        } else if (isEmpty(record.waitingTimeForDrawNextPart)) {
            toast.error(`Waiting Time For Draw Next Part must not be empty`, {
                id: "toast"
            });
            return false;
        } else if (isEmpty(record.waitingTimeForApplyBooster)) {
            toast.error(`Waiting Time For Apply Booster must not be empty`, {
                id: "toast"
            });
            return false;
        } else if (isEmpty(record.minimumNumberOfParticipantsRequired)) {
            toast.error(`Minimum Number Of Participants Required must not be empty`, {
                id: "toast"
            });
            return false;
        } else if (isEmpty(record.maximumNumberOfParticipants)) {
            toast.error(`Maximum Number Of Participants must not be empty`, {
                id: "toast"
            });
            return false;
        } else if (
            parseInt(record.minimumNumberOfParticipantsRequired) >
            parseInt(record.maximumNumberOfParticipants)
        ) {
            toast.error(`Maximum number of participants should be grater to minimum number of participants`, {
                id: "toast"
            });
            return false;
        } else if (isEmpty(record.prizePoolTypeEnum)) {
            toast.error(`Prize Pool must not be empty`, {
                id: "toast"
            });
            return false;
        } else if (isEmpty(record.entryFee)) {
            toast.error(`Entry Fee must not be empty`, {
                id: "toast"
            });
            return false;
        } else if (isEmpty(record.prizePool)) {
            toast.error(`Prize Pool must not be empty`, {
                id: "toast"
            });
            return false;
        } else if (isEmpty(record.prizePool)) {
            toast.error(`Prize Pool must not be empty`, {
                id: "toast"
            });
            return false;
        } else if (isEmpty(record.rewardDistributionList[0])) {
            toast.error(`Add Reward Distribution must not be empty`, {
                id: "toast"
            });
            return false;
        }

        let arr = record.rewardDistributionList;
        // let totalPercentage = 0;
        for (let i = 0; i < arr.length; i++) {
            if (isEmpty(parseInt(arr[i].rankFrom))) {
                toast.error(`Rank From must not be empty in row ${i + 1}`, {
                    id: "toast"
                });
                return false;
            }
            if (isEmpty(parseInt(arr[i].rankTo))) {
                toast.error(`Rank To must not be empty in row ${i + 1}`, {
                    id: "toast"
                });
                return false;
            }
            if (isEmpty(parseInt(arr[i].rewardPercentage))) {
                toast.error(`Reward Percentage must not be empty in row ${i + 1}`, {
                    id: "toast"
                });
                return false;
            }

            if (parseInt(arr[i].rankFrom) > parseInt(arr[i].rankTo)) {
                toast.error(`RankFrom in row ${i + 1}  greater then  or equal to RankTo row ${i}`, {
                    id: "toast"
                });
                return false;
            }

            for (let j = 0; j < i; j++) {
                if (parseInt(arr[j].rankTo) >= parseInt(arr[i].rankFrom)) {
                    toast.error(`RankTo row ${j + 1} is not greater than  or equal to RankFrom row ${i + 1}`, {
                        id: "toast"
                    });
                    return false;
                }
            }
            //   totalPercentage += arr[i].rewardPercentage;
        }
        // if (totalPercentage > 100) {
        //   toast.error(
        //     `Total of Reward Percentage is more than 100 in Reward Distribution`,
        //     {
        //       id: "toast",
        //     }
        //   );
        //   return false;
        // }
        return true;
    };

    //Submit Avatar Form
    const handleSubmit = async () => {
        try {
            let recordObj = record;

            let publishDate = new Date(record.publishDateTimeStamp);
            let startDate = new Date(record.startDateTimeStamp);
            if (publishDate > startDate) {
                toast.error("Start Date should be grater than Publish Date");
                return false;
            }
            recordObj.publishDateTimeStamp = publishDate.getTime();
            recordObj.startDateTimeStamp = startDate.getTime();

            recordObj.clientId = selectedClientList.key;
            recordObj.cricketMatchId = selectedMatchList.key;
            recordObj.prizePoolTypeEnum = selectedPrizePoolTypeEnum.value;
            recordObj.waitingTimeForDrawNextPart = selectedWaitingTimeForDrawNextPart.value;
            recordObj.waitingTimeForApplyBooster =
                selectedWaitingTimeForApplyBooster.value;
            if (!checkInputValidation()) {
                return;
            }
            if (!recordID || recordID === "") {
                callPostApi(
                    MODAL_LOADER,
                    Add_Contest_POST,
                    recordObj,
                    {},
                    (response) => {
                        toast.success(response.message);
                        hideModal();
                        handleRefresh();
                    }
                );
            } else {
                callPutApi(
                    MODAL_LOADER,
                    Update_Contest_PUT.replaceAll("{recordID}", recordID),
                    recordObj,
                    {},
                    (response) => {
                        toast.success(response.message);
                        hideModal();
                        handleRefresh();
                    }
                );
            }
        } catch (error) {
            toast.error(error);
            return;
        }
    };

    const handleClickAddRewardDistribution = () => {
        setRecord((prev) => ({
            ...prev,
            rewardDistributionList: [
                ...prev.rewardDistributionList,
                rankDistribution,
            ],
        }));
    };

    const handleClickRemoveRewardDistribution = (index) => {
        const _rowRewardDistribution = [...rewardDistributionList];
        _rowRewardDistribution.splice(index, 1);
        setRecord((prev) => ({
            ...prev,
            rewardDistributionList: _rowRewardDistribution,
        }));
    };

    const handleDynamicTextChange = (e, index) => {
        const { value, id } = e.target;
        const _rowRewardDistribution = [...rewardDistributionList];
        _rowRewardDistribution[index][id] = value;
        setRecord((prev) => ({
            ...prev,
            rewardDistributionList: _rowRewardDistribution,
        }));
    };

    const addRewardDistribution = (index) => {
        return (
            <div className=" rounded border d-flex justify-content-around align-items-end border-dark mb-3 p-3 ">
                <div className="">
                    <label>Rank From*</label>
                    <input
                        type="number"
                        className="form-control input-default"
                        id="rankFrom"
                        name="rankFrom"
                        onChange={(e) => handleDynamicTextChange(e, index)}
                        value={rewardDistributionList[index].rankFrom}
                    />
                </div>
                <div className="">
                    <label>Rank To*</label>
                    <input
                        type="number"
                        className="form-control input-default"
                        id="rankTo"
                        name="rankTo"
                        onChange={(e) => handleDynamicTextChange(e, index)}
                        value={rewardDistributionList[index].rankTo}
                    />
                </div>
                <div className="">
                    <label>Reward Percentage*</label>
                    <input
                        type="number"
                        className="form-control input-default"
                        id="rewardPercentage"
                        name="rewardPercentage"
                        onChange={(e) => handleDynamicTextChange(e, index)}
                        value={rewardDistributionList[index].rewardPercentage}
                    />
                </div>
                <button
                    className="btn btn-danger"
                    onClick={() => handleClickRemoveRewardDistribution(index)}
                >
                    Remove
                </button>
            </div>
        );
    };

    const handleSelectMatchList = (e) => {
        setRecord({ ...record, cricketMatchId: e.value });
        setSelectedMatchList({
            label: e.label,
            value: e.value,
            ...e,
        });
    };
    const handleSelectClientList = (e) => {
        setRecord({ ...record, clientId: e.value });
        setSelectedClientList({
            label: e.label,
            value: e.value,
            ...e,
        });
    };

    const handleSelectPrizePoolTypeEnum = (e) => {
        setRecord({ ...record, prizePoolTypeEnum: e.value });
        setSelectedPrizePoolTypeEnum({
            label: e.label,
            value: e.value,
            ...e,
        });
    };

    const handleSelectWaitingTimeForDrawNextPart = (e) => {
        setSelectedWaitingTimeForDrawNextPart({
            label: e.label,
            value: e.value,
            ...e,
        });
    };
    const handleSelectWaitingTimeForApplyBooster = (e) => {
        setSelectedWaitingTimeForApplyBooster({
            label: e.label,
            value: e.value,
            ...e,
        });
    };

    return (
        <ModalWrapper
            show={show}
            hide={hideModal}
            // backdrop="static"
            header={`${recordID ? "Update" : "Add"} Contest Details`}
            size="lg"
            handleSubmit={handleSubmit}
        >
            <div className="form-group row">
                <div className="col-lg-6 mb-3">
                    <label>Select Client*</label>
                    <Select
                        value={selectedClientList}
                        options={clientList}
                        onChange={handleSelectClientList}
                    />
                </div>
                <div className="col-lg-6 mb-3">
                    <label>Select Cricket Match*</label>
                    <Select
                        value={selectedMatchList}
                        options={matchList}
                        onChange={handleSelectMatchList}
                    />
                </div>
                <div className="col-lg-6 mb-3">
                    <label>Publish Date*</label>
                    <input
                        type="datetime-local"
                        className="form-control input-default"
                        id="publishDateTimeStamp"
                        name="publishDateTimeStamp"
                        onChange={handleTextChange}
                        value={record.publishDateTimeStamp}
                    />
                </div>
                <div className="col-lg-6 mb-3">
                    <label>Start Date*</label>
                    <input
                        type="datetime-local"
                        className="form-control input-default"
                        id="startDateTimeStamp"
                        name="startDateTimeStamp"
                        onChange={handleTextChange}
                        value={record.startDateTimeStamp}
                    />
                </div>
                <div className="col-lg-6 mb-3">
                    <label>Waiting Time For Draw Next Part*</label>
                    <Select
                        value={selectedWaitingTimeForDrawNextPart}
                        options={waitingTimeInSec}
                        onChange={handleSelectWaitingTimeForDrawNextPart}
                    />
                </div>
                <div className="col-lg-6 mb-3">
                    <label>Waiting Time For Apply Booster*</label>
                    <Select
                        value={selectedWaitingTimeForApplyBooster}
                        options={applyBoosterTimeInSec}
                        onChange={handleSelectWaitingTimeForApplyBooster}
                    />
                </div>
                <div className="col-lg-6 mb-3">
                    <label>Minimum Number Of Participants Required*</label>
                    <input
                        type="number"
                        className="form-control input-default"
                        id="minimumNumberOfParticipantsRequired"
                        name="minimumNumberOfParticipantsRequired"
                        onChange={handleTextChange}
                        value={Math.abs(record.minimumNumberOfParticipantsRequired)}
                    />
                </div>
                <div className="col-lg-6 mb-3">
                    <label>Maximum Number Of Participants*</label>
                    <input
                        type="number"
                        className="form-control input-default"
                        id="maximumNumberOfParticipants"
                        name="maximumNumberOfParticipants"
                        onChange={handleTextChange}
                        value={Math.abs(record.maximumNumberOfParticipants)}
                    />
                </div>
                <div className="col-lg-3 mb-3">
                    <label>Select Prize Pool Type*</label>
                    <Select
                        value={selectedPrizePoolTypeEnum}
                        options={prizePoolTypeEnum}
                        onChange={handleSelectPrizePoolTypeEnum}
                    />
                </div>
                <div className="col-lg-3 mb-3">
                    <label>Entry Fee*</label>
                    <input
                        type="number"
                        className="form-control input-default"
                        id="entryFee"
                        name="entryFee"
                        onChange={handleTextChange}
                        value={record.entryFee}
                    />
                </div>
                <div className="col-lg-3 mb-3">
                    <label>Prize Pool*</label>
                    <input
                        type="number"
                        className="form-control input-default"
                        id="prizePool"
                        name="prizePool"
                        onChange={handleTextChange}
                        value={record.prizePool}
                    />
                </div>
                <div className="col-lg-3 mb-3">
                    <label> Replica Count*</label>
                    <input
                        type="number"
                        className="form-control input-default"
                        id="replicaCount"
                        name="replicaCount"
                        onChange={handleTextChange}
                        value={record?.replicaCount}
                    />
                </div>

                <div className="col-lg-12 mb-3">
                    <div className="row">
                        <div className="col-12 d-flex justify-content-between align-items-center mb-3">
                            <label>Reward Distribution *</label>
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={handleClickAddRewardDistribution}
                            >
                                Add
                            </button>
                        </div>
                        <div className="col-12">
                            {rewardDistributionList &&
                                rewardDistributionList.map((data, i) =>
                                    addRewardDistribution(i)
                                )}
                        </div>
                    </div>
                </div>
            </div>
        </ModalWrapper>
    );
};

export default ContestAddEdit;
