import React from "react";
import PageHeading from "../common/PageHeading";

const Home = () => {
    return (
        <>
            {PageHeading("Dashboard", "", "/")}
            <div className="row">
                <div className="col-xl-8">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="card mini-stats-wid">
                                <div className="card-body">
                                    <div className="d-flex flex-wrap">
                                        <div className="me-3">
                                            <p className="text-muted mb-2">Total Post</p>
                                            <h5 className="mb-0">120</h5>
                                        </div>
                                        <div className="avatar-sm ms-auto">
                                            <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                                <i className="bx bxs-book-bookmark" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="card blog-stats-wid">
                                <div className="card-body">
                                    <div className="d-flex flex-wrap">
                                        <div className="me-3">
                                            <p className="text-muted mb-2">Pages</p>
                                            <h5 className="mb-0">86</h5>
                                        </div>
                                        <div className="avatar-sm ms-auto">
                                            <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                                <i className="bx bxs-note" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="card blog-stats-wid">
                                <div className="card-body">
                                    <div className="d-flex flex-wrap">
                                        <div className="me-3">
                                            <p className="text-muted mb-2">Comments</p>
                                            <h5 className="mb-0">4,235</h5>
                                        </div>
                                        <div className="avatar-sm ms-auto">
                                            <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                                <i className="bx bxs-message-square-dots" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* end row */}
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex flex-wrap align-items-start">
                                <h5 className="card-title me-2">Visitors</h5>
                                <div className="ms-auto">
                                    <div className="toolbar d-flex flex-wrap gap-2 text-end">
                                        <button type="button" className="btn btn-light btn-sm">
                                            ALL
                                        </button>
                                        <button type="button" className="btn btn-light btn-sm">
                                            1M
                                        </button>
                                        <button type="button" className="btn btn-light btn-sm">
                                            6M
                                        </button>
                                        <button type="button" className="btn btn-light btn-sm active">
                                            1Y
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="row text-center">
                                <div className="col-lg-4">
                                    <div className="mt-4">
                                        <p className="text-muted mb-1">Today</p>
                                        <h5>1024</h5>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="mt-4">
                                        <p className="text-muted mb-1">This Month</p>
                                        <h5>
                                            12356{" "}
                                            <span className="text-success font-size-13">
                                                0.2 % <i className="mdi mdi-arrow-up ms-1" />
                                            </span>
                                        </h5>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="mt-4">
                                        <p className="text-muted mb-1">This Year</p>
                                        <h5>
                                            102354{" "}
                                            <span className="text-success font-size-13">
                                                0.1 % <i className="mdi mdi-arrow-up ms-1" />
                                            </span>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <hr className="mb-4" />
                        </div>
                    </div>
                </div>
                {/* end col */}
                <div className="col-xl-4">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex">
                                <div className="flex-shrink-0 me-3">
                                    <img src="/assets/images/users/avatar-1.jpg" alt="" className="avatar-sm rounded-circle img-thumbnail" />
                                </div>
                                <div className="flex-grow-1">
                                    <div className="d-flex">
                                        <div className="flex-grow-1">
                                            <div className="text-muted">
                                                <h5 className="mb-1">Henry wells</h5>
                                                <p className="mb-0">UI / UX Designer</p>
                                            </div>
                                        </div>
                                        <div className="flex-shrink-0 dropdown ms-2">
                                            <button className="btn btn-light btn-sm" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i className="bx bxs-cog align-middle me-1" /> Setting
                                            </button>
                                            <div className="dropdown-menu dropdown-menu-end">
                                                <a className="dropdown-item" href="#">
                                                    Action
                                                </a>
                                                <a className="dropdown-item" href="#">
                                                    Another action
                                                </a>
                                                <a className="dropdown-item" href="#">
                                                    Something else
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="col-4">
                                            <div>
                                                <p className="text-muted text-truncate mb-2">Total Post</p>
                                                <h5 className="mb-0">32</h5>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div>
                                                <p className="text-muted text-truncate mb-2">Subscribes</p>
                                                <h5 className="mb-0">10k</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex flex-wrap align-items-start">
                                <h5 className="card-title mb-3 me-2">Subscribes</h5>
                                <div className="dropdown ms-auto">
                                    <a className="text-muted font-size-16" role="button" data-bs-toggle="dropdown" aria-haspopup="true">
                                        <i className="mdi mdi-dots-horizontal" />
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-end">
                                        <a className="dropdown-item" href="#">
                                            Action
                                        </a>
                                        <a className="dropdown-item" href="#">
                                            Another action
                                        </a>
                                        <a className="dropdown-item" href="#">
                                            Something else here
                                        </a>
                                        <div className="dropdown-divider" />
                                        <a className="dropdown-item" href="#">
                                            Separated link
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-wrap">
                                <div>
                                    <p className="text-muted mb-1">Total Subscribe</p>
                                    <h4 className="mb-3">10,512</h4>
                                    <p className="text-success mb-0">
                                        <span>
                                            0.6 % <i className="mdi mdi-arrow-top-right ms-1" />
                                        </span>
                                    </p>
                                </div>
                                <div className="ms-auto align-self-end">
                                    <i className="bx bx-group display-4 text-light" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body p-4">
                            <div className="text-center">
                                <div className="avatar-md mx-auto mb-4">
                                    <div className="avatar-title bg-light rounded-circle text-primary h1">
                                        <i className="mdi mdi-email-open" />
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-xl-10">
                                        <h4 className="text-primary">Subscribe !</h4>
                                        <p className="text-muted font-size-14 mb-4">Subscribe our newletter and get notification to stay update.</p>
                                        <div className="input-group bg-light rounded">
                                            <input
                                                type="email"
                                                className="form-control bg-transparent border-0"
                                                placeholder="Enter Email address"
                                                aria-label="Recipient's username"
                                                aria-describedby="button-addon2"
                                            />
                                            <button className="btn btn-primary" type="button" id="button-addon2">
                                                <i className="bx bxs-paper-plane" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* end col */}
            </div>
            {/* end row */}
            <div className="row">
                <div className="col-xl-4 col-lg-6">
                    <div className="card">
                        <div className="card-header bg-transparent border-bottom">
                            <div className="d-flex flex-wrap align-items-start">
                                <div className="me-2">
                                    <h5 className="card-title mt-1 mb-0">Posts</h5>
                                </div>
                                <ul className="nav nav-tabs nav-tabs-custom card-header-tabs ms-auto" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link active" data-bs-toggle="tab" href="#post-recent" role="tab">
                                            Recent
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" data-bs-toggle="tab" href="#post-popular" role="tab">
                                            Popular
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="card-body">
                            <div data-simplebar="" className="card-body-scroll" style={{maxHeight: "340px"}}>
                                {/* Tab panes */}
                                <div className="tab-content">
                                    <div className="tab-pane active" id="post-recent" role="tabpanel">
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item py-3">
                                                <div className="d-flex">
                                                    <div className="me-3">
                                                        <img src="/assets/images/users/avatar-1.jpg" alt="" className="avatar-md h-auto d-block rounded" />
                                                    </div>
                                                    <div className="align-self-center overflow-hidden me-auto">
                                                        <div>
                                                            <h5 className="font-size-14 text-truncate">
                                                                <a href="#" className="text-dark">
                                                                    Beautiful Day with Friends
                                                                </a>
                                                            </h5>
                                                            <p className="text-muted mb-0">10 Nov, 2020</p>
                                                        </div>
                                                    </div>
                                                    <div className="dropdown ms-2">
                                                        <a className="text-muted font-size-14" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            <i className="mdi mdi-dots-horizontal" />
                                                        </a>
                                                        <div className="dropdown-menu dropdown-menu-end">
                                                            <a className="dropdown-item" href="#">
                                                                Action
                                                            </a>
                                                            <a className="dropdown-item" href="#">
                                                                Another action
                                                            </a>
                                                            <a className="dropdown-item" href="#">
                                                                Something else here
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="list-group-item py-3">
                                                <div className="d-flex">
                                                    <div className="me-3">
                                                        <img src="/assets/images/users/avatar-1.jpg" alt="" className="avatar-md h-auto d-block rounded" />
                                                    </div>
                                                    <div className="align-self-center overflow-hidden me-auto">
                                                        <div>
                                                            <h5 className="font-size-14 text-truncate">
                                                                <a href="#" className="text-dark">
                                                                    Drawing a sketch
                                                                </a>
                                                            </h5>
                                                            <p className="text-muted mb-0">02 Nov, 2020</p>
                                                        </div>
                                                    </div>
                                                    <div className="dropdown ms-2">
                                                        <a className="text-muted font-size-14" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            <i className="mdi mdi-dots-horizontal" />
                                                        </a>
                                                        <div className="dropdown-menu dropdown-menu-end">
                                                            <a className="dropdown-item" href="#">
                                                                Action
                                                            </a>
                                                            <a className="dropdown-item" href="#">
                                                                Another action
                                                            </a>
                                                            <a className="dropdown-item" href="#">
                                                                Something else here
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="list-group-item py-3">
                                                <div className="d-flex">
                                                    <div className="me-3">
                                                        <img src="/assets/images/users/avatar-1.jpg" alt="" className="avatar-md h-auto d-block rounded" />
                                                    </div>
                                                    <div className="align-self-center overflow-hidden me-auto">
                                                        <div>
                                                            <h5 className="font-size-14 text-truncate">
                                                                <a href="#" className="text-dark">
                                                                    Project discussion with team
                                                                </a>
                                                            </h5>
                                                            <p className="text-muted mb-0">24 Oct, 2020</p>
                                                        </div>
                                                    </div>
                                                    <div className="dropdown ms-2">
                                                        <a className="text-muted font-size-14" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            <i className="mdi mdi-dots-horizontal" />
                                                        </a>
                                                        <div className="dropdown-menu dropdown-menu-end">
                                                            <a className="dropdown-item" href="#">
                                                                Action
                                                            </a>
                                                            <a className="dropdown-item" href="#">
                                                                Another action
                                                            </a>
                                                            <a className="dropdown-item" href="#">
                                                                Something else here
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="list-group-item py-3">
                                                <div className="d-flex">
                                                    <div className="me-3">
                                                        <img src="/assets/images/users/avatar-1.jpg" alt="" className="avatar-md h-auto d-block rounded" />
                                                    </div>
                                                    <div className="align-self-center overflow-hidden me-auto">
                                                        <div>
                                                            <h5 className="font-size-14 text-truncate">
                                                                <a href="#" className="text-dark">
                                                                    Riding bike on road
                                                                </a>
                                                            </h5>
                                                            <p className="text-muted mb-0">20 Oct, 2020</p>
                                                        </div>
                                                    </div>
                                                    <div className="dropdown ms-2">
                                                        <a className="text-muted font-size-14" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            <i className="mdi mdi-dots-horizontal" />
                                                        </a>
                                                        <div className="dropdown-menu dropdown-menu-end">
                                                            <a className="dropdown-item" href="#">
                                                                Action
                                                            </a>
                                                            <a className="dropdown-item" href="#">
                                                                Another action
                                                            </a>
                                                            <a className="dropdown-item" href="#">
                                                                Something else here
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    {/* end tab pane */}
                                    <div className="tab-pane" id="post-popular" role="tabpanel">
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item py-3">
                                                <div className="d-flex">
                                                    <div className="me-3">
                                                        <img src="/assets/images/users/avatar-1.jpg" alt="" className="avatar-md h-auto d-block rounded" />
                                                    </div>
                                                    <div className="align-self-center overflow-hidden me-auto">
                                                        <div>
                                                            <h5 className="font-size-14 text-truncate">
                                                                <a href="#" className="text-dark">
                                                                    Drawing a sketch
                                                                </a>
                                                            </h5>
                                                            <p className="text-muted mb-0">02 Nov, 2020</p>
                                                        </div>
                                                    </div>
                                                    <div className="dropdown ms-2">
                                                        <a className="text-muted font-size-14" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            <i className="mdi mdi-dots-horizontal" />
                                                        </a>
                                                        <div className="dropdown-menu dropdown-menu-end">
                                                            <a className="dropdown-item" href="#">
                                                                Action
                                                            </a>
                                                            <a className="dropdown-item" href="#">
                                                                Another action
                                                            </a>
                                                            <a className="dropdown-item" href="#">
                                                                Something else here
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="list-group-item py-3">
                                                <div className="d-flex">
                                                    <div className="me-3">
                                                        <img src="/assets/images/users/avatar-1.jpg" alt="" className="avatar-md h-auto d-block rounded" />
                                                    </div>
                                                    <div className="align-self-center overflow-hidden me-auto">
                                                        <div>
                                                            <h5 className="font-size-14 text-truncate">
                                                                <a href="#" className="text-dark">
                                                                    Beautiful Day with Friends
                                                                </a>
                                                            </h5>
                                                            <p className="text-muted mb-0">10 Nov, 2020</p>
                                                        </div>
                                                    </div>
                                                    <div className="dropdown ms-2">
                                                        <a className="text-muted font-size-14" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            <i className="mdi mdi-dots-horizontal" />
                                                        </a>
                                                        <div className="dropdown-menu dropdown-menu-end">
                                                            <a className="dropdown-item" href="#">
                                                                Action
                                                            </a>
                                                            <a className="dropdown-item" href="#">
                                                                Another action
                                                            </a>
                                                            <a className="dropdown-item" href="#">
                                                                Something else here
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="list-group-item py-3">
                                                <div className="d-flex">
                                                    <div className="me-3">
                                                        <img src="/assets/images/users/avatar-1.jpg" alt="" className="avatar-md h-auto d-block rounded" />
                                                    </div>
                                                    <div className="align-self-center overflow-hidden me-auto">
                                                        <div>
                                                            <h5 className="font-size-14 text-truncate">
                                                                <a href="#" className="text-dark">
                                                                    Riding bike on road
                                                                </a>
                                                            </h5>
                                                            <p className="text-muted mb-0">20 Oct, 2020</p>
                                                        </div>
                                                    </div>
                                                    <div className="dropdown ms-2">
                                                        <a className="text-muted font-size-14" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            <i className="mdi mdi-dots-horizontal" />
                                                        </a>
                                                        <div className="dropdown-menu dropdown-menu-end">
                                                            <a className="dropdown-item" href="#">
                                                                Action
                                                            </a>
                                                            <a className="dropdown-item" href="#">
                                                                Another action
                                                            </a>
                                                            <a className="dropdown-item" href="#">
                                                                Something else here
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="list-group-item py-3">
                                                <div className="d-flex">
                                                    <div className="me-3">
                                                        <img src="/assets/images/users/avatar-1.jpg" alt="" className="avatar-md h-auto d-block rounded" />
                                                    </div>
                                                    <div className="align-self-center overflow-hidden me-auto">
                                                        <div>
                                                            <h5 className="font-size-14 text-truncate">
                                                                <a href="#" className="text-dark">
                                                                    Project discussion with team
                                                                </a>
                                                            </h5>
                                                            <p className="text-muted mb-0">24 Oct, 2020</p>
                                                        </div>
                                                    </div>
                                                    <div className="dropdown ms-2">
                                                        <a className="text-muted font-size-14" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            <i className="mdi mdi-dots-horizontal" />
                                                        </a>
                                                        <div className="dropdown-menu dropdown-menu-end">
                                                            <a className="dropdown-item" href="#">
                                                                Action
                                                            </a>
                                                            <a className="dropdown-item" href="#">
                                                                Another action
                                                            </a>
                                                            <a className="dropdown-item" href="#">
                                                                Something else here
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    {/* end tab pane */}
                                </div>
                                {/* end tab content */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* end col */}
                <div className="col-xl-4 col-lg-6">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex flex-wrap align-items-start">
                                <div className="me-2">
                                    <h5 className="card-title mb-3">Comments</h5>
                                </div>
                                <div className="dropdown ms-auto">
                                    <a className="text-muted font-size-16" role="button" data-bs-toggle="dropdown" aria-haspopup="true">
                                        <i className="mdi mdi-dots-horizontal" />
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-end">
                                        <a className="dropdown-item" href="#">
                                            Action
                                        </a>
                                        <a className="dropdown-item" href="#">
                                            Another action
                                        </a>
                                        <a className="dropdown-item" href="#">
                                            Something else here
                                        </a>
                                        <div className="dropdown-divider" />
                                        <a className="dropdown-item" href="#">
                                            Separated link
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div data-simplebar="" className="card-body-scroll" style={{maxHeight: "299px"}}>
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item py-3">
                                        <div className="d-flex">
                                            <div className="flex-shrink-0 me-3">
                                                <div className="avatar-xs">
                                                    <div className="avatar-title rounded-circle bg-light text-primary">
                                                        <i className="bx bxs-user" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-grow-1">
                                                <h5 className="font-size-14 mb-1">
                                                    Delores Williams <small className="text-muted float-end">1 hr Ago</small>
                                                </h5>
                                                <p className="text-muted">If several languages coalesce, the grammar of the resulting of the individual</p>
                                                <div>
                                                    <a href="#" className="text-success">
                                                        <i className="mdi mdi-reply me-1" /> Reply
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="list-group-item py-3">
                                        <div className="d-flex">
                                            <div className="flex-shrink-0 me-3">
                                                <div className="avatar-xs">
                                                    <img src="/assets/images/users/avatar-1.jpg" alt="" className="img-fluid d-block rounded-circle" />
                                                </div>
                                            </div>
                                            <div className="flex-grow-1">
                                                <h5 className="font-size-14 mb-1">
                                                    Clarence Smith <small className="text-muted float-end">2 hrs Ago</small>
                                                </h5>
                                                <p className="text-muted">Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet</p>
                                                <div>
                                                    <a href="#" className="text-success">
                                                        <i className="mdi mdi-reply" /> Reply
                                                    </a>
                                                </div>
                                                <div className="d-flex pt-3">
                                                    <div className="flex-shrink-0 me-3">
                                                        <div className="avatar-xs">
                                                            <div className="avatar-title rounded-circle bg-light text-primary">
                                                                <i className="bx bxs-user" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <h5 className="font-size-14 mb-1">
                                                            Silvia Martinez <small className="text-muted float-end">2 hrs Ago</small>
                                                        </h5>
                                                        <p className="text-muted">To take a trivial example, which of us ever undertakes</p>
                                                        <div>
                                                            <a href="#" className="text-success">
                                                                <i className="mdi mdi-reply" /> Reply
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="list-group-item py-3">
                                        <div className="d-flex">
                                            <div className="flex-shrink-0 me-3">
                                                <div className="avatar-xs">
                                                    <div className="avatar-title rounded-circle bg-light text-primary">
                                                        <i className="bx bxs-user" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-grow-1">
                                                <h5 className="font-size-14 mb-1">
                                                    Keith McCoy <small className="text-muted float-end">12 Aug</small>
                                                </h5>
                                                <p className="text-muted">Donec posuere vulputate arcu. phasellus accumsan cursus velit</p>
                                                <div>
                                                    <a href="#" className="text-success">
                                                        <i className="mdi mdi-reply" /> Reply
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/* end col */}
                <div className="col-xl-4">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex flex-wrap align-items-start">
                                <div className="me-2">
                                    <h5 className="card-title mb-3">Top Visitors</h5>
                                </div>
                                <div className="dropdown ms-auto">
                                    <a className="text-muted font-size-16" role="button" data-bs-toggle="dropdown" aria-haspopup="true">
                                        <i className="mdi mdi-dots-horizontal" />
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-end">
                                        <a className="dropdown-item" href="#">
                                            Action
                                        </a>
                                        <a className="dropdown-item" href="#">
                                            Another action
                                        </a>
                                        <a className="dropdown-item" href="#">
                                            Something else here
                                        </a>
                                        <div className="dropdown-divider" />
                                        <a className="dropdown-item" href="#">
                                            Separated link
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="row text-center">
                                <div className="col-6">
                                    <div className="mt-3">
                                        <p className="text-muted mb-1">Today</p>
                                        <h5>1024</h5>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="mt-3">
                                        <p className="text-muted mb-1">This Month</p>
                                        <h5>
                                            12356{" "}
                                            <span className="text-success font-size-13">
                                                0.2 % <i className="mdi mdi-arrow-up ms-1" />
                                            </span>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div>
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item">
                                        <div className="py-2">
                                            <h5 className="font-size-14">
                                                California <span className="float-end">78%</span>
                                            </h5>
                                            <div className="progress animated-progess progress-sm">
                                                <div className="progress-bar" role="progressbar" style={{ width: "78%" }} aria-valuenow={78} aria-valuemin={0} aria-valuemax={100} />
                                            </div>
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        <div className="py-2">
                                            <h5 className="font-size-14">
                                                Nevada <span className="float-end">69%</span>
                                            </h5>
                                            <div className="progress animated-progess progress-sm">
                                                <div className="progress-bar bg-warning" role="progressbar" style={{ width: "69%" }} aria-valuenow={69} aria-valuemin={0} aria-valuemax={100} />
                                            </div>
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        <div className="py-2">
                                            <h5 className="font-size-14">
                                                Texas <span className="float-end">61%</span>
                                            </h5>
                                            <div className="progress animated-progess progress-sm">
                                                <div className="progress-bar bg-success" role="progressbar" style={{ width: "61%" }} aria-valuenow={61} aria-valuemin={0} aria-valuemax={100} />
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/* end col */}
            </div>
            {/* end row */}
            <div className="row">
                <div className="col-xl-4">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex align-items-start">
                                <div className="me-2">
                                    <h5 className="card-title mb-4">Activity</h5>
                                </div>
                                <div className="dropdown ms-auto">
                                    <a className="text-muted font-size-16" role="button" data-bs-toggle="dropdown" aria-haspopup="true">
                                        <i className="mdi mdi-dots-horizontal" />
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-end">
                                        <a className="dropdown-item" href="#">
                                            Action
                                        </a>
                                        <a className="dropdown-item" href="#">
                                            Another action
                                        </a>
                                        <a className="dropdown-item" href="#">
                                            Something else here
                                        </a>
                                        <div className="dropdown-divider" />
                                        <a className="dropdown-item" href="#">
                                            Separated link
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div data-simplebar="" className="mt-2" style={{ maxHeight: 280 }}>
                                <ul className="verti-timeline list-unstyled">
                                    <li className="event-list active">
                                        <div className="event-timeline-dot">
                                            <i className="bx bxs-right-arrow-circle font-size-18 bx-fade-right" />
                                        </div>
                                        <div className="d-flex">
                                            <div className="flex-shrink-0 me-3">
                                                <h5 className="font-size-14">
                                                    10 Nov <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                                                </h5>
                                            </div>
                                            <div className="flex-grow-1">
                                                <div>
                                                    Posted <span className="fw-semibold">Beautiful Day with Friends</span> blog... <a href="#">View</a>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="event-list">
                                        <div className="event-timeline-dot">
                                            <i className="bx bx-right-arrow-circle font-size-18" />
                                        </div>
                                        <div className="d-flex">
                                            <div className="flex-shrink-0 me-3">
                                                <h5 className="font-size-14">
                                                    08 Nov <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                                                </h5>
                                            </div>
                                            <div className="flex-grow-1">
                                                <div>
                                                    If several languages coalesce, the grammar of the resulting... <a href="#">Read</a>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="event-list">
                                        <div className="event-timeline-dot">
                                            <i className="bx bx-right-arrow-circle font-size-18" />
                                        </div>
                                        <div className="d-flex">
                                            <div className="flex-shrink-0 me-3">
                                                <h5 className="font-size-14">
                                                    02 Nov <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                                                </h5>
                                            </div>
                                            <div className="flex-grow-1">
                                                <div>
                                                    Create <span className="fw-semibold">Drawing a sketch blog</span>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="event-list">
                                        <div className="event-timeline-dot">
                                            <i className="bx bx-right-arrow-circle font-size-18" />
                                        </div>
                                        <div className="d-flex">
                                            <div className="flex-shrink-0 me-3">
                                                <h5 className="font-size-14">
                                                    24 Oct <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                                                </h5>
                                            </div>
                                            <div className="flex-grow-1">
                                                <div>In enim justo, rhoncus ut, imperdiet a venenatis vitae</div>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="event-list">
                                        <div className="event-timeline-dot">
                                            <i className="bx bx-right-arrow-circle font-size-18" />
                                        </div>
                                        <div className="d-flex">
                                            <div className="flex-shrink-0 me-3">
                                                <h5 className="font-size-14">
                                                    21 Oct <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                                                </h5>
                                            </div>
                                            <div className="flex-grow-1">
                                                <div>Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut</div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="text-center mt-4">
                                <a href="#" className="btn btn-primary waves-effect waves-light btn-sm">
                                    View More <i className="mdi mdi-arrow-right ms-1" />
                                </a>
                            </div>
                        </div>
                    </div>
                    {/* end card */}
                </div>
                {/* end col */}
                <div className="col-xl-8">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex align-items-start">
                                <div className="me-2">
                                    <h5 className="card-title mb-4">Popular post</h5>
                                </div>
                                <div className="dropdown ms-auto">
                                    <a className="text-muted font-size-16" role="button" data-bs-toggle="dropdown" aria-haspopup="true">
                                        <i className="mdi mdi-dots-horizontal" />
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-end">
                                        <a className="dropdown-item" href="#">
                                            Action
                                        </a>
                                        <a className="dropdown-item" href="#">
                                            Another action
                                        </a>
                                        <a className="dropdown-item" href="#">
                                            Something else here
                                        </a>
                                        <div className="dropdown-divider" />
                                        <a className="dropdown-item" href="#">
                                            Separated link
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className="table align-middle table-nowrap mb-0">
                                    <tbody>
                                        <tr>
                                            <th scope="col" colSpan={2}>
                                                Post
                                            </th>
                                            <th scope="col">Likes</th>
                                            <th scope="col">Comments</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </tbody>
                                    <tbody>
                                        <tr>
                                            <td style={{ width: 100 }}>
                                                <img src="/assets/images/users/avatar-1.jpg" alt="" className="avatar-md h-auto d-block rounded" />
                                            </td>
                                            <td>
                                                <h5 className="font-size-13 text-truncate mb-1">
                                                    <a href="#" className="text-dark">
                                                        Beautiful Day with Friends
                                                    </a>
                                                </h5>
                                                <p className="text-muted mb-0">10 Nov, 2020</p>
                                            </td>
                                            <td>
                                                <i className="bx bx-like align-middle me-1" /> 125
                                            </td>
                                            <td>
                                                <i className="bx bx-comment-dots align-middle me-1" /> 68
                                            </td>
                                            <td>
                                                <div className="dropdown">
                                                    <a className="text-muted font-size-16" role="button" data-bs-toggle="dropdown" aria-haspopup="true">
                                                        <i className="mdi mdi-dots-horizontal" />
                                                    </a>
                                                    <div className="dropdown-menu dropdown-menu-end">
                                                        <a className="dropdown-item" href="#">
                                                            Action
                                                        </a>
                                                        <a className="dropdown-item" href="#">
                                                            Another action
                                                        </a>
                                                        <a className="dropdown-item" href="#">
                                                            Something else here
                                                        </a>
                                                        <div className="dropdown-divider" />
                                                        <a className="dropdown-item" href="#">
                                                            Separated link
                                                        </a>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <img src="/assets/images/users/avatar-1.jpg" alt="" className="avatar-md h-auto d-block rounded" />
                                            </td>
                                            <td>
                                                <h5 className="font-size-13 text-truncate mb-1">
                                                    <a href="#" className="text-dark">
                                                        Drawing a sketch
                                                    </a>
                                                </h5>
                                                <p className="text-muted mb-0">02 Nov, 2020</p>
                                            </td>
                                            <td>
                                                <i className="bx bx-like align-middle me-1" /> 102
                                            </td>
                                            <td>
                                                <i className="bx bx-comment-dots align-middle me-1" /> 48
                                            </td>
                                            <td>
                                                <div className="dropdown">
                                                    <a className="text-muted font-size-16" role="button" data-bs-toggle="dropdown" aria-haspopup="true">
                                                        <i className="mdi mdi-dots-horizontal" />
                                                    </a>
                                                    <div className="dropdown-menu dropdown-menu-end">
                                                        <a className="dropdown-item" href="#">
                                                            Action
                                                        </a>
                                                        <a className="dropdown-item" href="#">
                                                            Another action
                                                        </a>
                                                        <a className="dropdown-item" href="#">
                                                            Something else here
                                                        </a>
                                                        <div className="dropdown-divider" />
                                                        <a className="dropdown-item" href="#">
                                                            Separated link
                                                        </a>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <img src="/assets/images/users/avatar-1.jpg" alt="" className="avatar-md h-auto d-block rounded" />
                                            </td>
                                            <td>
                                                <h5 className="font-size-13 text-truncate mb-1">
                                                    <a href="#" className="text-dark">
                                                        Riding bike on road
                                                    </a>
                                                </h5>
                                                <p className="text-muted mb-0">24 Oct, 2020</p>
                                            </td>
                                            <td>
                                                <i className="bx bx-like align-middle me-1" /> 98
                                            </td>
                                            <td>
                                                <i className="bx bx-comment-dots align-middle me-1" /> 35
                                            </td>
                                            <td>
                                                <div className="dropdown">
                                                    <a className="text-muted font-size-16" role="button" data-bs-toggle="dropdown" aria-haspopup="true">
                                                        <i className="mdi mdi-dots-horizontal" />
                                                    </a>
                                                    <div className="dropdown-menu dropdown-menu-end">
                                                        <a className="dropdown-item" href="#">
                                                            Action
                                                        </a>
                                                        <a className="dropdown-item" href="#">
                                                            Another action
                                                        </a>
                                                        <a className="dropdown-item" href="#">
                                                            Something else here
                                                        </a>
                                                        <div className="dropdown-divider" />
                                                        <a className="dropdown-item" href="#">
                                                            Separated link
                                                        </a>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <img src="/assets/images/users/avatar-1.jpg" alt="" className="avatar-md h-auto d-block rounded" />
                                            </td>
                                            <td>
                                                <h5 className="font-size-13 text-truncate mb-1">
                                                    <a href="#" className="text-dark">
                                                        Project discussion with team
                                                    </a>
                                                </h5>
                                                <p className="text-muted mb-0">15 Oct, 2020</p>
                                            </td>
                                            <td>
                                                <i className="bx bx-like align-middle me-1" /> 92
                                            </td>
                                            <td>
                                                <i className="bx bx-comment-dots align-middle me-1" /> 22
                                            </td>
                                            <td>
                                                <div className="dropdown">
                                                    <a className="text-muted font-size-16" role="button" data-bs-toggle="dropdown" aria-haspopup="true">
                                                        <i className="mdi mdi-dots-horizontal" />
                                                    </a>
                                                    <div className="dropdown-menu dropdown-menu-end">
                                                        <a className="dropdown-item" href="#">
                                                            Action
                                                        </a>
                                                        <a className="dropdown-item" href="#">
                                                            Another action
                                                        </a>
                                                        <a className="dropdown-item" href="#">
                                                            Something else here
                                                        </a>
                                                        <div className="dropdown-divider" />
                                                        <a className="dropdown-item" href="#">
                                                            Separated link
                                                        </a>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    {/* end card */}
                </div>
                {/* end col */}
            </div>
        </>
    );
};

export default Home;
