import {
    Activate_Client_PUT,
    All_Client_List_POST,
    DeActivate_Client_PUT,
    Delete_Client_DELETE,
    Deleted_Client_List_POST,
    Download_Excel_GET,
    Get_Public_Key_GET,
    Regenerate_Public_Private_Key_PUT,
    Revive_Client_PUT,
} from "api/ApiConst";
import CheckboxButton from "components/common/CheckboxButton";
import CustomButton from "components/common/CustomButton";
import PageHeading from "components/common/PageHeading";
import PaginationDataTable from "components/common/PaginationDataTable";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import dateUtilities from "utils/DateUtilities";
import {
    getImageSrcViaRefId,
    handleActive,
    handleDelete,
    handleInactive,
    handleRevive,
} from "utils/UserUtils";
import AdminClientAddEdit from "./AdminClientAddEdit";
import { callGetApi, callGetApiForExcelDownLoad, callGetApiWithoutAuth, callPostApi, callPutApi } from "api/ApiCaller";
import { SECTION_LOADER } from "utils/constants";
import { API_SUCCESS } from "api/ResponseCodes";
import toast from "react-hot-toast";
import ModalWrapper from "components/common/ModalWrapper";
import { Button, Modal } from "react-bootstrap";

const AdminClientMaster = () => {
    const [refreshFlag, setRefreshFlag] = useState(0);
    const [viewDeletedList, setViewDeletedList] = useState(false);
    const [recordID, setRecordID] = useState(null);
    const [showAddUpdateModal, setShowAddUpdateModal] = useState(false);
    const [showPublicKeyModal, setShowPublicKeyModal] = useState(false);
    const [getPublicKeyRecord, setGetPublicKeyRecord] = useState(null);

    const handleClose = () => setShowPublicKeyModal(false);

    useEffect(() => {
        if (!showAddUpdateModal) {
            setRecordID(null);
        }
    }, [showAddUpdateModal]);

    const handleRefresh = useCallback(() => {
        setRefreshFlag(refreshFlag + 1);
    }, [refreshFlag]);

    const columns = useMemo(
        () => [
            {
                id: "status",
                name: "",
                selector: (row) => (
                    <div
                        className={row.active ? "led-green" : "led-red"}
                        title={row.active ? "Active" : "Inactive"}
                    />
                ),
                width: "60px",
                sortable: false,
            },
            {
                id: "image",
                name: "",
                selector: (row) => (
                    <div className="square-img">
                        <img src={getImageSrcViaRefId(row.productIconUrl)} />
                    </div>
                ),
                width: "100px",
                sortable: false,
            },
            {
                id: "productTitle",
                name: "Product Title",
                selector: (row) => row.productTitle,
                sortable: false,
                wrap: 2,
            },
            {
                id: "name",
                name: "Name",
                selector: (row) => `${row.firstName} ${row.lastName}`,
                sortable: false,
                wrap: 2,
            },

            {
                id: "mobile",
                name: "Mobile Number",
                selector: (row) => `${row.isdCode}${row.mobile}`,
                sortable: false,
                wrap: 2,
            },

            {
                id: "email",
                name: "Email",
                selector: (row) => row.email,
                sortable: false,
                wrap: 2,
            },
            {
                id: "createdAtTimeStamp",
                name: "Created At",
                selector: (row) =>
                    dateUtilities.formattedDate(
                        row.createdAtTimeStamp,
                        "dd-MM-yyyy, hh:mm aa"
                    ),
                width: "200px",
                sortable: false,
            },
            {
                id: "action",
                name: "",
                selector: (row) => (
                    <div className="custom-column-action">{getActionButtons(row)}</div>
                ),
                width: "100px",
                sortable: false,
            },
        ],
        [handleRefresh]
    );

    const getFileName = () => {
        return Math.floor(Math.random() * 100) + "_sample-file.xlsx";
      };

    const downloadExcel = (uuid) => {
        callGetApiForExcelDownLoad("", Download_Excel_GET.replaceAll("{recordID}", uuid), {}, (res) => {
            // Create a Blob from the byte array
            const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            // Create a URL from the Blob
            const url = window.URL.createObjectURL(blob);

            // Create an anchor element and trigger a download
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'file.xlsx'); // or any other extension
            document.body.appendChild(link);
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);
        }, (err) => {
            console.error("Api Catch Error :: ", err.message)
        })
    }

    const getActionButtons = (row) => {
        return (
            <div className="d-flex align-items-center justify-content-between">
                {!row.deleted && (
                    <button className="listBtn me-2" onClick={() => handleEdit(row)}>
                        <i className="bx bx-pencil"></i>
                    </button>
                )}
                <div className="dropdown">
                    <button
                        className="btn btn-primary btn-flat btn-addon btn-xs dropdown-toggle"
                        title="More"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{ padding: "5px 10px 8px 10px" }}
                    >
                        <i className="bx bx-chevron-down"></i>
                    </button>
                    <ul className="dropdown-menu dropdown-menu-right">
                        <>
                            {row.deleted ? (
                                <li onClick={() => handleReviveData(row)}>
                                    <i className="bx bx-log-out-circle"></i> Revive
                                </li>
                            ) : (
                                <>
                                    <li onClick={() => handleDeleteData(row)}>
                                        <i className="bx bx-trash"></i> Delete
                                    </li>
                                    <li onClick={() => handleActiveInactive(row)}>
                                        {row.active ? (
                                            <>
                                                <i className="bx bx-x-circle"></i> Inactive
                                            </>
                                        ) : (
                                            <>
                                                <i className="bx bx-check-circle"></i> Active
                                            </>
                                        )}
                                    </li>
                                    <li onClick={() => handleRegeneratePublicKey(row)}>
                                        <i className="bx bxs-user-plus"></i> Regenerate Public Key
                                    </li>
                                    <li onClick={() => handleGetPublicKey(row)}>
                                        <i className="bx bxs-user-plus"></i> Get Public Key
                                    </li>
                                        <li onClick={() => downloadExcel(row.uuid)}>
                                            <i className="fa fa-file-export"></i> Export Player
                                        </li>
                                    {/* <a href={`${Download_Excel_GET.replaceAll("{recordID}", row.uuid)}`} target='_blank' download>
                                    </a> */}
                                </>
                            )}
                        </>
                    </ul>
                </div>
            </div>
        );
    };

    const handleEdit = (row) => {
        setRecordID(row.uuid);
        setShowAddUpdateModal(true);
    };

    const handleActiveInactive = (row) => {
        if (row.active) {
            handleInactive(
                DeActivate_Client_PUT.replaceAll("{recordID}", row.uuid),
                handleRefresh
            );
        } else {
            handleActive(
                Activate_Client_PUT.replaceAll("{recordID}", row.uuid),
                handleRefresh
            );
        }
    };

    //Revive Record
    const handleReviveData = (row) => {
        handleRevive(
            Revive_Client_PUT.replaceAll("{recordID}", row.uuid),
            handleRefresh
        );
    };

    //Assign Roles
    const handleRegeneratePublicKey = (row) => {
        callPutApi({}, Regenerate_Public_Private_Key_PUT.replaceAll("{recordID}", row.uuid), {}, {}, (response) => {
            toast.success(response.message);
        });
    };


    const handleGetPublicKey = (row) => {
        callGetApi({}, Get_Public_Key_GET.replaceAll("{recordID}", row.uuid), {}, (response) => {
            const res = response.responsePacket;
            setGetPublicKeyRecord(res);
            setShowPublicKeyModal(true);
        });
    };
    

    //Delete Record
    const handleDeleteData = (row) => {
        handleDelete(
            Delete_Client_DELETE.replaceAll("{recordID}", row.uuid),
            handleRefresh
        );
    };

    const handleToggleDeletedView = () => setViewDeletedList(!viewDeletedList);

    return (
        <>
            {showPublicKeyModal &&
                <Modal show={showPublicKeyModal} onHide={handleClose} backdrop="static" size="md">
                    <Modal.Header closeButton>
                        <Modal.Title>Public Key</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="col-xl-12 my-2">
                            <label> Public Key</label>
                            <textarea
                                className="form-control input-default"
                                id="ingredients"
                                disabled
                                style={{ height: "100px" }}
                                value={getPublicKeyRecord}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={handleClose}>
                            Close
                        </Button>
                        {/* Add additional buttons or actions if needed */}
                    </Modal.Footer>
                </Modal>
            }
            {PageHeading("Client List", "Dashboard", "/")}
            <div className="card">
                <CustomButton
                    handleClick={() => setShowAddUpdateModal(true)}
                    title="Create New"
                    iconClass="bx bx-plus"
                    buttonClass="createButton"
                />
                <div className="card-body tableView custom-height">
                    <PaginationDataTable
                        paginationUrl={
                            viewDeletedList ? Deleted_Client_List_POST : All_Client_List_POST
                        }
                        serverSidePagination={true}
                        search={true}
                        pagination={false}
                        columns={columns}
                    />
                    <CheckboxButton
                        title="Deleted List"
                        handleClick={handleToggleDeletedView}
                        additionalClass="deletedBtn ml-auto"
                    />
                </div>
            </div>
            {showAddUpdateModal && (
                <AdminClientAddEdit
                    show={showAddUpdateModal}
                    hideModal={() => setShowAddUpdateModal(false)}
                    recordID={recordID}
                    handleRefresh={handleRefresh}
                />
            )}
        </>
    );
};

export default AdminClientMaster;
