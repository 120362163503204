import React from "react";

const Loader = ({ loaderClass = "", style }) => {
    return (
        <div className={`loading-container ${loaderClass}`} style={style}>
            <div className="loading"></div>
            <div id="loading-text">loading</div>
        </div>
    );
};

export default Loader;
