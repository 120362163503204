import { callGetApi, callPostApi, callPutApi } from "api/ApiCaller";
import {
    Add_Jersey_POST,
    Get_Jersey_Details_GET,
    Update_Jersey_PUT,
} from "api/ApiConst";
import ImageSelector from "components/common/ImageSelector";
import ModalWrapper from "components/common/ModalWrapper";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { isEmpty } from "utils/TextUtils";
import { MODAL_LOADER } from "utils/constants";

const AdminJerseyAddEdit = ({ show, hideModal, handleRefresh, recordID }) => {
    const defaultRecordObj = {
        jerseyImgUrl: "",
        jerseyTitle: "",
    };

    const [record, setRecord] = useState(defaultRecordObj);

    useEffect(() => {
        if (recordID) {
            callGetApi(
                MODAL_LOADER,
                Get_Jersey_Details_GET.replaceAll("{recordID}", recordID),
                {},
                (response) => {
                    const res = response.responsePacket;
                    setRecord(res);
                }
            );
        }
    }, [recordID]);

    //Text Change Event
    const handleTextChange = (e) => {
        setRecord((prev) => ({ ...prev, [e.target.id]: e.target.value }));
    };

    const checkInputValidation = () => {
        if (isEmpty(record.jerseyImgUrl)) {
            toast.error(`Jersey Image must not be empty`, { id: "toast" });
            return false;
        } else if (isEmpty(record.jerseyTitle)) {
            toast.error(`Jersey Title must not be empty`, { id: "toast" });
            return false;
        }
        return true;
    };

    // Profile Image change
    const handleImageChange = (imageRefId) => {
        setRecord({ ...record, jerseyImgUrl: imageRefId });
    };

    //Submit Avatar Form
    const handleSubmit = async () => {
        try {
            if (!checkInputValidation()) {
                return;
            }
            let recordObj = record;

            if (!recordID || recordID === "") {
                callPostApi(
                    MODAL_LOADER,
                    Add_Jersey_POST,
                    recordObj,
                    {},
                    (response) => {
                        toast.success(response.message);
                        hideModal();
                        handleRefresh();
                    }
                );
            } else {
                callPutApi(
                    MODAL_LOADER,
                    Update_Jersey_PUT.replaceAll("{recordID}", recordID),
                    recordObj,
                    {},
                    (response) => {
                        toast.success(response.message);
                        hideModal();
                        handleRefresh();
                    }
                );
            }
        } catch (error) {
            return;
        }
    };

    return (
        <ModalWrapper
            show={show}
            hide={hideModal}
            backdrop="static"
            header={`${recordID ? "Update" : "Add"} Jersey Details`}
            size="sm"
            handleSubmit={handleSubmit}
        >
            <div className="form-group row mb-3">
                <div className="col-lg-12">
                    <label>Jersey Image</label>
                    <ImageSelector
                        style={{
                            height: "120px",
                            // width: "120px",
                            overflow: "hidden",
                        }}
                        user={true}
                        handleImageChange={handleImageChange}
                        selectedImage={record.jerseyImgUrl}
                    />
                </div>
                <div className="col-lg-12 mt-2">
                    <label>Jersey Title *</label>
                    <input
                        type="text"
                        className="form-control input-default"
                        id="jerseyTitle"
                        onChange={handleTextChange}
                        value={record.jerseyTitle}
                    />
                </div>
            </div>
        </ModalWrapper>
    );
};

export default AdminJerseyAddEdit;
