import axios from "axios";
import Cookies from "universal-cookie";
import toast from "react-hot-toast";

import { MODAL_LOADER, PAGE_LOADER, SECTION_LOADER } from "utils/constants";
import { HIDE_MODAL_LOADING, HIDE_PAGE_LOADING, HIDE_SECTION_LOADING, SHOW_MODAL_LOADING, SHOW_PAGE_LOADING, SHOW_SECTION_LOADING } from "reducer/actions";
import { API_SUCCESS, UNAUTHORIZED } from "./ResponseCodes";
import { store } from "index";

let pageLoadingStack = [];
let sectionLoadingStack = [];
let modalLoadingStack = [];


export const updateURL = (url) => {
    if (url.indexOf("##HTTPS##") >= 0) {
        url = url.replace("##HTTPS##", window.location.protocol.substring(0, window.location.protocol.indexOf(":")));
    }
    if (url.indexOf("##HOST##") >= 0) {
        url = url.replace("##HOST##", window.location.hostname);
    }
    if (url.indexOf("##PORT##") >= 0) {
        // window.location.port
        url = url.replace("##PORT##", 8080);
    }
    if (url.indexOf("##CID##") >= 0) {
        // const urlParams = new URLSearchParams(window.location.search);
        // url = url.replace("##CID##", urlParams.get("cid"));
        url = url.replace("##CID##", "Eatres_Live_Frist_Client_ID");
    }
    return url;
}

const getHeaders = (headerMap) => {
    const fantasyAuthToken = new Cookies().get("fantasyAuthToken");
    if (!fantasyAuthToken) {
        return;
    }
    headerMap["Authorization"] = fantasyAuthToken;
    return headerMap;
};

const showLoader = (loadingType) => {
    if (loadingType === MODAL_LOADER) {
        modalLoadingStack.push(pageLoadingStack.length);
        store.dispatch({ type: SHOW_MODAL_LOADING });
    } else if (loadingType === SECTION_LOADER) {
        sectionLoadingStack.push(pageLoadingStack.length);
        store.dispatch({ type: SHOW_SECTION_LOADING });
    } else if (loadingType === PAGE_LOADER) {
        pageLoadingStack.push(pageLoadingStack.length);
        store.dispatch({ type: SHOW_PAGE_LOADING });
    }
};

const hideLoader = (loadingType) => {
    if (loadingType === MODAL_LOADER) {
        modalLoadingStack.pop();
        if (modalLoadingStack.length !== 0) {
            return;
        }
        store.dispatch({ type: HIDE_MODAL_LOADING });
    } else if (loadingType === SECTION_LOADER) {
        sectionLoadingStack.pop();
        if (sectionLoadingStack.length !== 0) {
            return;
        }
        store.dispatch({ type: HIDE_SECTION_LOADING });
    } else if (loadingType === PAGE_LOADER) {
        pageLoadingStack.pop();
        if (pageLoadingStack.length !== 0) {
            return;
        }
        store.dispatch({ type: HIDE_PAGE_LOADING });
    }
};

const handleSuccess = (result, successCallBack, loadingType) => {
    hideLoader(loadingType);
    if (successCallBack != null) {
        if (result.data.errorCode === UNAUTHORIZED) {
            const cookies = new Cookies();
            cookies.remove("fantasyAuthToken");
            cookies.remove("user");
            window.location.href = "/";
            return;
        }
        if (result.data.errorCode !== API_SUCCESS) {
            toast.error(result.data.message);
            return;
        }
        // NOTE > If we need to handle any other code, add here.
        successCallBack(result.data);
    }
};

const handleFailure = (error, failedCallBack, loadingType) => {
    // if (error) {
    //     const cookies = new Cookies();
    //     cookies.remove("fantasyAuthToken");
    //     cookies.remove("user");
    //     window.location.href = "/";
    //     return;
    // }
    hideLoader(loadingType);
    if (failedCallBack != null) {
        failedCallBack(error);
        return;
    }
    toast.error(error?.response?.data?.message ? error?.response?.data?.message : error.message, { id: "ApiFailure" });
};

export const callPostApiWithoutAuth = (loadingType, url, data, successCallBack, failedCallBack) => {
    url = updateURL(url);
    showLoader(loadingType);
    axios
        .post(url, data)
        .then((result) => handleSuccess(result, successCallBack, loadingType))
        .catch((error) => handleFailure(error, failedCallBack, loadingType));
};

export const callGetApi = (loadingType, url, headerMap, successCallBack, failedCallBack) => {
    url = updateURL(url);
    showLoader(loadingType);
    axios
        .get(url, {
            headers: getHeaders(headerMap),
        })
        .then((result) => handleSuccess(result, successCallBack, loadingType))
        .catch((error) => handleFailure(error, failedCallBack, loadingType));
};

export const callGetApiForExcelDownLoad = (loadingType, url, headerMap, successCallBack, failedCallBack) => {
    url = updateURL(url);
    showLoader(loadingType);
    axios
        .get(url, {
            responseType: "arraybuffer",
            headers: getHeaders(headerMap),
        })
        .then((result) => successCallBack(result.data))
        .catch((error) => handleFailure(error, failedCallBack, loadingType));
};

export const callPostApi = (loadingType, url, data, headerMap, successCallBack, failedCallBack) => {
    url = updateURL(url);
    showLoader(loadingType);
    axios
        .post(url, data, {
            headers: getHeaders(headerMap),
        })
        .then((result) => handleSuccess(result, successCallBack, loadingType))
        .catch((error) => handleFailure(error, failedCallBack, loadingType));
};

export const callPutApi = (loadingType, url, data, headerMap, successCallBack, failedCallBack) => {
    url = updateURL(url);
    showLoader(loadingType);
    axios
        .put(url, data, {
            headers: getHeaders(headerMap),
        })
        .then((result) => handleSuccess(result, successCallBack, loadingType))
        .catch((error) => handleFailure(error, failedCallBack, loadingType));
};

export const callPatchApi = (loadingType, url, data, headerMap, successCallBack, failedCallBack) => {
    url = updateURL(url);
    showLoader(loadingType);
    axios
        .patch(url, data, {
            headers: getHeaders(headerMap),
        })
        .then((result) => handleSuccess(result, successCallBack, loadingType))
        .catch((error) => handleFailure(error, failedCallBack, loadingType));
};

export const callDeleteApi = (loadingType, url, headerMap, successCallBack, failedCallBack) => {
    url = updateURL(url);
    showLoader(loadingType);
    axios
        .delete(url, {
            headers: getHeaders(headerMap),
        })
        .then((result) => handleSuccess(result, successCallBack, loadingType))
        .catch((error) => handleFailure(error, failedCallBack, loadingType));
};