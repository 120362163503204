import React from "react";
import LeftMenu from "../common/LeftMenu";
import Header from "../common/Header";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import Loader from "../common/Loader";

const Dashboard = () => {
    const { showPageLoader, showSectionLoader } = useSelector((state) => state.utility);
    return (
        <>
            {showPageLoader && <Loader />}
            <div id="layout-wrapper">
                <Header />
                <LeftMenu />
                <div className="main-content">
                    {showSectionLoader && <Loader />}
                    <div className="page-content">
                        <div className="container-fluid">
                            <Outlet />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Dashboard;
