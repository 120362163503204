import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-hot-toast";

import {
  callDeleteApi,
  callPostApi,
  callPutApi
} from "api/ApiCaller";
import {
  Upload_Image_Custom_Server_POST
} from "api/ApiConst";
import { API_SUCCESS } from "api/ResponseCodes";
import { isEmpty } from "./TextUtils";
import { MODAL_LOADER, SECTION_LOADER } from "./constants";

export const getRoleTypeString = (roleType) => {
  if (isEmpty(roleType)) {
    return "";
  }
  if (roleType === "ROLE_SUPER_ADMIN") {
    return "Super Admin";
  }
  if (roleType === "ROLE_ADMIN") {
    return "Admin";
  }
  return roleType;
};

//Action Active
export const handleActive = (url, refreshTable) => {
  callPutApi(SECTION_LOADER, url, {}, {}, (response) => {
    if (response.errorCode === API_SUCCESS) {
      toast.success(response.message);
      refreshTable();
    } else {
      toast.error(response.message);
    }
  });
};

//  Action Inactive
export const handleInactive = (url, refreshTable) => {
  callPutApi(SECTION_LOADER, url, {}, {}, (response) => {
    if (response.errorCode === API_SUCCESS) {
      toast.success(response.message);
      refreshTable();
    } else {
      toast.error(response.message);
    }
  });
};

//   Action Delete
export const handleDelete = (url, refreshTable) => {
  confirmAlert({
    title: "Alert ?",
    message: "Do you want to delete this record ?",
    icon: "warning",
    overlayClassName: "overlay-custom-class-name",
    buttons: [
      {
        label: "Yes",
        onClick: () => {
          callDeleteApi(SECTION_LOADER, url, {}, (response) => {
            if (response.errorCode === API_SUCCESS) {
              toast.success(response.message);
              refreshTable();
            } else {
              toast.error(response.message);
            }
          });
        },
      },
      {
        label: "No",
      },
    ],
  });
};

//   Action Cancel
export const handleCancel = (url, refreshTable) => {
  confirmAlert({
    title: "Alert ?",
    message: "Do you want to cancel this record ?",
    icon: "warning",
    overlayClassName: "overlay-custom-class-name",
    buttons: [
      {
        label: "Yes",
        onClick: () => {
          callPutApi(SECTION_LOADER, url, {}, {}, (response) => {
            if (response.errorCode === API_SUCCESS) {
              toast.success(response.message);
              refreshTable();
            } else {
              toast.error(response.message);
            }
          });
        },
      },
      {
        label: "No",
      },
    ],
  });
};

// Action Revive
export const handleRevive = (url, refreshTable) => {
  callPutApi(SECTION_LOADER, url, {}, {}, (response) => {
    if (response.errorCode === API_SUCCESS) {
      toast.success(response.message);
      refreshTable();
    } else {
      toast.error(response.message);
    }
  });
};

export const userNameImageObject = (userData) => {
  return {
    fullName: userData.firstName + " " + userData.lastName,
    imageUrl: userData.photoImageUrl,
  };
};

export const callImageUploader = async (base64, fileName) => {
  // const res = await fetch(base64);
  // const blob = await res.blob();
  // let formData = new FormData();
  // formData.append("file", blob, fileName);
  return callImageUploaderApi(base64, fileName);
};

const callImageUploaderApi = async (base64, fileName) => {
  const ingObj = {
    fileName: fileName,
    fileBase64: base64,
  };
  return new Promise((resolve, reject) => {
    callPostApi(
      null,
      Upload_Image_Custom_Server_POST,
      ingObj,
      {},
      (response) => {
        if (response.errorCode === API_SUCCESS) {
          resolve(response.responsePacket);
          return response.responsePacket;
        } else {
          reject(null);
          toast.error(response.message);
        }
      },
      (error) => {
        reject(null);
        toast.error(error);
        return null;
      }
    );
  });
};

export const getImageSrcViaRefId = (refId) => {
  if (refId) {
    return refId;
  }
};

export const getQRCodeImage = async (rUrl) => {
  if (rUrl) {
    return new Promise((resolve, reject) => {
      callPostApi(SECTION_LOADER, rUrl, {}, {}, (response) => {
        if (response.errorCode === API_SUCCESS) {
          resolve(response.responsePacket);
          return response.responsePacket;
          // return <img src={`data:image/png;base64, ${response.responsePacket}`} alt="QR Code" className="my-2" style={{ height: "92px", width: "auto" }} />
        } else {
          reject(null);
          toast.error(response.message);
        }
      });
    });
  }
};

// Update Order Status
export const handleUpdateOrderStatus = (url, refreshDetails) => {
  callPutApi(MODAL_LOADER, url, {}, {}, (response) => {
    if (response.errorCode === API_SUCCESS) {
      toast.success(response.message);
      refreshDetails();
    } else {
      toast.error(response.message);
    }
  });
};
