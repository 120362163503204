import React, { useState, useEffect } from "react";

import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { confirmAlert } from "react-confirm-alert";
import { useDispatch, useSelector } from "react-redux";

import {
  Admin_Change_Password_POST,
  Change_Login_User_Password_Put,
} from "api/ApiConst";
import { callPostApi, callPutApi } from "api/ApiCaller";
import { isAlphaNumercSpecialChar, isEmpty } from "utils/TextUtils";
import { LOGOUT } from "reducer/actions";
import ModalWrapper from "./ModalWrapper";
import { MODAL_LOADER } from "utils/constants";
import { API_SUCCESS } from "api/ResponseCodes";

const Header = () => {
  const defaultPasswordObj = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const { isAuthenticated, user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [changePasswordObj, setChangePasswordObj] =
    useState(defaultPasswordObj);

  // const [oldPassword, setOldPassword] = useState(false)
  // const [newPassword, setNewPassword] = useState(false)
  // const [confirmPassword, setConfirmPassword] = useState(false)

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated]);

  useEffect(() => {
    setChangePasswordObj(defaultPasswordObj);
  }, [showChangePasswordModal]);

  const handleClose = () => {
    setShowChangePasswordModal(false);
  };

  const handleToggleSidebar = () => {
    document.body.classList.toggle("sidebar-enable");
    document.body.classList.toggle("vertical-collpsed");
  };

  const handleTextChange = (e) => {
    let rObj = {
      ...changePasswordObj,
      [e.target.id]: e.target.value,
    };

    // if(!isAlphaNumercSpecialChar(value){
    //     toast.error("")
    // })
    setChangePasswordObj(rObj);
  };

  const handleLogoutConfirmation = () => {
    confirmAlert({
      title: "Alert",
      message: "Are sure you want to logout ?",
      overlayClassName: "overlay-custom-class-name",
      buttons: [
        {
          label: "Yes",
          onClick: () => dispatch({ type: LOGOUT }),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const changePasswordModal = () => {
    return (
      <ModalWrapper
        show={showChangePasswordModal}
        backdrop="static"
        hide={handleClose}
        header="Change Password"
        size="ms"
        handleSubmit={handleSubmit}
      >
        <div className="form-group row mb-3">
          <div className="col-lg-12 my-2">
            <label>Old Password *</label>
            <input
              type="password"
              maxLength={16}
              className="form-control input-default"
              id="oldPassword"
              autoComplete={false}
              value={changePasswordObj.oldPassword}
              onChange={handleTextChange}
            />
          </div>
          <div className="col-lg-6 my-2">
            <label htmlFor="newPassword">New Password *</label>
            <input
              type="password"
              maxLength={16}
              className="form-control input-default"
              id="newPassword"
              autoComplete={false}
              value={changePasswordObj.newPassword}
              onChange={handleTextChange}
            />
          </div>
          <div className="col-md-6 my-2">
            <label htmlFor="confirmPassword">Confirm Password *</label>
            <input
              type="password"
              maxLength={16}
              className="form-control"
              id="confirmPassword"
              autoComplete={false}
              value={changePasswordObj.confirmPassword}
              onChange={handleTextChange}
            />
            {changePasswordObj.newPassword !== "" &&
              changePasswordObj.confirmPassword !== "" &&
              changePasswordObj.newPassword !==
                changePasswordObj.confirmPassword && (
                <span className="text-danger font-size-12">
                  Passwords do not match
                </span>
              )}
          </div>
        </div>
      </ModalWrapper>
    );
  };

  const handleSubmit = async () => {
    let requestObj = {
      oldPassword: changePasswordObj.oldPassword,
      newPassword: changePasswordObj.newPassword,
      confirmPassword: changePasswordObj.confirmPassword,
    };
    if (isEmpty(requestObj.oldPassword)) {
      toast.error("Old Password must not be empty", { id: "toast" });
      return false;
    } else if (!isAlphaNumercSpecialChar(requestObj.oldPassword)) {
      toast.error(
        "Old Password must contain letters, numbers, or special characters.",
        { id: "toast" }
      );
      return false;
    } else if (isEmpty(requestObj.newPassword)) {
      toast.error("New Password must not be empty", { id: "toast" });
      return false;
    } else if (changePasswordObj.newPassword.length < 3) {
      toast.error("New password must be more than 2 characters", {
        id: "toast",
      });
      return false;
    } else if (!isAlphaNumercSpecialChar(requestObj.newPassword)) {
      toast.error(
        "New Password must contain letters, numbers, or special characters.",
        { id: "toast" }
      );
      return false;
    } else if (isEmpty(requestObj.confirmPassword)) {
      toast.error("Confirm Password must not be empty", { id: "toast" });
      return false;
    } else if (changePasswordObj.confirmPassword.length < 3) {
      toast.error("Confirm password must be more than 2 characters", {
        id: "toast",
      });
      return false;
    } else if (!isAlphaNumercSpecialChar(requestObj.confirmPassword)) {
      toast.error(
        "Confirm Password must contain letters, numbers, or special characters.",
        { id: "toast" }
      );
      return false;
    } else if (
      changePasswordObj.newPassword !== changePasswordObj.confirmPassword
    ) {
      toast.error("Passwords do not match", { id: "toast" });
      return false;
    }

    const toastId = toast.loading("Requesting...");
    callPutApi(
      MODAL_LOADER,
      Change_Login_User_Password_Put,
      requestObj,
      {},
      (response) => {
        if (response.errorCode === API_SUCCESS) {
          // setShowChangePasswordModal(false);
          handleClose();
          setChangePasswordObj(defaultPasswordObj);
          toast.success(response.message, { id: toastId });
          // dispatch({ type: LOGOUT });
        } else {
          toast.error(response.message, { id: toastId });
        }
      }
    );
    // callPostApi(MODAL_LOADER, Admin_Change_Password_POST, changePasswordObj, {}, (response) => {
    //     if (response.success) {
    //         setShowChangePasswordModal(false);
    //         setChangePasswordObj(defaultPasswordObj);
    //         toast.success(response.message, { id: toastId });
    //         dispatch({ type: LOGOUT });
    //     } else {
    //         toast.error(response.message, { id: toastId });
    //     }
    // });
  };

  const handleViewProfile = () => {
    if (user?.userType === "ROLE_RESTAURANT_ADMIN") {
      navigate("restaurant-profile");
    }
  };

  return (
    <header id="page-topbar">
      <div className="navbar-header">
        <div className="d-flex">
          {/* LOGO */}
          {/* <div className="navbar-brand-box">
            <a href="index.html" className="logo logo-dark">
              <span className="logo-sm">
                <img src="/assets/images/logo-sm.jpg" alt="" height={22} />
              </span>
              <span className="logo-lg">
                <img src="/assets/images/logo-lg.png" alt="" height={24} />
              </span>
            </a>
            <a href="index.html" className="logo logo-light">
              <span className="logo-sm">
                <img src="/assets/images/logo-sm.jpg" alt="" height={22} />
              </span>
              <span className="logo-lg">
                <img src="/assets/images/logo-lg.png" alt="" height={24} />
              </span>
            </a>
          </div> */}
          <button
            type="button"
            className="btn btn-sm px-3 font-size-16 header-item waves-effect"
            id="vertical-menu-btn"
            onClick={handleToggleSidebar}
          >
            <i className="fa fa-fw fa-bars" />
          </button>
        </div>
        <div className="d-flex">
          <div className="dropdown d-inline-block d-lg-none ms-2">
            <button
              type="button"
              className="btn header-item noti-icon waves-effect"
              id="page-header-search-dropdown"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="mdi mdi-magnify" />
            </button>
            <div
              className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
              aria-labelledby="page-header-search-dropdown"
            >
              <form className="p-3">
                <div className="form-group m-0">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search ..."
                      aria-label="Recipient's username"
                    />
                    <div className="input-group-append">
                      <button className="btn btn-primary" type="submit">
                        <i className="mdi mdi-magnify" />
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="dropdown d-inline-block">
            <button
              type="button"
              className="btn header-item waves-effect"
              id="page-header-user-dropdown"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img
                className="rounded-circle header-profile-user"
                src="/assets/images/users/avatar-1.jpg"
                alt="Header Avatar"
              />
              <span className="d-none d-xl-inline-block ms-1" key="t-henry">
                {user?.fullName}
              </span>
              <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
            </button>
            <div className="dropdown-menu dropdown-menu-end">
              {/* item*/}
              <button
                className="dropdown-item py-2"
                onClick={handleViewProfile}
              >
                <i className="bx bx-user font-size-16 align-middle me-1" />
                <span key="t-profile">Profile</span>
              </button>
              <a className="dropdown-item py-2" href="#">
                <i className="bx bx-wallet font-size-16 align-middle me-1" />
                <span key="t-my-wallet">My Wallet</span>
              </a>
              <button
                className="dropdown-item py-2"
                onClick={() => setShowChangePasswordModal(true)}
              >
                <i className="bx bx-key font-size-16 align-middle me-1" />
                <span key="t-my-password">Change Passowrd</span>
              </button>
              <div className="dropdown-divider m-0" />
              <button
                className="dropdown-item text-danger py-2"
                onClick={handleLogoutConfirmation}
              >
                <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
                <span key="t-logout">Logout</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      {changePasswordModal()}
    </header>
  );
};

export default Header;
