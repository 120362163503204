import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";

import { callGetApi, callPostApi, callPutApi } from "api/ApiCaller";
import { Add_Country_POST, Get_Country_Details_GET, Update_Country_PUT, } from "api/ApiConst";

import { isEmpty } from "utils/TextUtils";
import { MODAL_LOADER } from "utils/constants";
import ModalWrapper from "components/common/ModalWrapper";

const AdminCountryAddEdit = ({ show, hideModal, handleRefresh, recordID }) => {
    const defaultRecordObj = {
        countryName: "",
        shortCode: "",
    };

    const [record, setRecord] = useState(defaultRecordObj);

    useEffect(() => {
        if (recordID) {
            callGetApi(
                MODAL_LOADER,
                Get_Country_Details_GET.replaceAll("{recordID}", recordID),
                {},
                (response) => {
                    const res = response.responsePacket;
                    setRecord(res);
                }
            );
        }
    }, [recordID]);

    //Text Change Event
    const handleTextChange = (e) => {
        setRecord((prev) => ({ ...prev, [e.target.id]: e.target.value }));
    };

    const checkInputValidation = () => {
        if (isEmpty(record.countryName)) {
            toast.error(`Country name must not be empty.`, {
                id: "toast",
            });
            return false;
        }
        return true;
    };

    //Submit Avatar Form
    const handleSubmit = async () => {
        try {
            if (!checkInputValidation()) {
                return;
            }
            let recordObj = record;
            if (!recordID || recordID === "") {
                callPostApi(
                    MODAL_LOADER,
                    Add_Country_POST,
                    recordObj,
                    {},
                    (response) => {
                        toast.success(response.message);
                        hideModal();
                        handleRefresh();
                    }
                );
            } else {
                callPutApi(
                    MODAL_LOADER,
                    Update_Country_PUT.replaceAll("{recordID}", recordID),
                    recordObj,
                    {},
                    (response) => {
                        toast.success(response.message);
                        hideModal();
                        handleRefresh();
                    }
                );
            }
        } catch (error) {
            return;
        }
    };

    return (
        <ModalWrapper
            show={show}
            hide={hideModal}
            backdrop="static"
            header={`${recordID ? "Update" : "Add"} Country Details`}
            size="sm"
            handleSubmit={handleSubmit}
        >
            <div className="form-group row mb-3">
                <div className="col-lg-12">
                    <label>Country Name *</label>
                    <input
                        type="text"
                        className="form-control input-default"
                        id="countryName"
                        onChange={(e) => handleTextChange(e)}
                        value={record.countryName}
                    />
                </div>
            </div>
        </ModalWrapper>
    );
};
export default AdminCountryAddEdit;
