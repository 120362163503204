import React, { useEffect, useState } from "react";
import Select from "react-select";
import toast from "react-hot-toast";

import { callGetApi, callPostApi, callPutApi } from "api/ApiCaller";
import {
    Add_Cricket_Player_POST,
    Get_Cricket_Player_Details_GET,
    Update_Cricket_Player_PUT,
} from "api/ApiConst";

import { isEmpty } from "utils/TextUtils";
import { MODAL_LOADER } from "utils/constants";
import ModalWrapper from "components/common/ModalWrapper";

const AdminCricketPlayerAddEdit = ({ show, hideModal, handleRefresh, recordID, countryList, }) => {
    const defaultRecordObj = {
        playerName: "",
        countryUuid: "",
    };

    const [record, setRecord] = useState(defaultRecordObj);
    const [selectedCountry, setSelectedCountry] = useState({});

    useEffect(() => {
        if (recordID) {
            callGetApi(
                MODAL_LOADER,
                Get_Cricket_Player_Details_GET.replaceAll("{recordID}", recordID),
                {},
                (response) => {
                    const res = response.responsePacket;
                    setRecord(res);
                    setSelectedCountry(
                        countryList.find((data) => data.value === res.countryUuid)
                    );
                }
            );
        }
    }, [recordID]);

    //Text Change Event
    const handleTextChange = (e) => {
        setRecord((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    const checkInputValidation = () => {
        if (isEmpty(record.playerName)) {
            toast.error(`Player Name must not be empty`, { id: "toast" });
            return false;
        } else if (isEmpty(record.countryUuid)) {
            toast.error(`Select Player Country must not be empty`, { id: "toast" });
            return false;
        }
        return true;
    };

    //Submit Avatar Form
    const handleSubmit = async () => {
        try {
            if (!checkInputValidation()) {
                return;
            }
            let recordObj = record;

            if (!recordID || recordID === "") {
                callPostApi(
                    MODAL_LOADER,
                    Add_Cricket_Player_POST,
                    recordObj,
                    {},
                    (response) => {
                        toast.success(response.message);
                        hideModal();
                        handleRefresh();
                    }
                );
            } else {
                callPutApi(
                    MODAL_LOADER,
                    Update_Cricket_Player_PUT.replaceAll("{recordID}", recordID),
                    recordObj,
                    {},
                    (response) => {
                        toast.success(response.message);
                        hideModal();
                        handleRefresh();
                    }
                );
            }
        } catch (error) {
            return;
        }
    };

    const handleImageChange = (imageRefId) => {
        setRecord({ ...record, playerImgUrl: imageRefId });
    };

    const handleSelect = (e) => {
        setRecord({ ...record, countryUuid: e.value });
        setSelectedCountry({
            label: e.label,
            value: e.value,
        });
    };

    return (
        <ModalWrapper
            show={show}
            hide={hideModal}
            backdrop="static"
            header={`${recordID ? "Update" : "Add"} Cricket Player Details`}
            size="sm"
            handleSubmit={handleSubmit}
        >
            <div className="form-group row">
                <div className="col-lg-12 mb-3">
                    <label>Select Player Country*</label>
                    <Select
                        value={selectedCountry}
                        options={countryList}
                        onChange={handleSelect}
                    />
                </div>
                <div className="col-lg-12 mb-3">
                    <label>Player Name*</label>
                    <input
                        type="text"
                        className="form-control input-default"
                        id="playerName"
                        name="playerName"
                        onChange={handleTextChange}
                        value={record.playerName}
                    />
                </div>
            </div>
        </ModalWrapper>
    );
};

export default AdminCricketPlayerAddEdit;
