import React, { useCallback, useEffect, useMemo, useState } from "react";

import { handleActive, handleDelete, handleInactive, handleRevive, } from "utils/UserUtils";

import {
    Activate_Country_PUT,
    All_Country_List_POST,
    DeActivate_Country_PUT,
    Delete_Country_DELETE,
    Deleted_Country_List_POST,
    Revive_Country_PUT,
} from "api/ApiConst";

import dateUtilities from "utils/DateUtilities";
import PageHeading from "components/common/PageHeading";
import CustomButton from "components/common/CustomButton";
import PaginationDataTable from "components/common/PaginationDataTable";
import CheckboxButton from "components/common/CheckboxButton";

import AdminCountryAddEdit from "./AdminCountryAddEdit";

const AdminCountryMaster = () => {
    const [refreshFlag, setRefreshFlag] = useState(0);
    const [viewDeletedList, setViewDeletedList] = useState(false);
    const [recordID, setRecordID] = useState(null);
    const [showAddUpdateModal, setShowAddUpdateModal] = useState(false);

    useEffect(() => {
        if (!showAddUpdateModal) {
            setRecordID(null);
        }
    }, [showAddUpdateModal]);

    const handleRefresh = useCallback(() => {
        setRefreshFlag(refreshFlag + 1);
    }, [refreshFlag]);

    const columns = useMemo(
        () => [
            {
                id: "status",
                name: "",
                selector: (row) => (
                    <div
                        className={row.active ? "led-green" : "led-red"}
                        title={row.active ? "Active" : "Inactive"}
                    />
                ),
                width: "60px",
                sortable: false,
            },
            // {
            //   id: "image",
            //   selector: (row) => (
            //     <div className="profile-img">
            //       <img src={getImageSrcViaRefId(row.flagImgRefId)} />
            //     </div>
            //   ),
            //   width: "100px",
            //   sortable: false,
            // },
            {
                id: "countryName",
                name: "Country Name",
                selector: (row) => row.countryName,
                sortable: false,
                wrap: 2,
            },
            {
                id: "createdAt",
                name: "Created At",
                selector: (row) =>
                    dateUtilities.formattedDate(
                        row.createdAtTimeStamp,
                        "dd-MM-yyyy, hh:mm aa"
                    ),
                width: "200px",
                sortable: false,
            },
            {
                id: "action",
                name: "",
                selector: (row) => (
                    <div className="custom-column-action">{getActionButtons(row)}</div>
                ),
                width: "100px",
                sortable: false,
            },
        ],
        [handleRefresh]
    );

    const getActionButtons = (row) => {
        return (
            <div className="d-flex align-items-center justify-content-between">
                {!row.deleted && (
                    <button className="listBtn me-2" onClick={() => handleEdit(row)}>
                        <i className="bx bx-pencil"></i>
                    </button>
                )}
                <div className="dropdown">
                    <button
                        className="btn btn-primary btn-flat btn-addon btn-xs dropdown-toggle"
                        title="More"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{ padding: "5px 10px 8px 10px" }}
                    >
                        <i className="bx bx-chevron-down"></i>
                    </button>
                    <ul className="dropdown-menu dropdown-menu-right">
                        <>
                            {row.deleted ? (
                                <li onClick={() => handleReviveData(row)}>
                                    <i className="bx bx-log-out-circle"></i> Revive
                                </li>
                            ) : (
                                <>
                                    <li onClick={() => handleDeleteData(row)}>
                                        <i className="bx bx-trash"></i> Delete
                                    </li>
                                    <li onClick={() => handleActiveInactive(row)}>
                                        {row.active ? (
                                            <>
                                                <i className="bx bx-x-circle"></i> Inactive
                                            </>
                                        ) : (
                                            <>
                                                <i className="bx bx-check-circle"></i> Active
                                            </>
                                        )}
                                    </li>
                                </>
                            )}
                        </>
                    </ul>
                </div>
            </div>
        );
    };

    const handleEdit = (row) => {
        setRecordID(row.uuid);
        setShowAddUpdateModal(true);
    };

    const handleActiveInactive = (row) => {
        if (row.active) {
            handleInactive(
                DeActivate_Country_PUT.replaceAll("{recordID}", row.uuid),
                handleRefresh
            );
        } else {
            handleActive(
                Activate_Country_PUT.replaceAll("{recordID}", row.uuid),
                handleRefresh
            );
        }
    };

    //Revive Record
    const handleReviveData = (row) => {
        handleRevive(
            Revive_Country_PUT.replaceAll("{recordID}", row.uuid),
            handleRefresh
        );
    };
    
    //Delete Record
    const handleDeleteData = (row) => {
        handleDelete(
            Delete_Country_DELETE.replaceAll("{recordID}", row.uuid),
            handleRefresh
        );
    };
    const handleToggleDeletedView = () => setViewDeletedList(!viewDeletedList);

    return (
        <>
            {PageHeading("Country List", "Dashboard", "/")}
            <div className="card">
                <CustomButton
                    handleClick={() => setShowAddUpdateModal(true)}
                    title="Create New"
                    iconClass="bx bx-plus"
                    buttonClass="createButton"
                />
                <div className="card-body tableView">
                    <PaginationDataTable
                        paginationUrl={
                            viewDeletedList
                                ? Deleted_Country_List_POST
                                : All_Country_List_POST
                        }
                        serverSidePagination={true}
                        search={true}
                        pagination={false}
                        columns={columns}
                    />
                    <CheckboxButton
                        title="Deleted List"
                        handleClick={handleToggleDeletedView}
                        additionalClass="deletedBtn ml-auto"
                    />
                </div>
            </div>
            {showAddUpdateModal && (
                <AdminCountryAddEdit
                    show={showAddUpdateModal}
                    hideModal={() => setShowAddUpdateModal(false)}
                    recordID={recordID}
                    handleRefresh={handleRefresh}
                />
            )}
        </>
    );
};

export default AdminCountryMaster;
