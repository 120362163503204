import React, { useCallback, useEffect, useMemo, useState } from "react";
import Select from "react-select";
import toast from "react-hot-toast";

import {
    All_History_Contest_List_POST,
    Deleted_Country_List_POST,
    Get_Client_key_value_list_POST,
    Get_Cricket_Match_key_value_list_POST,
} from "api/ApiConst";
import { callPostApi } from "api/ApiCaller";

import { MODAL_LOADER } from "utils/constants";
import { useNavigate } from "react-router-dom";
import { API_SUCCESS } from "api/ResponseCodes";
import dateUtilities from "utils/DateUtilities";
import PageHeading from "components/common/PageHeading";
import CustomButton from "components/common/CustomButton";
import CheckboxButton from "components/common/CheckboxButton";
import PaginationDataTable from "components/common/PaginationDataTable";
import { useSelector } from "react-redux";

const customStyles = {
    control: (provided) => ({
        ...provided,
        width: 135, // Set the desired width
        padding: 2
    }),
};

const defaultRecordObj = {
    clientId: 0,
    cricketMatchId: 0,
    startDateTimeStamp: 0,
    endDateTimeStamp: 0
}
const AdminContestHistoryMaster = () => {
    const navigate = useNavigate();
    const [record, setRecord] = useState(defaultRecordObj);
    const [refreshFlag, setRefreshFlag] = useState(0);
    const [viewDeletedList, setViewDeletedList] = useState(false);
    const [recordID, setRecordID] = useState(null);
    const [showAddUpdateModal, setShowAddUpdateModal] = useState(false);
    const [selectedContestTypeOption, setSelectedContestTypeOption] = useState([{ label: "All", value: "All" }]);
    const [clientList, setClientList] = useState([]);
    const [selectedClientList, setSelectedClientList] = useState([{ label: "All Client", value: "All", key: 0 }]);
    const [matchList, setMatchList] = useState([]);
    const [selectedMatchList, setSelectedMatchList] = useState([{ label: "All Match", value: "All", key: 0 }]);

    useEffect(() => {
        if (!showAddUpdateModal) {
            setRecordID(null);
        }
    }, [showAddUpdateModal]);

    useEffect(() => {
        getMatchList();
        getClientList();

    }, []);

    const getMatchList = () => {
        callPostApi("Loader", Get_Cricket_Match_key_value_list_POST, {}, {}, (res) => {
            setMatchList(res.responsePacket);
        });
    };
    const getClientList = () => {
        callPostApi("Loader", Get_Client_key_value_list_POST, {}, {}, (res) => {
            setClientList(res.responsePacket);
        });
    };

    const handleRefresh = useCallback(() => {
        setRefreshFlag(refreshFlag + 1);
    }, [refreshFlag]);

    const columns = useMemo(
        () => [
            {
                id: "status",
                name: "",
                selector: (row) => (
                    <div
                        className={row.active ? "led-green" : "led-red"}
                        title={row.active ? "Active" : "Inactive"} />
                ),
                width: "60px",
                sortable: false,
            },
            {
                id: "clientName",
                name: "Client",
                selector: (row) => row?.clientName,
                sortable: false,
                wrap: true,
                width: "180px",

                // center: true,
            },
            {
                id: "cricketMatchTitle",
                name: "Match Title",
                selector: (row) => row?.cricketMatchTitle,
                sortable: false,
                wrap: true,
            },
            {
                id: "participant",
                name: "Participant",
                // TODO : Replace "10" with joinCount variable here
                selector: (row) => {
                    return <>
                        {
                            row.totalParticipation
                        } Out Of {
                            row.minimumNumberOfParticipantsRequired + "/" + row.maximumNumberOfParticipants
                        } <br />
                        {
                            row.prizePoolTypeEnum === "PlayerPool" ? (
                                <div>Player Pool ( {row.totalParticipation * row.entryFee} )</div>
                            ) : (
                                <div>Guaranteed Pool ( {row.prizePool} )</div>
                            )
                        }
                    </>
                },
                sortable: false,
                wrap: true,
                right: false,
                width: "200px",
            },
            {
                id: "startDateTimeStamp",
                name: "Publish / Start",
                selector: (row) => {
                    return <>
                        {
                            dateUtilities.formattedDate(
                                row.publishDateTimeStamp,
                                "dd-MM-yyyy, hh:mm aa"
                            )
                        }<br />{
                            dateUtilities.formattedDate(
                                row.startDateTimeStamp,
                                "dd-MM-yyyy, hh:mm aa"
                            )
                        }
                    </>
                },
                sortable: false,
                wrap: true,
                width: "200px",
                // center: true,

            },
            {
                id: "createdAt",
                name: "Created At",
                selector: (row) => dateUtilities.formattedDate(
                    row.createdAtTimeStamp,
                    "dd-MM-yyyy, hh:mm aa"
                ),
                width: "200px",
                sortable: false,

            },
            {
                id: "action",
                name: "",
                selector: (row) => (
                    <div className="custom-column-action">{getActionButtons(row)}</div>
                ),
                width: "70px",
                sortable: false,
            },
        ],
        [handleRefresh]
    );

    const getActionButtons = (row) => {
        return (
            <div className="d-flex align-items-center justify-content-between">
                {!row.deleted && (
                    <button className="listBtn me-2" onClick={() =>
                        navigate(`/admin-contest-details/${row.uuid}/history`)} >
                        <i className="mdi mdi-eye-outline"></i>
                    </button>
                )}
            </div>
        );
    };

    const handleToggleDeletedView = () => setViewDeletedList(!viewDeletedList);

    const handleSelectMatchList = (e) => {
        setRecord({ ...record, cricketMatchId: e.key });
        setSelectedMatchList({
            label: e.label,
            value: e.value,
            ...e,
        });
    };
    const handleSelectClientList = (e) => {
        setRecord({ ...record, clientId: e.key });
        setSelectedClientList({
            label: e.label,
            value: e.value,
            ...e,
        });
    };

    const handleGetList = () => {
        let rObj = { ...record }
        let rUrl = All_History_Contest_List_POST;

        callPostApi(MODAL_LOADER, rUrl, rObj, {}, (response) => {
            if (response.errorCode === API_SUCCESS) {

                toast.success(response.message, { id: "toast" });
                handleRefresh();
                // setRecord(response.responsePacket);
            } else {
                toast.error(response.message, { id: "toast" });
            }
        });
    }

    return (
        <>
            {PageHeading("Contest History List", "Dashboard", "/")}
            <div className="card">
                <div className="card-body tableView d-flex" style={{ zIndex: 1 }}>
                    <div className="d-flex flex-grow-1">
                        <Select
                            className="ms-1"
                            maxMenuHeight={150}
                            styles={customStyles}
                            options={[{ label: "All Client", value: "All", key: 0 }, ...clientList]}
                            value={selectedClientList}
                            onChange={handleSelectClientList}
                        />
                        <Select
                            className="ms-1"
                            maxMenuHeight={150}
                            styles={customStyles}
                            options={[{ label: "All Match", value: "All", key: 0 }, ...matchList]}
                            value={selectedMatchList}
                            onChange={handleSelectMatchList}
                        />
                        <CustomButton
                            title="Get List"
                            buttonClass="bg-success ms-2 px-4 align-items-center justify-content-center "
                            handleClick={handleGetList}
                        />
                    </div>
                    <div className="ms-auto d-flex">
                        <CheckboxButton
                            title="Deleted List"
                            handleClick={handleToggleDeletedView}
                            additionalClass="me-1 contest-btn"
                        />
                    </div>
                </div>
                <div className="card-body tableView" style={{ top: "-77px" }}>
                    <PaginationDataTable
                        paginationUrl={
                            viewDeletedList
                                ? Deleted_Country_List_POST
                                :
                                All_History_Contest_List_POST
                        }
                        serverSidePagination={true}
                        search={true}
                        pagination={false}
                        columns={columns}
                        filter={{ ...record }}
                    />
                </div>
            </div>
        </>
    );
};

export default AdminContestHistoryMaster;
