import dateUtilities from "./DateUtilities";

export const isEmpty = (val) => {
    if (!val || val === null || val === undefined || val === "" || val === 0) {
        return true;
    }
    return false;
};

export const isBlank = (val) => {
    if (!val || val === null || val === undefined || val === "") {
        return true;
    }
    return false;
};

export const isNumeric = (str) => {
    if (typeof str != "string") {
        return false; // we only process strings!
    }
    return (
        !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str))
    ); // ...and ensure strings of whitespace fail
};
export const isNumericPositive = (str) => {
    if (typeof str !== "string") {
        return false; // we only process strings!
    }

    // Ensure the string is not empty
    if (str.trim() === "") {
        return false;
    }

    // Use regex to check for a valid positive number
    const numericRegex = /^[+]?\d*\.?\d+$/;
    return numericRegex.test(str);
};

export const getImageSrc = (imgSrc) => {
    if (this.isEmpty(imgSrc)) {
        // if imgSrc is empty then set default image.
        return "/assets/images/001/user.png";
    }
    return imgSrc;
};

export const isChar = (char) => {
    const regEx = /^[a-zA-Z]+$/;
    return regEx.test(char);
};
export const isAlphaNumeric = (Alphachar) => {
    const regEx = /[a-zA-Z0-9]+$/;
    return regEx.test(Alphachar);
};
export const isAlphaNumericSpc = (Alphachar) => {
    const regEx = /[a-zA-Z0-9 ]+$/;
    return regEx.test(Alphachar);
};

export const isAlphaNumercSpecialChar = (char) => {
    const regEx = /^[A-Za-z0-9_!#$%^&*~`@.-]+$/;
    return regEx.test(char);
};
export const isAlphaNumercSpecialCharSpc = (char) => {
    const regEx = /^[A-Za-z0-9_ !#$%^&*~`@.-]+$/;
    return regEx.test(char);
};
export const isMobile = (mobile) => {
    const regEx = /^[5-9][0-9]{7,9}$/;
    return regEx.test(mobile);
};
// export const isValidGST = (value) => {
//     const regEx = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}[Z]{1}[0-9]{1}$/
//     return regEx.test(value);
// }
export const isEmail = (email) => {
    const regEx =
        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return regEx.test(email);
};

export const isUpiId = (upiId) => {
    const regEx = /[a-zA-Z0-9.\\-_]{2,256}@[a-zA-Z]{2,64}/;
    return regEx.test(upiId);
};

export const getDOB = (value) => {
    if (typeof value === "number" || value.toString().split("") === 1) {
        return dateUtilities.formattedDate(value, "yyyy-MM-dd");
    }
    return value;
};

export const getTimeFromDate = (value) => {
    return new Date(value).getTime();
};

export const isValidIsdCode = (isdCode) => {
    const regEx = /^\+\d{1,3}$/;
    return regEx.test(isdCode);
};

export const isValidJsonString = (jsonString) => {
    try {
        JSON.parse(jsonString);
    } catch (e) {
        return false;
    }
    return true;
};

// capitalize Text Value
export const capitalizeText = (val) => {
    return val.charAt(0).toUpperCase() + val.slice(1).toLowerCase(); // capitalize day name
};

// Get Ordered Customer Full Name With Contact Number (FirstName|LastName|IsdCode|ContactNumber)
export const getOrderedCustomerDetails = (row) => {
    let fullName = "";
    let contactNo = "";
    if (row?.userCustomerFirstName) {
        fullName = fullName + row?.userCustomerFirstName;
    }

    if (row?.userCustomerLastName) {
        fullName = fullName + " " + row?.userCustomerLastName;
    }
    if (row?.userCustomerIsdCode) {
        contactNo = contactNo + row?.userCustomerIsdCode;
    }
    if (row?.userCustomerMobile) {
        contactNo = contactNo + row?.userCustomerMobile;
    }

    if (isEmpty(fullName) && isEmpty(contactNo)) {
        return "NA";
    } else if (isEmpty(fullName)) {
        return contactNo;
    } else if (isEmpty(contactNo)) {
        return fullName;
    } else {
        return fullName + "(" + contactNo + ")";
    }
};
