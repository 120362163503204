import { callPostApi } from "api/ApiCaller";
import { POST_ADD_RECOMMENDED_TEAM } from "api/ApiConst";
import ModalWrapper from "components/common/ModalWrapper";
import { BarChart, LightningIcon, OneCoin } from "components/common/Svgicon";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { MODAL_LOADER } from "utils/constants";

const RecommendedTeamAddEdit = ({
  show,
  hideModal,
  teamDetails,
  recommendedTeams,
  watchId,
  submitTeam,
}) => {
  const [totalPlayersList, setTotalPlayersList] = useState([
    ...teamDetails.team1.players,
    ...teamDetails.team2.players,
  ]);
  const [creditsLeft, setCreditsLeft] = useState(2100);
  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const [teamTitle, setTeamTitle] = useState("");

  const handleSelectPlayer = (e, player) => {
    e.stopPropagation();
    const { cricketPlayerId, coins } = player;
    // let { usedCoins } = { ...contestDetail };
    const _selectedPlayers = [...selectedPlayers];
    const index = _selectedPlayers.indexOf(cricketPlayerId);
    if (_selectedPlayers.length >= 5 && index === -1) {
      toast.error(`You can select only ${5} players`, { id: "Error" });
      return;
    }

    if (index !== -1) {
      setCreditsLeft((prev) => (prev += coins));
      // creditsLeft += coins;
      _selectedPlayers.splice(index, 1);
    } else {
      if (creditsLeft >= 0 && creditsLeft - coins >= 0) {
        setCreditsLeft((prev) => (prev -= coins));
        // creditsLeft -= coins;
        _selectedPlayers.push(cricketPlayerId);
      } else {
        toast.error(`Sorry, make ${5} player team within ${2100} credits!`, {
          id: "Error",
        });
      }
    }

    // const _filteredPlayersList = filteredPlayersList.filter((player) => _selectedPlayersList.includes(player.cricketPlayerId));
    // const { teamsScorePart } = getTeamsScorePart(_filteredPlayersList);
    // setContestDetail((prev) => ({ ...prev, usedCoins, teamsScorePart }));
    setSelectedPlayers(_selectedPlayers);
  };

  const handleTeamName = (e) => {
    const { value } = e.target;
    if (value.length <= 25) {
      setTeamTitle(value);
    }
  };

  const handleSubmit = () => {
    if (teamTitle === "") {
      toast.error("Please enter a team title!!!");
      return;
    } else if (teamTitle.length > 25) {
      toast.error("Only 25 character team name is valid!!!");
      return;
    } else if (selectedPlayers.length !== 5) {
      toast.error("Please select 5 players to move forward!!!");
      return;
    }
    const payload = {
      teamTitle,
      cricketPlayerIds: selectedPlayers,
    };
    callPostApi(
      MODAL_LOADER,
      POST_ADD_RECOMMENDED_TEAM.replaceAll("{cricketMatchId}", watchId),
      payload,
      {},
      (response) => {
        if (response.errorCode === 0) {
          toast.success(response.message, { id: "Error" });
          submitTeam();
        } else {
          toast.error(response.message, { id: "Error" });
        }
      }
    );
  };

  const showPlayerCard = (player) => {
    const getHightestPart = () => {
      const scoreParts = {
        P1: player.scorePart1,
        P2: player.scorePart2,
        P3: player.scorePart3,
        P4: player.scorePart4,
        P5: player.scorePart5,
        P6: player.scorePart6,
        P7: player.scorePart7,
        P8: player.scorePart8,
      };
      let greatestNumber = Math.max(...Object.values(scoreParts));
      return Object.keys(scoreParts).find(
        (key) => scoreParts[key] === greatestNumber
      );
    };
    return (
      <div className="player_tails border border-black rounded-2 ">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <div className="custom_checkbox me-2">
              <input
                name="group1"
                type="checkbox"
                className="form-check-input"
                onChange={(e) => handleSelectPlayer(e, player)}
                checked={selectedPlayers?.includes(player.cricketPlayerId)}
                disabled={!!!handleSelectPlayer}
              />
            </div>
            <div className="d-flex align-items-center ">
              <div className="fs-6 fw-500 text-black me-2">
                {player.playerName}
              </div>
              <div className="fs-12 fw-500 text-gray">
                <span className="me-1">
                  <OneCoin />
                </span>
                {player.coins}
              </div>
            </div>
          </div>

          <div className="d-flex align-items-center ">
            <div className="d-flex align-items-center me-2">
              <span className="me-1">
                <LightningIcon />
              </span>
              <span className="text-gray-70 fs-12 fw-400">
                {getHightestPart()}
              </span>
            </div>
            <div className="d-flex align-items-center">
              <div className="playground playground_red">
                <span>
                  <BarChart />
                </span>
                <span>{player.totalScore}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="selected_scoring mt-3 px-0">
          <li>
            <span className="fs-12 fw-400 d-block opacity-75 text-gray">
              P1
            </span>
            <span className={`d-block fs-12 fw-400 p-1 rounded-1 ${player.scorePart1 === 0 ? "playground_red" : "playground_green"}`}>
              {player.scorePart1}
            </span>
          </li>
          <li>
            <span className="fs-12 fw-400 d-block opacity-75 text-gray">
              P2
            </span>
            <span className={`d-block fs-12 fw-400 p-1 rounded-1  ${player.scorePart2 === 0 ? "playground_red" : "playground_green"}`}>
              {player.scorePart2}
            </span>
          </li>
          <li>
            <span className="fs-12 fw-400 d-block opacity-75 text-gray">
              P3
            </span>
            <span className={`d-block fs-12 fw-400 p-1 rounded-1  ${player.scorePart3 === 0 ? "playground_red" : "playground_green"}`}>
              {player.scorePart3}
            </span>
          </li>
          <li>
            <span className="fs-12 fw-400 d-block opacity-75 text-gray">
              P4
            </span>
            <span className={`d-block fs-12 fw-400 p-1 rounded-1  ${player.scorePart4 === 0 ? "playground_red" : "playground_green"}`}>
              {player.scorePart4}
            </span>
          </li>
          <li>
            <span className="fs-12 fw-400 d-block opacity-75 text-gray">
              P5
            </span>
            <span className={`d-block fs-12 fw-400 p-1 rounded-1  ${player.scorePart5 === 0 ? "playground_red" : "playground_green"}`}>
              {player.scorePart5}
            </span>
          </li>
          <li>
            <span className="fs-12 fw-400 d-block opacity-75 text-gray">
              P6
            </span>
            <span className={`d-block fs-12 fw-400 p-1 rounded-1  ${player.scorePart6 === 0 ? "playground_red" : "playground_green"}`}>
              {player.scorePart6}
            </span>
          </li>
          <li>
            <span className="fs-12 fw-400 d-block opacity-75 text-gray">
              P7
            </span>
            <span className={`d-block fs-12 fw-400 p-1 rounded-1  ${player.scorePart7 === 0 ? "playground_red" : "playground_green"}`}>
              {player.scorePart7}
            </span>
          </li>
          <li>
            <span className="fs-12 fw-400 d-block opacity-75 text-gray">
              P8
            </span>
            <span className={`d-block fs-12 fw-400 p-1 rounded-1  ${player.scorePart8 === 0 ? "playground_red" : "playground_green"}`}>
              {player.scorePart8}
            </span>
          </li>
        </div>
      </div>
    );
  };

  return (
    <ModalWrapper
      show={show}
      hide={hideModal}
      handleSubmit={handleSubmit}
      header="Add Recommended Team"
    >
      <div className="col-lg-7 col-md-8 col-12">
        <div className="d-flex align-items-center ">
          <label className="text-black me-3" style={{whiteSpace: 'pre'}}>Team Name:</label>
          <input
            className="form-control rounded"
            placeholder="Name"
            type="text"
            value={teamTitle}
            onChange={handleTeamName}
          />
        </div>
      </div>
      <div className="row recommend-team-scroll mt-3">
        {totalPlayersList.map((player) => (
          <div className="col-lg-6 mt-4">{showPlayerCard(player)}</div>
        ))}
      </div>
      <div className="d-flex gap-4 position-absolute" style={{bottom: '-40px', left: '20px'}}>
        <div className="fw-bold">Credit Left: <span className="fw-light">{creditsLeft}</span></div>
        <div className="fw-bold">Players: <span className="fw-light">{selectedPlayers.length}/5</span></div>
      </div>
    </ModalWrapper>
  );
};

export default RecommendedTeamAddEdit;
