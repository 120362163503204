import { callGetApi, callPostApi } from 'api/ApiCaller';
import { Add_log_apiCallerList_Post, Add_log_transactionStatusList_Post, Get_ContestDetails_Details_GET } from 'api/ApiConst';
import { API_SUCCESS } from 'api/ResponseCodes';
import PageHeading from 'components/common/PageHeading';
import { data, event } from 'jquery';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { SECTION_LOADER } from 'utils/constants';
import dateUtilities from "utils/DateUtilities";
import JsonView from "react-json-view";

const AdminContestDetails = () => {
    const [record, setRecord] = useState();
    const [showPlayerDetails, setShowPlayerDetails] = useState(false);
    const [showContestTeamPlayerDetails, setShowContestPlayerDetails] = useState(false);
    const [transcationList, setTransactionList] = useState([]);
    const [apiCallerList, setApiCallerList] = useState([]);
    const [activeTab, setActiveTab] = useState("contestTeamDetails");
    const [expandedRows, setExpandedRows] = useState(new Set());

    const handleTabClick = (tab) => {
        setActiveTab(tab);
        if (tab === "apiCalls" && contestId) {
            callPostApi(SECTION_LOADER, Add_log_apiCallerList_Post.replaceAll("{contestId}", contestId), {}, {},
                (response) => {
                    if (response.errorCode === API_SUCCESS) {
                        const res = response.responsePacket;
                        setApiCallerList(res);
                    }
                }
            );
        }

        if (tab === "transactions" && contestId) {
            callPostApi(SECTION_LOADER, Add_log_transactionStatusList_Post.replaceAll("{contestId}", contestId), {}, {},
                (response) => {
                    if (response.errorCode === API_SUCCESS) {
                        const res = response.responsePacket;
                        setTransactionList(res);
                    }
                }
            );
        }

        if (tab === "contestTeamDetails" && contestId) {
            if (contestId) {
                if (flag === "live") {
                    callGetApi(SECTION_LOADER, Get_ContestDetails_Details_GET.replaceAll("{contestUuid}", contestId), {},
                        (response) => {
                            if (response.errorCode === API_SUCCESS) {
                                const res = response.responsePacket;
                                setRecord({ ...res });
                            }
                        }
                    );
                }
                if (flag === "history") {
                    callGetApi(SECTION_LOADER, Get_ContestDetails_Details_GET.replaceAll("{contestUuid}", contestId), {},
                        (response) => {
                            if (response.errorCode === API_SUCCESS) {
                                const res = response.responsePacket;
                                setRecord({ ...res });
                            }
                        }
                    );
                }
            }
        }
    };

    const { contestId, flag } = useParams();

    useEffect(() => {
        if (contestId) {
            if (flag === "live") {
                callGetApi(SECTION_LOADER, Get_ContestDetails_Details_GET.replaceAll("{contestUuid}", contestId), {},
                    (response) => {
                        if (response.errorCode === API_SUCCESS) {
                            const res = response.responsePacket;
                            setRecord({ ...res });
                        }
                    }
                );
            }
            if (flag === "history") {
                callGetApi(SECTION_LOADER, Get_ContestDetails_Details_GET.replaceAll("{contestUuid}", contestId), {},
                    (response) => {
                        if (response.errorCode === API_SUCCESS) {
                            const res = response.responsePacket;
                            setRecord({ ...res });
                        }
                    }
                );
            }
        }
    }, []);

    const viewRoundPartSelectionDetails = () => {
        return (
            <div className="col-lg-6">
                <div className="card">
                    <div className="card-body">
                        <fieldset>
                            <legend>Round Part Selection Details</legend>
                            <table className="table table-striped mb-0">
                                <tbody>
                                    <tr className='text-center'>
                                        <th className='text-end'>Round 1 </th>
                                        <th className='text-end'>Round 2 </th>
                                        <th className='text-end'>Round 3 </th>
                                        <th className='text-end'>Round 4 </th>
                                    </tr>
                                    <tr className='text-center'>
                                        <td className='text-end'> {record?.round1PartSelection}</td>
                                        <td className='text-end'>{record?.round2PartSelection} </td>
                                        <td className='text-end'> {record?.round3PartSelection}</td>
                                        <td className='text-end'> {record?.round4PartSelection}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </fieldset>
                    </div>
                </div>
            </div>
        );
    }

    const viewRewardDistributionListDetails = () => {
        return (
            <div className="col-lg-6">
                <div className="card">
                    <div className="card-body">
                        <fieldset>
                            <legend>Reward Distribution Details</legend>
                            <table className="table table-striped mb-0">
                                <tbody>
                                    <tr>
                                        <th className='text-end'>Rank From</th>
                                        <th className='text-end'>Rank To</th>
                                        <th className='text-end'>Reward Percentage</th>
                                    </tr>
                                    {record?.rewardDistributionList.map((data, index) => (
                                        <tr >
                                            <td className='text-end'> {data.rankFrom}</td>
                                            <td className='text-end'>{data.rankTo} </td>
                                            <td className='text-end'> {data.rewardPercentage.toFixed(2)}%</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </fieldset>
                    </div>
                </div>
            </div>
        );
    }

    const viewMatchTitle = () => {
        return (
            <div className="col-lg-12">
                <div className="card">
                    <div className="card-body">
                        {/* <fieldset> */}
                        {/* <legend>Match Title</legend> */}
                        <table className="table table-striped mb-0">
                            <tbody>
                                <tr >
                                    <th className='mx-2'>
                                        {record?.cricketMatchDetails.matchTitle || ""}
                                    </th>
                                    <td><strong>Entry Fee : {record?.entryFee.toFixed(2)}</strong></td>
                                </tr>
                                <tr >
                                    <th className="w-50 fw-medium text-secondary fs-6">
                                        {record?.cricketMatchDetails.teamOneDetail.teamTitle || ""}
                                        &nbsp; ( {record?.cricketMatchDetails.teamOneRunCount || ""} / {record?.cricketMatchDetails.teamOneWicketCount || ""} )
                                        {
                                            record?.cricketMatchDetails.winningTeamId === record?.cricketMatchDetails.teamOneDetail.teamId ?
                                                "( Won by " + record?.cricketMatchDetails.winningByCount + " " + record?.cricketMatchDetails.winningBy + " )" : ""
                                        }
                                    </th>
                                    <th className="w-50 fw-medium text-secondary fs-6">
                                        {record?.cricketMatchDetails.teamTwoDetail.teamTitle || ""}
                                        &nbsp; ( {record?.cricketMatchDetails.teamTwoRunCount || ""} / {record?.cricketMatchDetails.teamTwoWicketCount || ""} )
                                        {
                                            record?.cricketMatchDetails.winningTeamId === record?.cricketMatchDetails.teamTwoDetail.teamId ?
                                                "( Won by " + record?.cricketMatchDetails.winningByCount + " " + record?.cricketMatchDetails.winningBy + " )" : ""
                                        }
                                    </th>
                                </tr>
                                <tr>
                                    <td>
                                        Prize Pool : {record?.prizePoolTypeEnum} - OF - {
                                            record?.prizePoolTypeEnum === "GuaranteedPool" ?
                                                record?.prizePool.toFixed(2) : (record?.contestTeamDetails?.length * record?.entryFee).toFixed(2)
                                        }
                                    </td>
                                    <td>
                                        Participants : {record?.contestTeamDetails?.length} Out Of {record?.maximumNumberOfParticipants}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Extra Earnings Over Pool : {
                                            record?.remainingRewardAmount?.toFixed(2)
                                        }
                                    </td>
                                    <td>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        {/* </fieldset> */}
                    </div>
                </div>
            </div>
        );
    };

    const viewTeamADetails = () => {
        return (
            <div className='col-lg-12 '>
                <div className="card">
                    <div className="card-body">
                        <fieldset >
                            <legend>Players</legend>
                            <button
                                className={`btn ${showPlayerDetails ? "btn-danger" : "btn-primary"} p-1 ms-4`}
                                onClick={() => setShowPlayerDetails(!showPlayerDetails)} >
                                {showPlayerDetails ? "Hide Details" : "Show Details"}
                            </button>
                            <div className='d-flex'>
                                <div className="col-lg-6">
                                    <div className="card">
                                        <div className="card-body">
                                            <fieldset>
                                                <legend>{`Team ${record?.cricketMatchDetails.teamOneDetail.teamTitle}`}</legend>
                                                <table className="table table-striped mb-0">
                                                    <tbody>
                                                        {record?.cricketMatchDetails.teamOnePlayers.map((data, index) => (
                                                            <>
                                                                <tr>
                                                                    <div className='d-flex'>
                                                                        <th className='w-100'> {data.playerName}</th>
                                                                        <th className='text-center w-100'>{`Coins ( ${data.coins} )`}</th>
                                                                        <th className='text-end w-100'>{`Total ( ${data.totalScore} )`}</th>
                                                                    </div>
                                                                </tr>
                                                                {showPlayerDetails ?
                                                                    <>
                                                                        <tr>
                                                                            <div className='d-flex'>
                                                                                <th className="text-center fw-medium text-secondary fs-6 w-100 " >P1</th>
                                                                                <th className="text-center fw-medium text-secondary fs-6 w-100 " >P2</th>
                                                                                <th className="text-center fw-medium text-secondary fs-6 w-100 " >P3</th>
                                                                                <th className="text-center fw-medium text-secondary fs-6 w-100 " >P4</th>
                                                                                <th className="text-center fw-medium text-secondary fs-6 w-100 " >P5</th>
                                                                                <th className="text-center fw-medium text-secondary fs-6 w-100 " >P6</th>
                                                                                <th className="text-center fw-medium text-secondary fs-6 w-100 " >P7</th>
                                                                                <th className="text-center fw-medium text-secondary fs-6 w-100 " >P8</th>
                                                                            </div>
                                                                        </tr>
                                                                        <tr>
                                                                            <div className='d-flex'>
                                                                                <td className="text-center fw-medium text-secondary fs-6 w-100 "> {data.scorePart1}</td>
                                                                                <td className="text-center fw-medium text-secondary fs-6 w-100 ">{data.scorePart2}</td>
                                                                                <td className="text-center fw-medium text-secondary fs-6 w-100 ">{data.scorePart3}</td>
                                                                                <td className="text-center fw-medium text-secondary fs-6 w-100 ">{data.scorePart4}</td>
                                                                                <td className="text-center fw-medium text-secondary fs-6 w-100 ">{data.scorePart5}</td>
                                                                                <td className="text-center fw-medium text-secondary fs-6 w-100 ">{data.scorePart6}</td>
                                                                                <td className="text-center fw-medium text-secondary fs-6 w-100 ">{data.scorePart7}</td>
                                                                                <td className="text-center fw-medium text-secondary fs-6 w-100 ">{data.scorePart8}</td>
                                                                            </div>
                                                                        </tr>
                                                                    </>
                                                                    : ""
                                                                }
                                                            </>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </fieldset>
                                        </div>
                                    </div>
                                </div>
                                {viewTeamBDetails()}
                            </div>
                        </fieldset>
                    </div>
                </div>
            </div>
        );
    };

    const viewTeamBDetails = () => {
        return (
            <div className="col-lg-6">
                <div className="card">
                    <div className="card-body">
                        <fieldset>
                            <legend>{`Team ${record?.cricketMatchDetails.teamTwoDetail.teamTitle}`}</legend>
                            <table className="table table-striped mb-0">
                                <tbody>
                                    {record?.cricketMatchDetails.teamTwoPlayers.map((data, i) => (
                                        <>
                                            <tr>
                                                <div className='d-flex'>
                                                    <th className='w-100'> {data.playerName}</th>
                                                    <th className='text-center w-100'>{`Coins ( ${data.coins} )`}</th>
                                                    <th className='text-end w-100'>{`Total ( ${data.totalScore} )`}</th>
                                                </div>
                                            </tr>
                                            {showPlayerDetails ?
                                                <>
                                                    <tr>
                                                        <div className='d-flex'>
                                                            <th className="text-center fw-medium text-secondary fs-6 w-100 " >P1</th>
                                                            <th className="text-center fw-medium text-secondary fs-6 w-100 " >P2</th>
                                                            <th className="text-center fw-medium text-secondary fs-6 w-100 " >P3</th>
                                                            <th className="text-center fw-medium text-secondary fs-6 w-100 " >P4</th>
                                                            <th className="text-center fw-medium text-secondary fs-6 w-100 " >P5</th>
                                                            <th className="text-center fw-medium text-secondary fs-6 w-100 " >P6</th>
                                                            <th className="text-center fw-medium text-secondary fs-6 w-100 " >P7</th>
                                                            <th className="text-center fw-medium text-secondary fs-6 w-100 " >P8</th>
                                                        </div>
                                                    </tr>
                                                    <tr>
                                                        <div className='d-flex'>
                                                            <td className="text-center fw-medium text-secondary fs-6 w-100 "> {data.scorePart1}</td>
                                                            <td className="text-center fw-medium text-secondary fs-6 w-100 ">{data.scorePart2}</td>
                                                            <td className="text-center fw-medium text-secondary fs-6 w-100 ">{data.scorePart3}</td>
                                                            <td className="text-center fw-medium text-secondary fs-6 w-100 ">{data.scorePart4}</td>
                                                            <td className="text-center fw-medium text-secondary fs-6 w-100 ">{data.scorePart5}</td>
                                                            <td className="text-center fw-medium text-secondary fs-6 w-100 ">{data.scorePart6}</td>
                                                            <td className="text-center fw-medium text-secondary fs-6 w-100 ">{data.scorePart7}</td>
                                                            <td className="text-center fw-medium text-secondary fs-6 w-100 ">{data.scorePart8}</td>
                                                        </div>
                                                    </tr>
                                                </>
                                                : ""
                                            }
                                        </>
                                    ))}
                                </tbody>
                            </table>
                        </fieldset>
                    </div>
                </div>
            </div>
        )
    }

    const viewContestTeamDetailsDetails = () => {
        const toggleDetails = (id) => {
            const element = document.getElementById(id);
            if (element.classList.contains('show')) {
                element.classList.remove('show');
            } else {
                element.classList.add('show');
            }
        };

        return (
            <>
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body">
                            <fieldset>
                                <legend>Contest Team Details</legend>
                                <table className="table table-striped mb-0">
                                    <thead>
                                        {/* <tr>
                                        <th className='text-center'>Player Id</th>
                                          <th className='text-center'>Player Name</th>
                                          <th className='text-end'>Ranking</th> */}

                                        <tr>
                                            <th className='text-center'>Sr#</th>
                                            <th className=''>Player Id</th>
                                            <th className=''>Player Name</th>

                                            <th className='text-end'>Booster 1.5X</th>
                                            <th className='text-end'> Booster 2.0X</th>
                                            <th className='text-end'>Rank</th>
                                            <th className='text-end'>TIE Breaker</th>
                                            <th className='text-end'>Total Earnings</th>
                                            <th className='text-end'>Total Score</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {record?.contestTeamDetails.map((data, i) => (
                                            <React.Fragment key={i}>
                                                {/* <tr>
                                                    <td className='text-center'> {data.playerReferenceId}</td>
                                                    <td className='text-center'>{data.playerName} </td>
                                                    <td className='text-end'> {data?.ranking || 0}</td>
                                                    <td className='text-end'> {data?.booster_1_5x_AppliedOnScorePart || 0}</td>
                                                    <td className='text-end'> {data?.booster_2_0x_AppliedOnScorePart || 0}</td> */}
                                                <tr>
                                                    <td className='text-center'> {i + 1}</td>
                                                    <td className=''> {data.playerReferenceId}</td>
                                                    <td className=''>{data.playerName} </td>
                                                    <td className='text-end'> P{data?.booster_1_5x_AppliedOnScorePart || 0} - MS-{data?.booster_1_5x_AppliedDifference}</td>
                                                    <td className='text-end'> P{data?.booster_2_0x_AppliedOnScorePart || 0} - MS-{data?.booster_2_0x_AppliedDifference}</td>
                                                    <td className='text-end'> {data?.ranking || 0}</td>
                                                    <td className='text-end'> MS-{data?.booster_1_5x_AppliedDifference + data?.booster_2_0x_AppliedDifference}</td>
                                                    <td className='text-end'> {data?.totalEarnings.toFixed(2) || 0}</td>
                                                    <td className='text-end'> {data?.totalScore || 0}</td>
                                                    <td className='text-end'>
                                                        <i
                                                            className="bx bx-chevron-down bg-primary py-1 px-2 border rounded text-white"
                                                            onClick={() => toggleDetails("r" + i)}
                                                            style={{ cursor: 'pointer' }}
                                                        ></i>
                                                    </td>
                                                </tr>
                                                <tr className="collapse" id={"r" + i} data-bs-parent=".table" style={{ borderColor: "#fff" }}>
                                                    <td className='p-0' colSpan={11}>
                                                        <fieldset className='mt-4'>
                                                            <legend>{data?.playerName} - Team Details</legend>
                                                            <table className="table table-striped mb-0">
                                                                <tbody>
                                                                    <tr>
                                                                        <th className='text-start '>player Name</th>
                                                                        <th className='text-end'>P1</th>
                                                                        <th className='text-end'>P2</th>
                                                                        <th className='text-end'>P3</th>
                                                                        <th className='text-end'>P4</th>
                                                                        <th className='text-end'>P5</th>
                                                                        <th className='text-end'>P6</th>
                                                                        <th className='text-end'>P7</th>
                                                                        <th className='text-end'>P8</th>
                                                                        <th className='text-end'>Coins</th>
                                                                        <th className='text-end'>Total Score</th>
                                                                    </tr>
                                                                    {data?.contestTeamPlayers.map((contest, index) => (
                                                                        <tr className='text-center' key={index}>
                                                                            <td className='text-start '>{contest?.playerName || ""} </td>
                                                                            <td className='text-end'> {contest?.scorePart1 || 0}</td>
                                                                            <td className='text-end'> {contest?.scorePart2 || 0}</td>
                                                                            <td className='text-end'> {contest?.scorePart3 || 0}</td>
                                                                            <td className='text-end'> {contest?.scorePart4 || 0}</td>
                                                                            <td className='text-end'> {contest?.scorePart5 || 0}</td>
                                                                            <td className='text-end'> {contest?.scorePart6 || 0}</td>
                                                                            <td className='text-end'> {contest?.scorePart7 || 0}</td>
                                                                            <td className='text-end'> {contest?.scorePart8 || 0}</td>
                                                                            <td className='text-end'> {contest?.totalScore || 0}</td>
                                                                            <td className='text-end'> {contest?.coins || 0}</td>
                                                                        </tr>
                                                                    ))}
                                                                    <tr className='text-center'>
                                                                        <th colSpan={12}></th>
                                                                    </tr>
                                                                    <tr className='text-center'>
                                                                        <th className='text-start '> Total</th>
                                                                        <th className='text-end'>{data?.cricketMatchPlayerCalculatedScore?.totalScorePart1}</th>
                                                                        <th className='text-end'>{data?.cricketMatchPlayerCalculatedScore?.totalScorePart2}</th>
                                                                        <th className='text-end'>{data?.cricketMatchPlayerCalculatedScore?.totalScorePart3}</th>
                                                                        <th className='text-end'>{data?.cricketMatchPlayerCalculatedScore?.totalScorePart4}</th>
                                                                        <th className='text-end'>{data?.cricketMatchPlayerCalculatedScore?.totalScorePart5}</th>
                                                                        <th className='text-end'>{data?.cricketMatchPlayerCalculatedScore?.totalScorePart6}</th>
                                                                        <th className='text-end'>{data?.cricketMatchPlayerCalculatedScore?.totalScorePart7}</th>
                                                                        <th className='text-end'>{data?.cricketMatchPlayerCalculatedScore?.totalScorePart8}</th>
                                                                        <th className='text-end'>{data?.coinsSpend}</th>
                                                                        <th className='text-end'>{data?.cricketMatchPlayerCalculatedScore?.totalScore}</th>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </fieldset>
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        ))}
                                    </tbody>
                                </table>
                            </fieldset>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    const ApiCallerLogsList = () => {
        return (
            <>
                <div className="card">
                    <div className="card-body">
                        <fieldset>
                            <legend>Api Caller Logs List</legend>
                            <div className="card-body listView ">
                                {apiCallerList?.map((row) => (
                                    <div key={row?.id} className="log-entry border p-2 rounded mb-3">
                                        <div>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='d-flex align-items-center justify-content-between'>
                                                        <div>
                                                            <p> {row?.id}</p>
                                                        </div>
                                                        <div> <p> {row?.apiUrl}[{row?.apiMethod}]</p>
                                                        </div>
                                                        <div className='d-flex gap-5'>
                                                            <p>
                                                                <span
                                                                    style={{
                                                                        backgroundColor: row.responseCode === "200" ? "green" : row.responseCode === "500" ? "red" : "orange",
                                                                        padding: "2px 5px",
                                                                        borderRadius: "5px",
                                                                        color: "#fff",

                                                                    }}
                                                                >

                                                                    {row?.responseCode}
                                                                </span>
                                                            </p>
                                                            <p> {dateUtilities?.formattedDate(row.createdAtTimeStamp, "dd-MM-yyyy, hh:mm aa")}</p>
                                                            {row.responseCode}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-6">
                                                    <p>
                                                        Request:{" "}
                                                        <JsonView src={JSON.parse(row?.requestJson)} />
                                                    </p>
                                                </div>
                                                <div className="col-6">
                                                    <p>
                                                        Response:{" "}
                                                        <JsonView src={JSON.parse(row?.responseJson)} />
                                                    </p>
                                                </div>
                                                <div className="col-6">
                                                    <p>Enc Request: {row?.encRequestJson}</p>
                                                </div>
                                                <div className="col-6">
                                                    <p>
                                                        Decoded Response:
                                                        {row?.decodedResponseJson}
                                                    </p>
                                                </div>

                                            </div>
                                            {row?.errorMessage && (
                                                <div>
                                                    <p>Error Message: {row?.errorMessage}</p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </fieldset>
                    </div>
                </div>
            </>
        );
    }

    const TransactionList = () => {
        return (
            <>
                <div className="card">
                    <div className="card-body">
                        <fieldset>
                            <legend>Transaction List</legend>
                            <table className="table table-striped mb-0">
                                <thead>
                                    <tr>
                                        <th className='text-start'>ID</th>
                                        <th className='text-start'>Created At</th>
                                        <th className='text-start'>Modified At</th>
                                        <th className='text-start'>Player Reference ID</th>
                                        <th className='text-start'>Bet Amount</th>
                                        <th className='text-start'>Transaction Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {transcationList?.map((row) => (
                                        <tr key={row?.id}>
                                            <td className='text-start'>{row?.id}</td>
                                            <td className='text-start'>{dateUtilities?.formattedDate(row?.createdAtTimeStamp, "dd-MM-yyyy, hh:mm aa")}</td>
                                            <td className='text-start'>{dateUtilities?.formattedDate(row?.modifiedAtTimeStamp, "dd-MM-yyyy, hh:mm aa")}</td>
                                            <td className='text-start'>{row?.playerReferenceId}</td>
                                            <td className='text-start'>{row?.betAmount}</td>
                                            <td className='text-start'>{row?.transactionStatus}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </fieldset>
                    </div>
                </div>
            </>
        );
    }
    const viewWinningDetails = () => {
        return (
            <div className="col-lg-12">
                <div className="card">
                    <div className="card-body">
                        <fieldset>
                            <legend>Winning Details</legend>
                            <table className="table table-striped mb-0">
                                <tbody>
                                    <tr className='text-center'>
                                        <th>Winning Team</th>
                                        <th>Winning By</th>
                                        <th>Winning By Count</th>
                                    </tr>
                                    <tr className='text-center'>
                                        <td>{record?.cricketMatchDetails.winningTeamId === record?.cricketMatchDetails.teamOneDetail.teamId ? record?.cricketMatchDetails.teamOneDetail.teamTitle
                                            : record?.cricketMatchDetails.teamTwoDetail.teamTitle}</td>
                                        <td>{record?.cricketMatchDetails.winningBy}</td>
                                        <td >{record?.cricketMatchDetails.winningByCount}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </fieldset>
                    </div>
                </div>
            </div>
        );
    };

    const viewOtherContestDetails = () => {
        return (
            <div className="col-lg-12">
                <div className="card">
                    <div className="card-body">
                        <fieldset>
                            <legend>Other Details</legend>
                            <table className="table table-striped mb-0">
                                <tbody>
                                    <tr className='text-center'>
                                        <th>Waiting Time For Draw Next Part</th>
                                        <th>Waiting Time For Apply Booster</th>
                                        <th>Minimum Participants Required</th>
                                        <th>Maximum Participants</th>
                                        <th>Prize Type</th>
                                        <th>Entry Fee</th>
                                    </tr>
                                    <tr className='text-center'>
                                        <td> {record?.waitingTimeForDrawNextPart}</td>
                                        <td>{record?.waitingTimeForApplyBooster} </td>
                                        <td> {record?.minimumNumberOfParticipantsRequired}</td>
                                        <td> {record?.maximumNumberOfParticipants}</td>
                                        <td> {record?.prizePoolTypeEnum}</td>
                                        <td> {record?.entryFee}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </fieldset>
                    </div>
                </div>
            </div>
        );
    }
    return (
        <>
            {PageHeading(`${flag} Order Details`, "Dashboard", "/")}
            {viewMatchTitle()}
            <div className="row">
                <div className="col-lg-12">
                    <div className="row">
                        {viewTeamADetails()}
                    </div>
                </div>
            </div>
            <div className='d-flex justify-content-between '>
                {viewRewardDistributionListDetails()}
                {viewRoundPartSelectionDetails()}
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <div className="row">{viewTeamADetails()}</div>
                </div>
            </div>
            <div className="d-flex mb-4">
                <button
                    className={`btn btn-tabs me-2 ${activeTab === "contestTeamDetails" ? "active" : ""}`}
                    onClick={() => handleTabClick("contestTeamDetails")}
                >
                    Contest Team Details
                </button>
                <button
                    className={`btn btn-tabs me-2 ${activeTab === "transactions" ? "active" : ""}`}
                    onClick={() => handleTabClick("transactions")}
                >
                    Transactions
                </button>
                <button
                    className={`btn btn-tabs ${activeTab === "apiCalls" ? "active" : ""}`}
                    onClick={() => handleTabClick("apiCalls")}
                >
                    API Calls
                </button>
            </div>
            {activeTab === "contestTeamDetails" && <div>{viewContestTeamDetailsDetails()}</div>}
            {activeTab === "transactions" && <div>{TransactionList()}</div>}
            {activeTab === "apiCalls" && <div>{ApiCallerLogsList()}</div>}
        </>
    )
}

export default AdminContestDetails;