import { callDeleteApi, callGetApi } from "api/ApiCaller";
import {
  DELETE_RECOMMENDED_TEAM,
  GET_CRICKET_MATCH_DETAILS,
} from "api/ApiConst";
import ModalWrapper from "components/common/ModalWrapper";
import React, { useEffect, useState } from "react";
import { MODAL_LOADER } from "utils/constants";
import RecommendedTeamAddEdit from "./RecommendedTeamAddEdit";
import toast from "react-hot-toast";
import { BarChart, Coins, LightningIcon, OneCoin } from "components/common/Svgicon";

const defaultPlayerRecordObj = {
  coins: 0,
  cricketPlayerId: 0,
  playerName: "",
  scorePart1: 0,
  scorePart2: 0,
  scorePart3: 0,
  scorePart4: 0,
  scorePart5: 0,
  scorePart6: 0,
  scorePart7: 0,
  scorePart8: 0,
  totalScore: 0,
};

const singleTeamDetailObj = {
  name: "",
  runCount: 0,
  wicketCount: 0,
  players: [
    ...Array.from({ length: 11 }, () => ({ ...defaultPlayerRecordObj })),
  ],
};

const teamDetailsObj = {
  team1: { ...singleTeamDetailObj },
  team2: { ...singleTeamDetailObj },
};

const MatchDetails = ({ show, hideModal, handleRefresh, watchId }) => {
  const [showPlayerDetails, setShowPlayerDetails] = useState(false);
  const [matchDetails, setMatchDetails] = useState(null);
  const [teamDetails, setTeamDetails] = useState({ ...teamDetailsObj });
  const [showRecommendedTeamModal, setShowRecommendedTeamModal] =
    useState(false);
  const [recommendedTeams, setRecommendedTeams] = useState([]);

  const getMatchDetails = () => {
    callGetApi(
      MODAL_LOADER,
      GET_CRICKET_MATCH_DETAILS.replaceAll("{recordID}", watchId),
      {},
      (response) => {
        const res = response.responsePacket;
        setTeamDetails((prev) => ({
          ...prev,
          team1: {
            name: res.teamOneDetail.teamTitle,
            runCount: res.teamOneRunCount,
            wicketCount: res.teamOneWicketCount,
            players: res.teamOnePlayerList,
          },
          team2: {
            name: res.teamTwoDetail.teamTitle,
            runCount: res.teamTwoRunCount,
            wicketCount: res.teamTwoWicketCount,
            players: res.teamTwoPlayerList,
          },
        }));
        if (res.recommendedTeams) {
          setRecommendedTeams(res.recommendedTeams);
        }
      }
    );
  };

  useEffect(() => {
    if (watchId) {
      getMatchDetails();
    }
  }, [watchId]);

  useEffect(() => {
    if (!showRecommendedTeamModal) {
    }
  }, [showRecommendedTeamModal]);

  const submitTeam = () => {
    getMatchDetails();
    setShowRecommendedTeamModal(false);
  };

  const handleDeleteRecTeam = (recTeamId) => {
    callDeleteApi(
      MODAL_LOADER,
      DELETE_RECOMMENDED_TEAM.replaceAll(
        "{cricketMatchId}",
        watchId
      ).replaceAll("{recommendedTeamId}", recTeamId),
      {},
      (response) => {
        if (response.errorCode === 0) {
          getMatchDetails();
          toast.success(response.message, { id: "Error" });
        } else {
          toast.error(response.message, { id: "Error" });
        }
      }
    );
  };

  const viewPlayerList = (team) => {
    return (
      <div className="col-lg-6">
        <div className="card mb-0">
          <div className="card-body p-0">
            <fieldset>
              <legend>{`Team ${teamDetails[team].name}`}</legend>
              {/* <div></div> */}
              <div className=" overflow-auto" style={{ height: "350px" }}>
                <table className="table table-striped mb-0">
                  {teamDetails[team].players.map((data, index) => (
                    <tbody key={index}>
                      <>
                        <tr>
                          <div className="d-flex">
                            <th className="w-100"> {data.playerName}</th>
                            <th className="text-center w-100">{`Coins ( ${data.coins} )`}</th>
                            <th className="text-end w-100">{`Total ( ${data.totalScore} )`}</th>
                          </div>
                        </tr>
                        {showPlayerDetails ? (
                          <>
                            <tr>
                              <div className="d-flex">
                                <th className="text-center fw-medium text-secondary fs-6 w-100 ">
                                  P1
                                </th>
                                <th className="text-center fw-medium text-secondary fs-6 w-100 ">
                                  P2
                                </th>
                                <th className="text-center fw-medium text-secondary fs-6 w-100 ">
                                  P3
                                </th>
                                <th className="text-center fw-medium text-secondary fs-6 w-100 ">
                                  P4
                                </th>
                                <th className="text-center fw-medium text-secondary fs-6 w-100 ">
                                  P5
                                </th>
                                <th className="text-center fw-medium text-secondary fs-6 w-100 ">
                                  P6
                                </th>
                                <th className="text-center fw-medium text-secondary fs-6 w-100 ">
                                  P7
                                </th>
                                <th className="text-center fw-medium text-secondary fs-6 w-100 ">
                                  P8
                                </th>
                              </div>
                            </tr>
                            <tr>
                              <div className="d-flex">
                                <td className="text-center fw-medium text-secondary fs-6 w-100 ">
                                  {" "}
                                  {data.scorePart1}
                                </td>
                                <td className="text-center fw-medium text-secondary fs-6 w-100 ">
                                  {data.scorePart2}
                                </td>
                                <td className="text-center fw-medium text-secondary fs-6 w-100 ">
                                  {data.scorePart3}
                                </td>
                                <td className="text-center fw-medium text-secondary fs-6 w-100 ">
                                  {data.scorePart4}
                                </td>
                                <td className="text-center fw-medium text-secondary fs-6 w-100 ">
                                  {data.scorePart5}
                                </td>
                                <td className="text-center fw-medium text-secondary fs-6 w-100 ">
                                  {data.scorePart6}
                                </td>
                                <td className="text-center fw-medium text-secondary fs-6 w-100 ">
                                  {data.scorePart7}
                                </td>
                                <td className="text-center fw-medium text-secondary fs-6 w-100 ">
                                  {data.scorePart8}
                                </td>
                              </div>
                            </tr>
                          </>
                        ) : (
                          ""
                        )}
                      </>
                    </tbody>
                  ))}
                </table>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    );
  };

  const viewRecommendedTeamCard = (team) => {
    const getHightestPart = () => {
      const scoreParts = {
        P1: team.scorePart1,
        P2: team.scorePart2,
        P3: team.scorePart3,
        P4: team.scorePart4,
        P5: team.scorePart5,
        P6: team.scorePart6,
        P7: team.scorePart7,
        P8: team.scorePart8,
      };
      let greatestNumber = Math.max(...Object.values(scoreParts));
      return Object.keys(scoreParts).find(
        (key) => scoreParts[key] === greatestNumber
      );
    };

    const showPlayerNames = () => {
      let nameStr = "";
      team.recommendedTeamPlayers.map((player, i) => {
        if (team.recommendedTeamPlayers.length === i + 1) {
          nameStr += `${player.playerName.trim()}`;
        } else {
          nameStr += `${player.playerName.trim()}, `;
        }
        // nameStr += " ";
      });
      return nameStr;
    };
    return (
      <div className="col-lg-6 col-md-12">
        <div className="player_tails card shadow-lg mb-2">
          <div className="d-flex align-items-center justify-content-between">
            <div className="w-100 me-4">
              <div className="fs-16 fw-500 text-black">{team.teamTitle}</div>
            </div>

            <div className="d-flex justify-content-between align-items-center gap-3">
              <div className="d-flex align-items-center">
                <OneCoin  />
                <p className="m-0 ms-1">{team.coins}</p>
              </div>
              <div className="d-flex align-items-center">
                <div className="d-flex align-items-center me-2">
                  <span className="me-1">
                    <LightningIcon />
                  </span>
                  <span className="text-gray-70 fs-12 fw-400">
                    {getHightestPart()}
                  </span>
                </div>
                <div className="d-flex align-items-center">
                  <div className="playground playground_red">
                    <span>
                      <BarChart />
                    </span>
                    <span>{team.totalScore}</span>
                  </div>
                </div>
              </div>

              <button
                className="btn btn-danger py-1 px-2"
                onClick={() => handleDeleteRecTeam(team.id)}
              >
                <i className="bx bx-trash"></i>
              </button>
            </div>
          </div>
          <div className="selected_scoring mt-3 px-0">
            <li>
              <span className="fs-12 fw-400 d-block opacity-75 text-gray">
                P1
              </span>
              <span className={`d-block fs-12 fw-400 p-1 rounded-1  ${team.scorePart1 === 0 ? "playground_red" : "playground_green"}`}>
                {team.scorePart1}
              </span>
              <span></span>
            </li>
            <li>
              <span className="fs-12 fw-400 d-block opacity-75 text-gray">
                P2
              </span>
              <span className={`d-block fs-12 fw-400 p-1 rounded-1  ${team.scorePart2 === 0 ? "playground_red" : "playground_green"}`}>
                {team.scorePart2}
              </span>
            </li>
            <li>
              <span className="fs-12 fw-400 d-block opacity-75 text-gray">
                P3
              </span>
              <span className={`d-block fs-12 fw-400 p-1 rounded-1  ${team.scorePart3 === 0 ? "playground_red" : "playground_green"}`}>
                {team.scorePart3}
              </span>
            </li>
            <li>
              <span className="fs-12 fw-400 d-block opacity-75 text-gray">
                P4
              </span>
              <span className={`d-block fs-12 fw-400 p-1 rounded-1  ${team.scorePart4 === 0 ? "playground_red" : "playground_green"}`}>
                {team.scorePart4}
              </span>
            </li>
            <li>
              <span className="fs-12 fw-400 d-block opacity-75 text-gray">
                P5
              </span>
              <span className={`d-block fs-12 fw-400 p-1 rounded-1  ${team.scorePart5 === 0 ? "playground_red" : "playground_green"}`}>
                {team.scorePart5}
              </span>
            </li>
            <li>
              <span className="fs-12 fw-400 d-block opacity-75 text-gray">
                P6
              </span>
              <span className={`d-block fs-12 fw-400 p-1 rounded-1  ${team.scorePart6 === 0 ? "playground_red" : "playground_green"}`}>
                {team.scorePart6}
              </span>
            </li>
            <li>
              <span className="fs-12 fw-400 d-block opacity-75 text-gray">
                P7
              </span>
              <span className={`d-block fs-12 fw-400 p-1 rounded-1  ${team.scorePart7 === 0 ? "playground_red" : "playground_green"}`}>
                {team.scorePart7}
              </span>
            </li>
            <li>
              <span className="fs-12 fw-400 d-block opacity-75 text-gray">
                P8
              </span>
              <span className={`d-block fs-12 fw-400 p-1 rounded-1  ${team.scorePart8 === 0 ? "playground_red" : "playground_green"}`}>
                {team.scorePart8}
              </span>
            </li>
          </div>
          {/* <div className="selected_scoring mt-3 px-0">
                        <li>
                            <span className="fs-12 fw-400 d-block opacity-75 text-gray">P1</span>
                            <span className={`d-block fs-12 fw-400 p-1 rounded-1  ${team.scorePart1 === 0 ? "playground_red" : "playground_green"}`}>{team.scorePart1}</span>
                            <span></span>
                        </li>
                    </div> */}
          <div className="fs-12 fw-500 text-gray playerName ">
            {showPlayerNames()}
          </div>
        </div>
      </div>
    );
  };

  return (
    <ModalWrapper
      show={show}
      hide={hideModal}
      backdrop="static"
      header={`Cricket Match Details`}
      size="xl"
      // handleSubmit={handleSubmit}
    >
      <div className="col-lg-12 ">
        <div className="card shadow-lg mb-3">
          <div className="card-body">
            <fieldset>
              <legend>Players</legend>
              <button
                className={`btn mb-3 ${
                  showPlayerDetails ? "btn-danger" : "btn-primary"
                } p-1 `}
                onClick={() => setShowPlayerDetails(!showPlayerDetails)}
              >
                {showPlayerDetails ? "Hide Details" : "Show Details"}
              </button>
              <div className="d-flex row">
                {viewPlayerList("team1")}
                {viewPlayerList("team2")}
              </div>
            </fieldset>
          </div>
        </div>
      </div>
      <div className="col-lg-12">
        <div className="d-flex justify-content-between align-items-center mb-2">
          <b>Recommended Teams</b>
          <button
            className="btn btn-primary py-1"
            onClick={() => setShowRecommendedTeamModal(true)}
          >
            Add
          </button>
        </div>
        {recommendedTeams.length > 0 ? (
          <div className="row">
            {recommendedTeams.map((team) => viewRecommendedTeamCard(team))}
          </div>
        ) : (
          <div>No recommended teams are created...</div>
        )}
      </div>
      {showRecommendedTeamModal && (
        <RecommendedTeamAddEdit
          show={showRecommendedTeamModal}
          hideModal={() => setShowRecommendedTeamModal(false)}
          teamDetails={teamDetails}
          recommendedTeams={recommendedTeams}
          watchId={watchId}
          submitTeam={submitTeam}
        />
      )}
    </ModalWrapper>
  );
};

export default MatchDetails;
