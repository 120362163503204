import { callGetApi, callPostApi, callPutApi } from "api/ApiCaller";
import {
    Add_Cricket_Match_POST,
    Get_Cricket_Match_Details_GET,
    Update_Cricket_Match_PUT,
} from "api/ApiConst";
import ModalWrapper from "components/common/ModalWrapper";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import Select from "react-select";
import { isBlank, isEmpty, isNumeric } from "utils/TextUtils";
import { MODAL_LOADER } from "utils/constants";

const MatchAddEdit = ({
    show,
    hideModal,
    handleRefresh,
    recordID,
    teamList,
    playerList,
}) => {

    const winningByOptions = [
        { label: "Wicket", value: "Wicket" },
        { label: "Run", value: "Run" }
    ];

    const totalNumberOfPlayerPerTeam = 11;
    const totalNumberOfScorePartPerPlayer = 8;
    const defaultPlayerRecordObj = {
        cricketPlayerId: null,
        scorePart1: 0,
        scorePart2: 0,
        scorePart3: 0,
        scorePart4: 0,
        scorePart5: 0,
        scorePart6: 0,
        scorePart7: 0,
        scorePart8: 0,
        coins: 0,
        totalScore: 0
    };

    const defaultRecordObj = {
        uuid: "",
        matchTitle: "",

        teamOneId: null,
        teamOneRunCount: 0,
        teamOneWicketCount: 0,
        teamOnePlayerList: [
            ...Array.from({ length: totalNumberOfPlayerPerTeam }, () => ({ ...defaultPlayerRecordObj })),
        ],

        teamTwoId: null,
        teamTwoRunCount: 0,
        teamTwoWicketCount: 0,
        teamTwoPlayerList: [
            ...Array.from({ length: totalNumberOfPlayerPerTeam }, () => ({ ...defaultPlayerRecordObj })),
        ],

        winningTeamId: null,
        winningBy: { label: "Run", value: "Run" },
        winningByCount: 0
    };
    const [record, setRecord] = useState(defaultRecordObj);

    useEffect(() => {
        if (recordID) {
            callGetApi(
                MODAL_LOADER,
                Get_Cricket_Match_Details_GET.replaceAll("{recordID}", recordID),
                {},
                (response) => {
                    const res = response.responsePacket;
                    const _teamOneId = teamList.find((obj) => obj.value == res.teamOneId);
                    const _teamTwoId = teamList.find((obj) => obj.value == res.teamTwoId);
                    const _winningTeamId = teamList.find(
                        (obj) => obj.value == res.winningTeamId
                    );
                    const _winningBy = winningByOptions.find(
                        (obj) => obj.value == res.winningBy
                    );
                    res.teamOnePlayerList.map((teamPlayer, index) => {
                        let selectedPlayer = playerList.find((obj) => obj.value == teamPlayer.cricketPlayerId);
                        res.teamOnePlayerList[index].cricketPlayerId = selectedPlayer;
                    });
                    if (res.teamOnePlayerList.length < totalNumberOfPlayerPerTeam) {
                        for (let i = res.teamOnePlayerList.length; i < totalNumberOfPlayerPerTeam; i++) {
                            res.teamOnePlayerList.push(defaultPlayerRecordObj);
                        }
                    }

                    res.teamTwoPlayerList.map((teamPlayer, index) => {
                        let selectedPlayer = playerList.find((obj) => obj.value == teamPlayer.cricketPlayerId);
                        res.teamTwoPlayerList[index].cricketPlayerId = selectedPlayer;
                    });
                    if (res.teamTwoPlayerList.length < totalNumberOfPlayerPerTeam) {
                        for (let i = res.teamTwoPlayerList.length; i < totalNumberOfPlayerPerTeam; i++) {
                            res.teamTwoPlayerList.push(defaultPlayerRecordObj);
                        }
                    }
                    setRecord({
                        ...res,
                        teamOneId: _teamOneId || "",
                        teamTwoId: _teamTwoId || "",
                        winningTeamId: _winningTeamId || "",
                        winningBy: _winningBy || "",
                    });
                }
            );
        }
    }, [recordID]);

    const handleTextChange = (e) => {
        setRecord((prev) => ({ ...prev, [e.target.id]: e.target.value }));
    };

    const getPlayerScoreRecord = (teamPlayerListVar, index, teamPlayer) => {
        const playerScoreParts = [];
        for (var i = 0; i < totalNumberOfScorePartPerPlayer; i++) {
            playerScoreParts.push(
                <div className="ms-1 me-1" key={i}>
                    <label>P{i + 1}</label>
                    <input type="text" maxLength={4} id={"scorePart" + (i + 1)} className="form-control input-default"
                        value={teamPlayer['scorePart' + (i + 1)]}
                        onChange={(e) => {
                            var tempRecord = { ...record };
                            let value = isNumeric(e.target.value) ? e.target.value : "0";
                            tempRecord[teamPlayerListVar][index][e.target.id] = parseInt(value);
                            var totalScore = 0;
                            for (var j = 0; j < totalNumberOfScorePartPerPlayer; j++) {
                                totalScore += tempRecord[teamPlayerListVar][index]["scorePart" + (j + 1)];
                            }
                            tempRecord[teamPlayerListVar][index]["totalScore"] = totalScore;
                            setRecord(tempRecord);
                        }}
                    // record[teamPlayerListVar][index]["scorePart" + (i + 1)] = e.value
                    />
                </div >
            )
        }
        return (
            playerScoreParts.map((part) => {
                return part;
            })
        );
    };
    const getPlayerRecords = (teamPlayerListVar, teamPlayerList) => {
        return (
            teamPlayerList.map((teamPlayer, index) => {
                return <fieldset key={index} className="mt-3">
                    <legend>Player {index + 1}</legend>
                    <div className="row">
                        <div className="col-lg-6 mb-3">
                            <label>Select Player *</label>
                            <Select
                                options={playerList}
                                onChange={(e) => handleSelectPlayer(e, teamPlayerListVar, index)}
                                value={teamPlayer.cricketPlayerId}
                            />
                        </div>
                        <div className="col-lg-3 mb-3">
                            <label>Coins *</label>
                            <input type="text" className="form-control input-default" id="coins" name="coins"
                                onChange={(e) => {
                                    var tempRecord = { ...record };
                                    tempRecord[teamPlayerListVar][index]['coins'] = parseInt(
                                        isNumeric(e.target.value) ? e.target.value : "0"
                                    );
                                    setRecord(tempRecord);
                                }}
                                value={teamPlayer?.coins}
                            />
                        </div>
                        <div className="col-lg-3 mb-3">
                            <label>Total</label>
                            <label className="form-control input-default">{teamPlayer?.totalScore}</label>
                        </div>
                        <div className="col-12 mb-3">
                            <div className="d-flex">{getPlayerScoreRecord(teamPlayerListVar, index, teamPlayer)}</div>
                        </div>
                    </div>
                </fieldset>
            })
        );
    };

    const handleSelectPlayer = (e, teamPlayerListVar, index) => {
        var tempRecord = { ...record };
        tempRecord[teamPlayerListVar][index]["cricketPlayerId"] = e;
        setRecord(tempRecord);
    };

    const checkInputValidation = () => {
        if (isEmpty(record.matchTitle)) {
            toast.error(`Match title must not be empty`, { id: "toast" });
            return false;
        } else if (isEmpty(record.teamOneId)) {
            toast.error(`Team A must not be empty`, { id: "toast" });
            return false;
        } else if (isBlank(record.teamOneRunCount)) {
            toast.error(`Team A run count must not be empty`, { id: "toast" });
            return false;
        } else if (isBlank(record.teamOneWicketCount)) {
            toast.error(`Team A wicket count must not be empty`, { id: "toast" });
            return false;
        } else if (isEmpty(record.teamTwoId)) {
            toast.error(`Team B must not be empty`, { id: "toast" });
            return false;
        } else if (record.teamOneId === record.teamTwoId) {
            toast.error(`Both team can not be same`);
            return false;
        } else if (isBlank(record.teamTwoRunCount)) {
            toast.error(`Team A run count must not be empty`, { id: "toast" });
            return false;
        } else if (isBlank(record.teamTwoWicketCount)) {
            toast.error(`Team A wicket count must not be empty`, { id: "toast" });
            return false;
        } else if (isEmpty(record.winningTeamId)) {
            toast.error(`Winning team must not be empty`, { id: "toast" });
            return false;
        } else if (isBlank(record.winningByCount)) {
            toast.error(`Winning By Count must not be empty`, { id: "toast" });
            return false;
        }

        let selectedPlayersIds = [];
        for (let i = 0; i < record.teamOnePlayerList.length; i++) {
            let playerId = record.teamOnePlayerList[i].cricketPlayerId;
            if (!playerId) {
                toast.error(`Please select player on ${i + 1} position in team A`);
                return false;
            }
            if (selectedPlayersIds.includes(playerId)) {
                toast.error(`Player on ${i + 1} position in team A is already selected before.`);
                return false;
            }
            selectedPlayersIds.push(playerId);
        }

        for (let i = 0; i < record.teamTwoPlayerList.length; i++) {
            let playerId = record.teamTwoPlayerList[i].cricketPlayerId;
            if (!playerId) {
                toast.error(`Please select player on ${i + 1} position in team B`);
                return false;
            }
            if (selectedPlayersIds.includes(playerId)) {
                toast.error(`Player on ${i + 1} position in team B is already selected before.`);
                return false;
            }
            selectedPlayersIds.push(playerId);
        }
        return true;
    };

    const handleSubmit = async () => {
        try {
            if (!checkInputValidation()) {
                return;
            }
            let dataObj = JSON.parse(JSON.stringify(record));
            dataObj = {
                ...dataObj,
                teamOneId: parseInt(record.teamOneId?.key || "0"),
                teamTwoId: parseInt(record.teamTwoId?.key || "0"),
                winningTeamId: parseInt(record.winningTeamId?.key || "0"),
                winningBy: record.winningBy?.value || "",
            };
            dataObj.teamOnePlayerList.map((teamPlayer, index) => {
                dataObj.teamOnePlayerList[index].cricketPlayerId = parseInt(teamPlayer.cricketPlayerId.key);
            });
            dataObj.teamTwoPlayerList.map((teamPlayer, index) => {
                dataObj.teamTwoPlayerList[index].cricketPlayerId = parseInt(teamPlayer.cricketPlayerId.key);
            });
            if (!recordID || recordID === "") {
                callPostApi(MODAL_LOADER, Add_Cricket_Match_POST, dataObj, {}, (response) => {
                    toast.success(response.message);
                    hideModal();
                    handleRefresh();
                });
            } else {
                callPutApi(MODAL_LOADER, Update_Cricket_Match_PUT.replaceAll("{recordID}", recordID), dataObj, {}, (response) => {
                    toast.success(response.message);
                    hideModal();
                    handleRefresh();
                });
            }
        } catch (error) {
            return;
        }
    };

    return (
        <ModalWrapper
            show={show}
            hide={hideModal}
            backdrop="static"
            header={`${recordID ? "Update" : "Add"} Cricket Match Details`}
            size="xl"
            handleSubmit={handleSubmit}
        >
            <div className="form-group row mb-3">
                <div className="col-12 mb-3">
                    <fieldset>
                        <legend>Match Title</legend>
                        <div className="row">
                            <div className="col-lg-12">
                                <input type="text" className="form-control input-default" id="matchTitle"
                                    onChange={handleTextChange}
                                    value={record.matchTitle}
                                />
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div className="col-12 mb-3 row">
                    <div className="col-lg-6 row">
                        <div className="col-lg-8">
                            <label>Team A</label>
                            <Select
                                value={record.teamOneId}
                                onChange={(e) => setRecord((pre) => ({ ...pre, teamOneId: e }))}
                                options={teamList}
                            />
                        </div>
                        <div className="col-lg-2">
                            <label>Run *</label>
                            <input type="text" className="form-control input-default" id="teamOneRunCount"
                                // onChange={handleTextChange}
                                onChange={(e) => {
                                    var tempRecord = { ...record };
                                    tempRecord.teamOneRunCount = parseInt(
                                        isNumeric(e.target.value) ? e.target.value : "0"
                                    );
                                    setRecord(tempRecord);
                                }}
                                value={record.teamOneRunCount}
                            />
                        </div>
                        <div className="col-lg-2">
                            <label>Wicket *</label>
                            <input type="text" className="form-control input-default" id="teamOneWicketCount"
                                // onChange={handleTextChange}
                                onChange={(e) => {
                                    var tempRecord = { ...record };
                                    tempRecord.teamOneWicketCount = parseInt(
                                        isNumeric(e.target.value) ? e.target.value : "0"
                                    );
                                    setRecord(tempRecord);
                                }}
                                value={record.teamOneWicketCount}
                            />
                        </div>
                        <div className="col-12">
                            <div className="form-group row mb-3">
                                <div className="col-12">
                                    {getPlayerRecords("teamOnePlayerList", record.teamOnePlayerList)}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 row">
                        <div className="col-lg-8">
                            <label>Team B</label>
                            <Select
                                value={record.teamTwoId}
                                onChange={(e) =>
                                    setRecord((pre) => ({ ...pre, teamTwoId: e }))
                                }
                                options={teamList}
                            />
                        </div>
                        <div className="col-lg-2">
                            <label>Run *</label>
                            <input type="text" className="form-control input-default" id="teamTwoRunCount"
                                // onChange={handleTextChange}
                                onChange={(e) => {
                                    var tempRecord = { ...record };
                                    tempRecord.teamTwoRunCount = parseInt(
                                        isNumeric(e.target.value) ? e.target.value : "0"
                                    );
                                    setRecord(tempRecord);
                                }}
                                value={record.teamTwoRunCount}
                            />
                        </div>
                        <div className="col-lg-2">
                            <label>Wicket *</label>
                            <input type="text" className="form-control input-default" id="teamTwoWicketCount"
                                // onChange={handleTextChange}
                                onChange={(e) => {
                                    var tempRecord = { ...record };
                                    tempRecord.teamTwoWicketCount = parseInt(
                                        isNumeric(e.target.value) ? e.target.value : "0"
                                    );
                                    setRecord(tempRecord);
                                }}
                                value={record.teamTwoWicketCount}
                            />
                        </div>
                        <div className="col-12">
                            <div className="form-group row mb-3">
                                <div className="col-12">
                                    {getPlayerRecords("teamTwoPlayerList", record.teamTwoPlayerList)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 mb-3">
                    <fieldset>
                        <legend>Winning Details</legend>
                        <div className="row">
                            <div className="col-lg-4">
                                <label>Winning Team *</label>
                                <Select
                                    value={record.winningTeamId}
                                    onChange={(e) =>
                                        setRecord((pre) => ({ ...pre, winningTeamId: e }))
                                    }
                                    options={teamList}
                                />
                            </div>
                            <div className="col-lg-4">
                                <label>Winning By *</label>

                                <Select
                                    options={winningByOptions}
                                    value={record.winningBy}
                                    onChange={(e) =>
                                        setRecord((pre) => ({ ...pre, winningBy: e }))
                                    }
                                />
                            </div>
                            <div className="col-lg-4">
                                <label>Winning By Count *</label>
                                <input
                                    type="text"
                                    className="form-control input-default"
                                    id="winningByCount"
                                    name="winningByCount"
                                    onChange={handleTextChange}
                                    value={record.winningByCount}
                                />
                            </div>
                        </div>
                    </fieldset>
                </div>
            </div>
        </ModalWrapper>
    );
};

export default MatchAddEdit;