import React from "react";

import { Button } from "react-bootstrap";

const CustomButton = ({ title, handleClick, iconClass, buttonClass, style = null }) => {
    return (
        <Button className={`customButton mb-0 ${buttonClass}`} onClick={handleClick} style={style}>
            <i className={iconClass} /> {title}
        </Button>
    );
};

export default CustomButton;
