import { callGetApi, callPostApi, callPutApi } from "api/ApiCaller";
import {
    Add_Team_POST,
    Get_Team_Details_GET,
    Update_Team_PUT,
} from "api/ApiConst";
import ModalWrapper from "components/common/ModalWrapper";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { isEmpty } from "utils/TextUtils";
import { MODAL_LOADER } from "utils/constants";
import ImageSelector from "components/common/ImageSelector";
import Select from "react-select";
const AdminTeamAddEdit = ({
    show,
    hideModal,
    handleRefresh,
    recordID,
    jerseyList,
}) => {
    const defaultRecordObj = {
        jerseyUuid: "",
        title: "",
        shortTitle: "",
        flagImageUrl: "",
        colorCode: "",
    };

    const [record, setRecord] = useState(defaultRecordObj);
    const [selectJerseyList, setSelectJerseyList] = useState({});
    const [imageUrl, setImageUrl] = useState("");
    let jerseyImg = null;
    useEffect(() => {
        if (recordID) {
            callGetApi(
                MODAL_LOADER,
                Get_Team_Details_GET.replaceAll("{recordID}", recordID),
                {},
                (response) => {
                    const res = response.responsePacket;
                    setRecord(res);
                    const getJersyImg = () => {
                        jerseyImg = jerseyList.find(
                            (data) => data.value === res.jerseyUuid
                        );
                    };
                    getJersyImg();
                    setSelectJerseyList(jerseyImg);
                    setImageUrl(jerseyImg.key);
                }
            );
        }
    }, [recordID]);

    //Text Change Event
    const handleTextChange = (e) => {
        setRecord((prev) => ({ ...prev, [e.target.id]: e.target.value }));
    };

    const checkInputValidation = () => {
        if (isEmpty(record.flagImageUrl)) {
            toast.error(`Image must not be empty`, { id: "toast" });
            return false;
        } else if (isEmpty(record.title)) {
            toast.error(`Title must not be empty`, { id: "toast" });
            return false;
        } else if (isEmpty(record.shortTitle)) {
            toast.error(`Short Title must not be empty`, {
                id: "toast",
            });
            return false;
        } else if (isEmpty(record.colorCode)) {
            toast.error(`Color must not be empty`, {
                id: "toast",
            });
            return false;
        }
        return true;
    };

    //Submit Avatar Form
    const handleSubmit = async () => {
        try {
            if (!checkInputValidation()) {
                return;
            }
            let recordObj = record;

            if (!recordID || recordID === "") {
                callPostApi(MODAL_LOADER, Add_Team_POST, recordObj, {}, (response) => {
                    toast.success(response.message);
                    hideModal();
                    handleRefresh();
                });
            } else {
                callPutApi(
                    MODAL_LOADER,
                    Update_Team_PUT.replaceAll("{recordID}", recordID),
                    recordObj,
                    {},
                    (response) => {
                        toast.success(response.message);
                        hideModal();
                        handleRefresh();
                    }
                );
            }
        } catch (error) {
            return;
        }
    };

    const handleImageChange = (imageRefId) => {
        setRecord({ ...record, flagImageUrl: imageRefId });
    };

    const handleSelectJersey = (e) => {
        setImageUrl(e.key);
        setRecord({ ...record, jerseyUuid: e.value });
        setSelectJerseyList({
            value: e.value,
            label: e.label,
            image: e.key,
        });
    };

    return (
        <ModalWrapper
            show={show}
            hide={hideModal}
            backdrop="static"
            header={`${recordID ? "Update" : "Add"} Team`}
            size="sm"
            handleSubmit={handleSubmit}
        >
            <div className="form-group row mb-3">
                <div className={record.jerseyUuid ? "col-lg-8 mb-3" : "col-lg-12 mb-3"}>
                    <label>Flag *</label>
                    <ImageSelector
                        style={{
                            height: "120px",
                            // width: "120px",
                            overflow: "hidden",
                        }}
                        user={true}
                        handleImageChange={handleImageChange}
                        selectedImage={record.flagImageUrl}
                    />
                </div>
                {record.jerseyUuid && (
                    <div className="col-lg-4 mb-3">
                        <label>Jersey</label>
                        <div className="text-center imageParentDiv" style={{ height: "120px" }}>
                            <img
                                src={imageUrl}
                                alt="JerseyImage"
                                height="120px"
                            />
                        </div>
                    </div>
                )}
                <div className="col-lg-8 mb-3">
                    <label>Title *</label>
                    <input
                        type="text"
                        className="form-control input-default"
                        id="title"
                        onChange={handleTextChange}
                        value={record.title}
                    />
                </div>
                <div className="col-lg-4 mb-3">
                    <label>Short *</label>
                    <input
                        type="text"
                        className="form-control input-default"
                        maxLength={3}
                        id="shortTitle"
                        // autoCapitalize="word"
                        onChange={handleTextChange}
                        value={record.shortTitle}
                    />
                </div>
                <div className="col-lg-8 mb-3">
                    <label>Select Jersey *</label>
                    <Select
                        value={selectJerseyList}
                        options={jerseyList}
                        onChange={handleSelectJersey}
                    />
                </div>
                <div className="col-lg-4 mb-3">
                    <label>Color *</label>
                    <input
                        type="color"
                        className="form-control input-default"
                        id="colorCode"
                        onChange={handleTextChange}
                        value={record.colorCode}
                        style={{ height: "36.8px" }}
                    />
                </div>
            </div>
        </ModalWrapper>
    );
};

export default AdminTeamAddEdit;
